import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Discount } from '../models/model';
import { Observable } from 'rxjs';
import { UserService } from './user.service';


@Injectable()
export class DiscountService extends HelperService {

  constructor(_aFs: AngularFirestore,
    private _us: UserService,
    private http: HttpClient
  ) {
    super(_aFs);
  }

  public getVouchers$(): Observable<Discount[]> {
    const collection: AngularFirestoreCollection<Discount> = this._aFs.collection<Discount>('discount', ref => {
      return ref.orderBy('type', 'asc');
    });
    return super.mapSnapshotChanges$(collection, Discount);
  }

  async addVoucher(discount: Discount): Promise<DocumentReference> {
    return this._aFs.firestore.collection('discount').add(discount.data());
  }

  public getDiscountMeal(mealRef: DocumentReference) {
    return this._aFs.firestore.collection('discount')
      .where('mealRef', '==', mealRef).get();
  }

  public async getEmailUserId(owner) {
    return this._us.user.firebaseUser.getIdToken().then(token => {
      const url = `${environment.usersApiURL}/checkValidEmail/${owner}?auth=${token}`;

      return this.http.get(url).toPromise()
        .then(data => {
          return data;
        });
    });
  }

  public async getUsers(userId) {
    return this._us.user.firebaseUser.getIdToken().then(token => {
      const url = `${environment.usersApiURL}/getUser/${userId}?auth=${token}`;
      return this.http.get(url).toPromise()
        .then(data => {
          return data;
        });
    });
  }

  async sendEmail(owners: string[]): Promise<object | void> {
    if (!owners) {
      return Promise.resolve();
    }
    const body = {
      owners: owners,
    };
    const token = await this._us.user.firebaseUser.getIdToken();
    const url = `${environment.discountApiURL}/discount/?auth=${token}`;

    return this.http.post(url, body).toPromise();
  }
}

