import {FormBuilder} from '@angular/forms/';
import {FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {ErrorMessageService} from '../services/error-message.service';


export interface IInputDialogConfig {
    message: string;
    title: string;
    placeholder: string;
    validators: Array<Validators>;
    input: string;
}

@Component({
  selector: 'input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.less'],
  providers: []
})
export class InputDialog {
    public formGroup: FormGroup;

    public title: string;
    public message: string;
    public placeholder: string;

    constructor(public dialogRef: MatDialogRef<InputDialog>, @Inject(MAT_DIALOG_DATA) private _data: IInputDialogConfig, private _formBuilder: FormBuilder, public emh: ErrorMessageService) {
        this.title = this._data.title;
        this.message = this._data.message;
        this.placeholder = this._data.placeholder;
        this.formGroup = this._formBuilder.group({
            input: [_data.input, this._data.validators]
        });
    }
}
