import {InfoDialogComponent} from './info-dialog/info-dialog.component';
import {LnInfoBoxComponent} from './partner/ln-info-box/ln-info-box.component';
import {LOCALE_ID, NgModule} from '@angular/core';
import {MaterialModule} from './material/material.module';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {LanguageSelectorButtonComponent} from './language-selector/language-selector-button/language-selector-button.component';
import {LanguageSelectorDialogComponent} from './language-selector/language-selector-dialog/language-selector-dialog.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    LnInfoBoxComponent,
    InfoDialogComponent,
    LanguageSelectorButtonComponent,
    LanguageSelectorDialogComponent,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule
  ],
  exports: [
    LnInfoBoxComponent,
    LanguageSelectorButtonComponent,
    InfoDialogComponent,
  ],
  entryComponents: [
    InfoDialogComponent,
    LanguageSelectorDialogComponent
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID]
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }
  ]

})

export class SharedModule { }
