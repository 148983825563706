import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';

@Component({
  selector: 'app-cell-renderer-view',
  templateUrl: './cell-renderer-view.component.html',
  styleUrls: ['./cell-renderer-view.component.less']
})
export class CellRendererViewComponent implements AgRendererComponent {

  constructor() { }

  agInit() {

  }

  refresh() {
    return true;
  }

}
