import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.css']
})
export class LoadingDialogComponent {
  public title: string;
  public message: string;
  constructor(public dialogRef: MatDialogRef<LoadingDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any) {
      this.message = this._data.message;
      this.title = this._data.title;
  }
}
