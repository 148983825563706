import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LnCmsLargeCardComponent} from './components/large.card';
import {LnCmsPdfComponent} from './components/pdf';
import {LnCmsSmallCardComponent} from './components/small.card';
import {LnCmsVideoComponent} from './components/video';
import {MaterialModule} from '../material/material.module';
import {LnCmsElementComponent, LnCmsElementsComponent} from './ln-cms-elements.component';
import {LnCmsElementsDirective} from './ln-cms-elements.directive';
import {LnCmsService} from '../services/ln-cms.service';
import {AngularFirestore, AngularFirestoreModule, FirestoreSettingsToken} from '@angular/fire/firestore';

@NgModule({
  declarations: [
    LnCmsElementsComponent,
    LnCmsLargeCardComponent,
    LnCmsElementsDirective,
    LnCmsPdfComponent,
    LnCmsSmallCardComponent,
    LnCmsVideoComponent,
    LnCmsElementComponent],
  imports: [
    CommonModule,  MaterialModule, AngularFirestoreModule
  ],
  entryComponents: [
    LnCmsLargeCardComponent,
    LnCmsPdfComponent,
    LnCmsSmallCardComponent,
    LnCmsVideoComponent],
  providers: [
    LnCmsService, AngularFirestore,
    { provide: FirestoreSettingsToken, useValue: {} }
  ],
  exports: [LnCmsElementsComponent]
})
export class LnCmsElementsModule { }
