import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import {AngularFirestore, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore';
import { Company } from '../models/model';
import { Observable } from 'rxjs';
import {firestore} from 'firebase';


const COMPANY_COLLECTION = 'companies';

@Injectable()
export class CompanyService extends HelperService {

  constructor(_aFs: AngularFirestore) {
    super(_aFs);
  }

  public getCompanies$(): Observable<Company[]> {
    const collection: AngularFirestoreCollection<Company> = this._aFs.collection<Company>(COMPANY_COLLECTION, ref => {
        return ref.orderBy('name', 'asc');
    });
    return super.mapSnapshotChanges$(collection, Company);
  }

  public getCompanies(): Promise<Company[]> {
    return this._aFs.firestore.collection(COMPANY_COLLECTION).get()
      .then((querySnapshot) => {
        return super.mapQuerySnapshot<Company>(querySnapshot, Company);
      });
  }

  async addCompany(company: Company): Promise<DocumentReference> {
    company.customerId = await this.getNewCustomerId();
    return this._aFs.firestore.collection('companies').add(company.data());
  }

  public getNewCustomerId(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      return this._aFs.firestore.collection('companies').limit(1).orderBy('customerId', 'desc').get().then(querySnapshot => {
        super.mapQuerySnapshot<Company>(querySnapshot, Company).then(docData => {
          if (docData.length === 0 || !(typeof docData[0].customerId === 'number') || isNaN(docData[0].customerId) || docData[0].customerId <= 0) {
            reject('Error: Not able to generate a new customer id.');
            return;
          }
          resolve(docData[0].customerId + 1);
        });
      });
    });
  }

  public removeRestaurants(restaurants: DocumentReference[], ignore: Company): Promise<void> {
    if (!restaurants || restaurants.length === 0) {
      return Promise.resolve();
    }
    const worker = async () => {
      const querySnaps = await Promise.all(
          restaurants.map(ref => this._aFs.firestore.collection('companies')
              .where('restaurants', 'array-contains', ref).get())
      );
      const ignoreId = ignore ? ignore.id : '_';
      const updates = querySnaps.map((querySnap, index) => {
        const _updates = [];
        if (!querySnap.empty) {
          querySnap.forEach(snapshot => {
            if (ignoreId !== snapshot.id) {
              _updates.push({ref: snapshot.ref, update: {restaurants: firestore.FieldValue.arrayRemove(restaurants[index])}});
            }
          });
        }
        return _updates;
      }).reduce((array, _updates) => {
        if (_updates.length > 0) {
          array.push(..._updates);
        }
        return array;
      }, []);
      await super.updateDocuments(updates);
    };
    return worker();
  }
}
