import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-loading-grid-overlay',
  templateUrl: './loading.grid.overlay.component.html',
  styleUrls: ['./loading.grid.overlay.component.less']
})


export class CustomLoadingGridOverlayComponent implements ILoadingOverlayAngularComp {
  params: any;
  agInit(params): void {
    this.params = params;
  }
}


