import {environment} from '../environments/environment';

import {Component, Inject, LOCALE_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(@Inject(LOCALE_ID) _locale: string, _ts: TranslateService) {
    _ts.setDefaultLang(environment.defaultLang);
    _ts.use(_locale);
    moment.locale(_locale);
    this.addAnalyticsSnippet();
  }

  private addAnalyticsSnippet() {
    const script = document.createElement('script');
    script.innerHTML = `
       var firebaseConfig = {
        apiKey: "${environment.firebase.apiKey}",
        authDomain: "${environment.firebase.authDomain}",
        databaseURL: "${environment.firebase.databaseURL}",
        projectId: "${environment.firebase.projectId}",
        storageBucket: "${environment.firebase.storageBucket}",
        messagingSenderId: "${environment.firebase.messagingSenderId}",
        appId: "${environment.firebase.appId}",
        measurementId: "${environment.firebase.measurementId}"
      };
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();
    `;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
