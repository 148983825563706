import { ErrorMessageService } from './../services/error-message.service';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as moment from 'moment-timezone';
import { IDatePickerConfig } from 'ng2-date-picker';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { ValidatorService } from '../services/validator.service';
import { TranslateService } from '@ngx-translate/core';
import { Goodie } from '../models/model';
import { Moment } from 'moment';
import { RestaurantService } from '../services/restaurant.service';
import { firestore } from 'firebase';
import { Utils } from '../app.utils';


@Component({
  selector: 'app-goodie-dialog',
  templateUrl: './goodie-dialog.component.html',
  styleUrls: ['./goodie-dialog.component.less']
})
export class GoodieDialogComponent implements OnDestroy {
  public formGroup: FormGroup;
  public dialogTitle: string;
  public minDate = moment();
  daynames: Map<number, string>;
  timePickerConfig: IDatePickerConfig;
  weekdays: number[];
  subscription: Subscription;
  constructor(private _ts: TranslateService,
    public media: MediaObserver,
    public dialogRef: MatDialogRef<GoodieDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: Goodie,
    private _formBuilder: FormBuilder,
    private _vh: ValidatorService,
    private _rs: RestaurantService,
    public emh: ErrorMessageService) {
    if (moment(_data.startDate).isBefore(this.minDate)) {
      this.minDate = moment(_data.startDate);
    }
    this.daynames = new Map();
    this.daynames.set(1, this._ts.instant('monday'));
    this.daynames.set(2, this._ts.instant('tuesday'));
    this.daynames.set(3, this._ts.instant('wednesday'));
    this.daynames.set(4, this._ts.instant('thursday'));
    this.daynames.set(5, this._ts.instant('friday'));
    this.daynames.set(6, this._ts.instant('saturday'));
    this.daynames.set(7, this._ts.instant('sunday'));
    this.weekdays = [1, 2, 3, 4, 5, 6, 7];
    this.timePickerConfig = {
      format: 'HH:mm',
      drops: 'down',
      opens: 'left',
      min: moment('2000-01-01T00:00:00'),
      hours24Format: 'HH',
      minutesInterval: 15,
      showTwentyFourHours: true
    };
    this.formGroup = this._formBuilder.group({
      text: [_data.text, [Validators.maxLength(160), Validators.required, this._vh.noEmojiValidator()]],
      code: [_data.code, [Validators.minLength(6), Validators.required, this._vh.noEmojiValidator()]],
      start: [_data.startDate, this.dateIsSameOrBeforeEndDate()],
      end: [_data.endDate, this.dateIsSameOrAfterStartDate()],
      value: _data.value,
      from: this.getFormattedTime(_data.fromDate, true),
      to: this.getFormattedTime(_data.toDate, false),
      weekdays: [_data.weekdays || this.weekdays]
    });
    this.subscription = this.formGroup.controls['end'].valueChanges.subscribe(
      (value) => {
        // manually change value before validation of start
        this.formGroup.value.end = value;
        this.formGroup.controls['start'].updateValueAndValidity();
      }
    );
    _data.text ? this.dialogTitle = this._ts.instant('edit_goodie') : this.dialogTitle = this._ts.instant('add_goodie');
    // _data.weekdays ? this.dialogTitle = this._ts.instant('edit_goodie') : this.dialogTitle = this._ts.instant('add_goodie');
  }
  onSubmit() {
    const result = {
      text: Utils.trimWhiteSpace(this.formGroup.value.text),
      code: this.formGroup.value.code,
      value: this.formGroup.value.value,
      start: this.getTimestamp(this.formGroup.value.start),
      end: this.getTimestamp(this.formGroup.value.end),
      from: this.getTimestamp(this.formGroup.value.start, this.formGroup.value.from),
      to: this.getTimestamp(this.formGroup.value.end, this.formGroup.value.to),
      weekdays: this.formGroup.value.weekdays,
    };
    const goodie = Goodie.parse(result);
    goodie.ref = this._data.ref;
    this.dialogRef.close(goodie);
  }

  onGoodieDisable() {
    this.formGroup.value.start = null;
    this.formGroup.value.end = null;
    this.onSubmit();
  }
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
  dateIsSameOrAfterStartDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const date = this.formGroup ? this.formGroup.value.start : this._data.startDate;
        let sameOrAfter;
        if (!moment.isMoment(date)) {
          sameOrAfter = !moment(date).isSameOrBefore(moment(control.value));
        } else {
          sameOrAfter = !date.isSameOrBefore(moment(control.value));
        }
        return sameOrAfter ? { 'sameOrAfter': { value: control.value } } : null;
      }
    };
  }

  getFormattedTime(date: Date, start: boolean): string {
    const defaultTime = start ? '12:00' : '15:00';
    if (!date) {
      return defaultTime;
    }
    const momentTime = moment(date);
    if (momentTime.hours() === 0 && momentTime.minutes() === 0 && momentTime.seconds() === 0 ||
      momentTime.hours() === 23 && momentTime.minutes() === 59 && momentTime.seconds() === 59) {
      return defaultTime;
    }
    return momentTime.format('HH:mm');
  }
  dateIsSameOrBeforeEndDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const date = this.formGroup ? this.formGroup.value.end : this._data.endDate;
      let sameOrBefore;
      if (!moment.isMoment(date)) {
        sameOrBefore = date ? !moment(date).isSameOrAfter(moment(control.value)) : false;
      } else {
        sameOrBefore = date ? !date.isSameOrAfter(moment(control.value)) : false;
      }
      return sameOrBefore ? { 'sameOrBefore': { value: control.value } } : null;
    };
  }

  removeEndDate() {
    this.formGroup.controls['end'].setValue(null);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getTimestamp(date: Date | Moment | null, time?: string): firestore.Timestamp | null {
    const momentDate = moment.isMoment(date) ? date : (moment.isDate(date) ? moment(date) : null);
    if (!momentDate) {
      return null;
    }
    const momentTime = moment(time || '00:00', 'HH:mm');
    momentDate.set({
      hour: momentTime.get('hour'),
      minute: momentTime.get('minute'),
      second: momentTime.get('second')
    });
    return firestore.Timestamp.fromDate(momentDate.toDate());
  }
  get isNew(): boolean {
    return !this._data.id;
  }
}
