import { Component, Input, OnInit } from '@angular/core';
import { Restaurant } from '../../models/model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageService } from '../../services/error-message.service';

@Component({
  selector: 'app-partner-restaurants-details-form',
  templateUrl: './partner-restaurants-details-form.component.html',
  styleUrls: ['./partner-restaurants-details-form.component.less']
})
export class PartnerRestaurantsDetailsFormComponent implements OnInit {

  @Input() formGroup: FormGroup;  
  @Input() editMode: boolean;
  @Input() canEdit: boolean;
  @Input() set restaurant(restaurant: Restaurant) {
    this._restaurant = restaurant;
    if(restaurant) {
      this.setup();
    }
  };
  get restaurant(): Restaurant {
    return this._restaurant;
  }
  private _restaurant: Restaurant;
  
  detailProps = ['handicap', 'parkingExternal', 'parkingRestaurant', 'smokingInside', 'smokingOutside', 'wifi', 'deliveryEnabled', 'takeawayEnabled', 'reservationEnabled'];
  paymentProps = ['paymentCA', 'paymentEC', 'paymentVS', 'paymentMC', 'paymentAE', 'paymentDC', 'sodexo', 'ticketRestaurant', 'paypal'];
  chips: any = {
    handicap: {name: this._ts.instant('accessible')},
    parkingExternal: {name: this._ts.instant('parking_garage')},
    parkingRestaurant: {name: this._ts.instant('customer_parking')},
    smokingInside: {name: this._ts.instant('smoking_inside')},
    smokingOutside: {name: this._ts.instant('smoking_outside')},
    wifi: {name: this._ts.instant('wifi')},
    deliveryEnabled: {name: this._ts.instant('delivery')},
    takeawayEnabled: {name: this._ts.instant('takeaway')},
    reservationEnabled: {name: this._ts.instant('reservation')},
    paymentCA: {name: this._ts.instant('cash')},
    paymentEC: {name: this._ts.instant('debit_cards')},
    paymentVS: {name: this._ts.instant('visa')},
    paymentMC: {name: this._ts.instant('mastercard')},
    paymentAE: {name: this._ts.instant('american_express')},
    paymentDC: {name: this._ts.instant('diners_club')},
    sodexo: {name: this._ts.instant('sodexo')},
    ticketRestaurant: {name: this._ts.instant('ticket_restaurant')},
    paypal: {name: this._ts.instant('paypal')}
  };

  constructor(
    private _ts: TranslateService,
    public emh: ErrorMessageService,
    private _formBuilder: FormBuilder
  ) {
    this.editMode = false;
  }

  ngOnInit() { }

  setup() {
    this.formGroup = this._formBuilder.group({
      handicap: this.restaurant.details.handicap,
      parkingExternal: this.restaurant.details.parkingExternal,
      parkingRestaurant: this.restaurant.details.parkingRestaurant,
      smokingInside: this.restaurant.details.smokingInside,
      smokingOutside: this.restaurant.details.smokingOutside,
      wifi: this.restaurant.details.wifi,
      deliveryEnabled: this.restaurant.details.deliveryEnabled,
      takeawayEnabled: this.restaurant.details.takeawayEnabled,
      reservationEnabled: this.restaurant.details.reservationEnabled,
      paymentCA: this.restaurant.details.paymentCA,
      paymentEC: this.restaurant.details.paymentEC,
      paymentVS: this.restaurant.details.paymentVS,
      paymentMC: this.restaurant.details.paymentMC,
      paymentAE: this.restaurant.details.paymentAE,
      paymentDC: this.restaurant.details.paymentDC,
      sodexo: this.restaurant.details.sodexo,
      ticketRestaurant: this.restaurant.details.ticketRestaurant,
      paypal: this.restaurant.details.paypal,
      seatsInside: [this.restaurant.details.seatsInside || 0, [Validators.required, Validators.min(0), Validators.pattern(/^\d+$/)]],
      seatsOutside: [this.restaurant.details.seatsOutside || 0, [Validators.required, Validators.min(0), Validators.pattern(/^\d+$/)]]
    });
  }

  availableDetailData(isPayment: boolean) {
    for (const chip of this.chips) {
      if (this.restaurant.details[chip.property] && (isPayment === chip.isPayment)) {
        return true;
      }
    }
    return false;
  }

  submit() {
    if (!this.canEdit) {
      return;
    }
    const update = {
      details: {
        ...this.formGroup.value
      }
    };
    return this.restaurant.update(update);    
  }

}
