export class Utils {
  private static _timestamp: Date;
  private static _startTimestamp: Date;
  /**
   * Check if given string is empty or not. This function may be extended for object literals and arrays as well
   * @param txt - string to check
   * @returns {boolean}
   */
  public static isEmpty(txt: string): boolean {
    if (txt === undefined || txt === null) {
      return true;
    }
    return txt.trim().length === 0;
  }

  /**
   * Helper promise with a state, see usage in LanguageService class
   * @param promise
   */
  public static statePromise<T>(promise: Promise<T>): Promise<T> {
    let isPending = true;
    const result: any = promise.then(
      (data) => {
        isPending = false;
        return data;
      }
    ).catch(error => {
      isPending = false;
      throw error;
    });
    result.isPending = () => isPending;
    return result;
  }
  public static compare(strA: string, strB: string): number {
    const a = strA.toLowerCase().trim();
    const b = strB.toLowerCase().trim();
    return a < b ? -1 : a > b ? 1 : 0;
  }
  /**
   * help method for computing text hash, similar to java hashCode() method
   * @param txt
   */
  public static hashCode (txt: string): number {
    let hash = 0, i, chr;
    if (!txt || txt.length === 0) {
      return hash;
    }
    for (i = 0; i < txt.length; i++) {
      chr   = txt.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
  /**
   * function for testing execution time between two calls
   * @param label
   * @param reset
   * @private
   */
  public static _time(label: string, reset?: boolean) {
    if (!Utils._startTimestamp || reset) {
      Utils._startTimestamp = new Date();
      Utils._timestamp = new Date();
    }
    const now = new Date();
    if (Utils._timestamp) {
      const time = now.getTime() - Utils._timestamp.getTime();
      const msg = `_time: [${label}] took ${time} millis from last call. ${(now.getTime() - Utils._startTimestamp.getTime())} from first.`;
      if (time < 1000) {
        console.log(msg);
      } else {
        console.warn(msg);
      }
    } else {
      console.log(`_time: [${label}] first call.`);
    }
    Utils._timestamp = now;
  }
  /**
   * function for trailing whitespaces in meal descriptions/titles
   * @param label
   * @param reset
   * @private
   */
  public static trimWhiteSpace(x) {
    return x ? x.replace(/^\s+|\s+$/g, '') : '';
  }
  /**
   * Wait for ms milliseconds and resolve the promise
   * @param ms milliseconds
   */
  public static async waitFor(ms: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

}
