import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import {
  Additive,
  Allergen,
  Attribute,
  Company,
  Cuisine,
  Meal,
  MealType,
  MergedMeal,
  Pool,
  PoolMeal,
  Restaurant,
} from '../models/model';
import { HelperService } from './helper.service';
import {
  AngularFirestore,
  DocumentReference,
  DocumentSnapshot,
} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

import { Utils } from '../app.utils';
import { Moment } from 'moment';
import { RestaurantService } from './restaurant.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { firestore } from 'firebase';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { query } from '@angular/core/src/render3';

@Injectable()
export class MealService extends HelperService {
  constructor(
    _aFs: AngularFirestore,
    private _rs: RestaurantService,
    private _http: HttpClient,
    private _us: UserService
  ) {
    super(_aFs);
  }
  public getAttributes(): Promise<Attribute[]> {
    return super.getCollection<Attribute>('attributes', Attribute, {
      fieldPath: 'name',
      direction: 'asc',
    });
  }

  public getAdditives(): Promise<Additive[]> {
    return super.getCollection<Additive>('additives', Additive, {
      fieldPath: 'name',
      direction: 'asc',
    });
  }

  public getCuisines(): Promise<Cuisine[]> {
    return super.getCollection<Cuisine>('cuisines', Cuisine, {
      fieldPath: 'name',
      direction: 'asc',
    });
  }

  public getAllergens(): Promise<Allergen[]> {
    return super.getCollection<Allergen>('allergens', Allergen, {
      fieldPath: 'name',
      direction: 'asc',
    });
  }

  public getMealTypes(): Promise<MealType[]> {
    return super.getCollection<MealType>('meal_types', MealType, {
      fieldPath: 'name',
      direction: 'asc',
    });
  }



  private mergeMeals(
    restaurant: Restaurant,
    poolMeals: PoolMeal[] = [],
    meals: Meal[] = []
  ): Meal[] {
    let maxOrder = Number.MIN_SAFE_INTEGER;
    const ordersSet = new Set<number>();
    let merged: Meal[] = [];
    const mealsMap = new Map<string, Meal>();
    const resPoolMealsMap = new Map<string, Meal>();

    for (const meal of meals) {
      if (meal.poolMeal) {
        resPoolMealsMap.set(meal.poolMeal.ref.path, meal);
      } else {
        mealsMap.set(meal.ref.path, meal);
      }
      ordersSet.add(meal.order);
      maxOrder = Math.max(maxOrder, meal.order);
    }

    for (const meal of poolMeals) {
      const resPoolMeal = resPoolMealsMap.get(meal.ref.path);

      if (resPoolMeal) {
        merged.push(new MergedMeal(meal, resPoolMeal));
      } else {
        // TODO we must handle this case somehow, there is a pool meal defined, but in the restaurant meals collection
        //  there is no init data (ref, order, ...) for this pool meal
        const resMealOrder = ordersSet.has(meal.order)
          ? ++maxOrder
          : meal.order;
        ordersSet.add(resMealOrder);
        const resMeal = new Meal({
          ref: restaurant.ref.collection('meals').doc(),
          data: () => {
            return {
              order: resMealOrder,
              poolMeal: meal.ref,
            };
          },
        } as DocumentSnapshot<Meal>);
        // remove default value created in constructor, the meal will be merged, so is it important to know later
        // if the meal has own tags defined or not.
        delete resMeal.tags;
        merged.push(new MergedMeal(meal, resMeal, false));
        // and take care during updating maybe
        console.warn(
          `The pool meal from '${meal.ref.path}' is not saved in restaurant (${this._rs.getSelectedRestaurant().ref.path
          }) meals collection`
        );
      }
    }

    merged = merged.concat(
      Array.from(mealsMap.keys()).map((key) => mealsMap.get(key))
    );
    merged.sort((a: any, b: any) => {
      const comp = b.order - a.order;
      return isNaN(comp) ? (a.order ? -1 : 1) : comp;
    });

    return merged;
  }

  /**
   * @param restaurant
   */
  public getRestaurantMeals$(restaurant: Restaurant): Observable<Meal[]> {
    // TODO add pool observable for listing for new or deleted pools
    // filter the pool for the restaurant on client side
    const pools: Pool[] = this._rs
      .getPools()
      .filter(
        (pool) =>
          pool.restaurants &&
          (pool.restaurants === true ||
            !!pool.restaurants.find((ref) => ref.isEqual(restaurant.ref)))
      );

    const onlyPoolMeals = RestaurantService.hasSettingOnlyPoolMeals(
      pools,
      restaurant
    );
    const poolObservables = [];

    for (const pool of pools) {
      // get all pool meals Observables from all pools
      poolObservables.push(this.getMealObservable(pool.ref, pool));
    }
    // combine all last pools meals objects with all last meals objects in observable
    return combineLatest([
      combineLatest(poolObservables.length > 0 ? poolObservables : of([])),
      this.getMealObservable(restaurant.ref),
    ]).pipe(
      // first ist a array of pool meals (array.length = pools.length), e.g.  [[poolAmeal1, poolAmeal2], [poolBmeal1, poolBmeal2]}
      // second ist a array of restaurant meals
      map(([first, second]) => {
        const result = [];
        const mealsFromPool = result.concat(...first);

        return this.mergeMeals(
          restaurant,
          mealsFromPool,
          second.filter(
            (m) => (onlyPoolMeals && m.isMergedMeal()) || !onlyPoolMeals
          )
        );
      })
    );
  }

  // TEST
  // public getRestaurantMeals$(restaurant: Restaurant): Observable<Meal[]> {
  //   const collection = this._aFs.collection<Meal>(restaurant.meals, ref => {
  //     return ref.orderBy('order', 'desc');
  //   });
  //   return super.mapSnapshotChanges$(collection, Meal);
  // }
  public getPoolMeals$(pool: Pool): Observable<PoolMeal[]> {
    return this.getMealObservable(pool.ref, pool);
  }
  // TEST

  private getMealObservable(
    documentRef: DocumentReference,
    pool?: Pool
  ): Observable<any[]> {
    const collection = this._aFs.collection<any>(
      documentRef.collection('meals'),
      (ref) => {
        return ref.orderBy('order', 'desc');
      }
    );

    return collection.snapshotChanges().pipe(
      map((actions) => {
        if (actions.length === 0) {
          return [];
        }
        return actions.map((event) => {
          if (pool) {
            return new PoolMeal(pool, event.payload.doc);
          }
          const snap = event.payload.doc;
          const meal = new Meal(snap);

          if (snap.exists && !snap.data().tags && meal.isMergedMeal()) {
            // remove default value created in constructor, the meal will be merged later, so is it important to know
            // if the meal defined own tags or not
            delete meal.tags;
          }

          return meal;
        });
      })
    );
  }
  public updateOrder(
    meals: {
      ref: firebase.firestore.DocumentReference;
      update: { order: number };
    }[]
  ): Promise<void> {
    return super.updateDocuments(meals);
  }

  public getAvailability(meal: Meal, loadFromPool: boolean): Promise<string[]> {
    if (!meal || Utils.isEmpty(meal.id)) {
      return Promise.resolve([]);
    }

    const today = firestore.Timestamp.fromDate(
      moment().startOf('day').toDate()
    );
    const mapSnapshots = (querySnapshot: any) => {
      const list: string[] = [];

      querySnapshot.forEach((documentSnapshot) => {
        list.push(documentSnapshot.id);
      });

      return list;
    };
    const poolMealAvailability = (_meal: Meal) => {
      if (_meal.poolMeal) {
        return _meal.poolMeal.ref
          .collection('availability')
          .where('date', '>=', today)
          .get()
          .then((poolQuerySnapshot) => {
            return Promise.resolve(mapSnapshots(poolQuerySnapshot));
          });
      }

      return Promise.resolve([]);
    };

    if (loadFromPool) {
      return poolMealAvailability(meal);
    }

    return meal.availability
      .where('date', '>=', today)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty || !meal.isMergedMeal()) {
          return Promise.resolve(mapSnapshots(querySnapshot));
        }

        const merged = <MergedMeal>meal;

        merged.availabilityExists = false;
        return poolMealAvailability(meal);
        //  return [];
      });
  }

  private updateAvailabilities(
    elements: any[]
  ): firebase.firestore.WriteBatch[] {
    if (!elements || elements.length === 0) {
      return [];
    }
    // prepare batches for update, max batch size is 500, so divide to 500 length blocks
    const batches = [];
    const length = elements.length;

    for (let i = 0; i < length; i++) {
      if (i % HelperService.MAX_BATCH_SIZE === 0) {
        batches.push(firebase.firestore().batch());
      }
      if (elements[i].set) {
        batches[batches.length - 1].set(elements[i].ref, elements[i].set);
      } else if (elements[i].delete) {
        batches[batches.length - 1].delete(elements[i].ref);
      }
    }
    return batches;
  }

  private existsInMoments(dates: Moment[], formattedDate: string): boolean {
    for (const date of dates) {
      if (date.format('YYYY-MM-DD') === formattedDate) {
        return true;
      }
    }
    return false;
  }

  public update(
    meal: Meal,
    update: any,
    oldDates: Moment[],
    newDates: Moment[]
  ): Promise<void> {
    const fields: string[] = meal.isMergedMeal()
      ? (<MergedMeal>meal).pool.canEdit
      : Object.keys(update.update);
    let availabilityUpdate = [];
    const isPoolMeal = meal.isMergedMeal();

    let discountMeal: any;


    for (const date of newDates) {
      if (date.get('hour') < 2) {
        // FIX for timezone issues. The time for the meal will be saved like: 7.September 2019 um 02:00:00 UTC+2
        // instead of 7.September 2019 um 00:00:00 UTC+2 what causes the issue with the timezone and getting proper offer day
        // in backend
        date.add(2, 'hour');
      }

      const dateFormat = date.format('YYYY-MM-DD');
      // is new
      if (!this.existsInMoments(oldDates, dateFormat)) {
        availabilityUpdate.push({
          ref: meal.availability.doc(dateFormat),
          set: { date: firebase.firestore.Timestamp.fromDate(date.toDate()) },
        });
      }
    }
    for (const date of oldDates) {
      const dateFormat = date.format('YYYY-MM-DD');
      // is deleted
      if (!this.existsInMoments(newDates, dateFormat)) {
        availabilityUpdate.push({
          ref: meal.availability.doc(dateFormat),
          delete: true,
        });
      }
    }
    if (
      availabilityUpdate.length > 0 &&
      isPoolMeal &&
      !(<MergedMeal>meal).availabilityExists &&
      fields.includes('availability')
    ) {
      availabilityUpdate = newDates.map((date) => {
        const dateFormat = date.format('YYYY-MM-DD');

        return {
          ref: meal.availability.doc(dateFormat),
          set: { date: firebase.firestore.Timestamp.fromDate(date.toDate()) },
        };
      });
    }
    const batches = this.updateAvailabilities(availabilityUpdate);

    return meal.update(update.update).then(() => {
      return Promise.all(batches.map((batch) => batch.commit())).then(() =>
        Promise.resolve()
      );
    })
  }

  public add(
    doc: Restaurant | Pool,
    data: any,
    dates: Moment[] = []
  ): Promise<void> {
    const newMeal = Meal.parse(data.update);
    const newDoc = doc.meals.doc();
   

    const availabilityUpdate = [];
    const isPool = doc.ref.path.startsWith('pools/');

    for (const date of dates) {
      availabilityUpdate.push({
        ref: newDoc.collection('availability').doc(date.format('YYYY-MM-DD')),
        //  FIX for timezone issues
        set: {
          date: firebase.firestore.Timestamp.fromDate(
            date.add(2, 'hour').toDate()
          ),
        },
      });
    }

    const updateAvailabilitiesBatches = this.updateAvailabilities(
      availabilityUpdate
    );

    const saveMeal = async () => {
      if (isPool) {
        // not pretty workaround for the problem with the creating the pool meal (newDoc.set(newMeal.data())
        // onPoolMealCreated trigger is fired, after creating the pool meal
        // the trigger generate the init meal data for every restaurant in the pool
        // this backend operation must be finished before adding the availability to the collection
        // in worse case not all offers could be generated
        return this._us.user.firebaseUser.getIdToken().then((token) => {
          const url = `${environment.poolsApiURL}/createMeal/${doc.id}/${newDoc.id}?auth=${token}`;
          return this._http.post(url, this.serializeMeal(newMeal)).toPromise();
        });
      }
      return newDoc.set(newMeal.data());
    };

    return saveMeal().then(() => {
      return Promise.all(
        updateAvailabilitiesBatches.map((batch) => batch.commit())
      ).then(
        () => Promise.resolve());
    })
  }

  public updateOrderable(doc: Restaurant): Promise<void> {
    return this._us.user.firebaseUser.getIdToken().then((token) => {
      const url = `${environment.restaurantsApiURL}/mealOrderable/${doc.id}?auth=${token}`;
      return this._http.post(url, null).toPromise();
    });
  }

  public updateNotOrderable(doc: Restaurant): Promise<void> {
    return this._us.user.firebaseUser.getIdToken().then((token) => {
      const url = `${environment.restaurantsApiURL}/mealNotOrderable/${doc.id}?auth=${token}`;
      return this._http.post(url, null).toPromise();
    });
  }

  private serializeMeal(meal: Meal): any {
    const mealData = meal.data();
    if (mealData.type) {
      mealData.type = meal.type.id;
    }
    if (mealData.tags) {
      mealData.tags = meal.tags.map((t) => t.id);
    }
    if (mealData.allergens) {
      mealData.allergens = meal.allergens.map((t) => t.id);
    }
    if (mealData.additives) {
      mealData.additives = meal.additives.map((t) => t.id);
    }
    return mealData;
  }

  public async getCompanyAvailableOffers(company: DocumentReference, from: Date) {
    const promises = [];

    const querySnapshot = this._aFs.firestore.collection('restaurants').where('company', '==', company).get();


    await querySnapshot.then(async snapshot => {
      for (const doc of snapshot.docs) {

        const offerQuerySnapshot = doc.ref.collection('offers').where('date', '>=', from).get();

        await offerQuerySnapshot.then(offers => {
          offers.docs.forEach(offer => {
            promises.push(offer.data());
          });
        });
      }
    });
    return promises;
  }

  public async getRestaurantAvailableOffers(restaurant: DocumentReference, from: Date) {
    const promises = [];

    const offerQuerySnapshot = this._aFs.firestore.collection('restaurants').doc(restaurant.id).collection('offers').where('date', '>=', from).get();

   await offerQuerySnapshot.then(offers => {
      offers.docs.forEach(offer => {
        promises.push(offer.data());
      });
    });
    return promises;
  }
}
