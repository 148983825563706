import {Component, Input, OnInit} from '@angular/core';
import {LnCmsElement} from '../../models/model';

/*
  currently you can embed every html into value element.
  TODO limit html elements, e.g. to only strong, i, small, etc...
 */
@Component({
  selector: 'app-ln-cms-large-card',
  template: `
    <mat-card class="large">
      <img *ngIf="image" mat-card-image [src]="image">
      <mat-card-content>
        <p *ngIf="headline" class="headline">{{headline}}</p>
        <p class="value" [innerHTML]="value"></p>
        <a *ngIf="url" class="link" [href]="url">
          {{element?.urlDescription}}
        </a>
      </mat-card-content>
    </mat-card>`,
  styleUrls: ['../ln-cms-elements.component.less']
})
export class LnCmsLargeCardComponent implements OnInit {
  @Input() element: LnCmsElement;
  constructor() {}
  ngOnInit(): void {
  }
  get image(): string | null {
    return this.getValue('image');
  }
  get headline(): string | null {
    return this.getValue('headline');
  }
  get value(): string | null {
    return this.getValue('value');
  }
  get url(): string | null {
    return this.getValue('url');
  }
  getValue(key: string): string | null {
    if (this.element && this.element[key]) {
      return this.element[key];
    }
    return null;
  }
}
