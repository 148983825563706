export const APP_ROUTE_PATHS = {
  partner: 'partner',
  login: 'login',
  logout: 'logout'
};
export const PARTNER_ROUTE_PATHS = {
  restaurants_general: 'restaurants/general',
  restaurants_openinghours: 'restaurants/openinghours',
  restaurants_address: 'restaurants/address',
  restaurants_details: 'restaurants/details',
  restaurants_media: 'restaurants/media',
  restaurants_menu_files: 'restaurants/menu-files',
  restaurants_facebook_connect: 'restaurants/facebook-connect',
  restaurants_print_function: 'restaurants/print-function',
  restaurants_widget: 'restaurants/widget',
  company_info: 'company/info',
  pool_meals: 'company/pools',
  menus: 'menus',
  meals: 'meals',
  dashboard: 'dashboard',
  restaurant_list: 'restaurant-list',
  partner_list: 'partner-list',
  attribute_manager: 'attribute-manager',
  help: 'help',
  admin_print_templates: 'admin-print-templates',
  language_manager: 'language-manager',
  tags_manager: 'tags-manager',
  lunchnow_cms: 'lunchnow-cms',
  company_list: 'company-list',
  admin_reports: 'admin-reports',
  payment: 'payment',
  discount_list: 'discount-list'
};
export const PROFILE_PERCENTAGE_THRESHOLD: number = 75;