import { AccessRole } from '../models/enums';
import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Tag } from '../models/model';
import { Observable } from 'rxjs';

const TAGS_COLLECTION = 'tags';

@Injectable()
export class TagService extends HelperService {
  constructor(_aFs: AngularFirestore) {
    super(_aFs);
  }

  public getTags(): Promise<Tag[]> {
      // TODO Access Role
      return this._aFs.firestore.collection(TAGS_COLLECTION).get()
      .then((querySnapshot) => {
        return super.mapQuerySnapshot<Tag>(querySnapshot, Tag);
      });
  }

  public getTags$(): Observable<Tag[]> {
    const collection: AngularFirestoreCollection<Tag> = this._aFs.collection<Tag>(TAGS_COLLECTION, ref => {
        return ref.orderBy('name', 'asc');
    });
    return super.mapSnapshotChanges$(collection, Tag);
  }

  public updateOrCreate(tag: Tag, update: any): Promise<void> {
    if (tag.id) {
        return tag.update(update);
    }
    return this._aFs.firestore.collection(TAGS_COLLECTION).doc().set(Tag.parse(update).data());
  }

}
