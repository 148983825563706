import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDatePickerConfig } from 'ng2-date-picker';
import { MediaObserver } from '@angular/flex-layout';
import { TranslateService } from '@ngx-translate/core';
import { Restaurant, Times } from '../../models/model';
import { Utils } from '../../app.utils';

@Component({
  selector: 'app-partner-restaurants-opening-hours-form',
  templateUrl: './partner-restaurants-opening-hours-form.component.html',
  styleUrls: ['./partner-restaurants-opening-hours-form.component.less']
})
export class PartnerRestaurantsOpeningHoursFormComponent implements OnInit {
  
  @Input() editMode;
  @Input() canEdit;
  @Input() formGroup: FormGroup;
  @Input() set restaurant(restaurant: Restaurant) {
    this._restaurant = restaurant;
    if(restaurant) {
      this.renderOpeningTimes();
    }
  };
  get restaurant(): Restaurant {
    return this._restaurant;
  }
  private _restaurant: Restaurant;

  timePickerConfig: IDatePickerConfig;
  weekdays = [{ title: this._ts.instant('monday'), key: '1' }, { title: this._ts.instant('tuesday'), key: '2' }, { title: this._ts.instant('wednesday'), key: '3' }, { title: this._ts.instant('thursday'), key: '4' }, { title: this._ts.instant('friday'), key: '5' }, { title: this._ts.instant('saturday'), key: '6' }, { title: this._ts.instant('sunday'), key: '7' }];
  invalidTime = this._ts.instant('invalid_time');

  constructor(private _ts: TranslateService,
    private _formBuilder: FormBuilder,
    public media: MediaObserver
  ) {
    this.setBlankForm();

    this.timePickerConfig = {
      format: 'HH:mm',
      drops: 'down',
      opens: 'left',
      min: moment('2000-01-01T00:00:00'),
      hours24Format: 'HH',
      minutesInterval: 15,
      showTwentyFourHours: true
    };
  }

  ngOnInit() { }

  addTime(type, day, start = moment('2000-01-01T09:00:00'), end = moment('2000-01-01T18:00:00')) {
    const control = <FormArray>this.formGroup.get(type).get(day);
    const newTimeGroup = this._formBuilder.group({
      start: [start, Validators.required],
      end: [end, Validators.required],
    }, { validator: ValidateTimeRange });
    control.push(newTimeGroup);
  }

  removeTime(type, day, index) {
    const control = <FormArray>this.formGroup.get(type).get(day);
    control.removeAt(index);
  }

  resetForm() {
    this.setBlankForm();
    this.renderOpeningTimes();
  }

  setBlankForm() {
    this.formGroup = this._formBuilder.group({
      openingHours: this._formBuilder.group({
        1: new FormArray([]), // monday
        2: new FormArray([]), // tuesday
        3: new FormArray([]), // wednesday
        4: new FormArray([]), // thursday
        5: new FormArray([]), // friday
        6: new FormArray([]), // saturday
        7: new FormArray([]) // sunday
      }),
      lunchTimes: this._formBuilder.group({
        1: new FormArray([]), // monday
        2: new FormArray([]), // tuesday
        3: new FormArray([]), // wednesday
        4: new FormArray([]), // thursday
        5: new FormArray([]), // friday
        6: new FormArray([]), // saturday
        7: new FormArray([]) // sunday
      })
    });
  }

  prepareData(dataSet: Times): Times {
    const weekdays = new Times();
    for (const weekdayKey of Object.keys(dataSet)) {
      const timeSpans = dataSet[weekdayKey];
      timeSpans.forEach(_ => {
        weekdays[weekdayKey] = timeSpans.map(obj => {
          return { start: obj.start.format('HH:mm'), end: obj.end.format('HH:mm') };
        });
        timeSpans.sort((a, b) => Utils.compare(a.start.toString(), b.start.toString()));
      });
    }
    return weekdays;
  }

  submit() {
    return this.restaurant.update({
      openingHours: this.prepareData(this.formGroup.getRawValue().openingHours),
      lunchTimes: this.prepareData(this.formGroup.getRawValue().lunchTimes)
    });
  }

  isLast(weekday, type, index) {
    return index + 1 === this.formGroup.get(type).get(weekday.key).value.length;
  }

  renderOpeningTimes() {
    this.setBlankForm();

    this.weekdays.forEach(weekday => {
      const openingHoursForWeekday = this.restaurant.openingHours[weekday.key];
      if (openingHoursForWeekday) {
        openingHoursForWeekday.forEach(timeSpan => {
          const startMoment = moment(`2000-01-01T${timeSpan.start}:00`);
          const endMoment = moment(`2000-01-02T${timeSpan.end}:00`);
          this.addTime('openingHours', weekday.key, startMoment, endMoment);
        });
      }

      const lunchTimesForWeekday = this.restaurant.lunchTimes[weekday.key];
      if (lunchTimesForWeekday) {
        lunchTimesForWeekday.forEach(timeSpan => {
          const startMoment = moment(`2000-01-01T${timeSpan.start}:00`);
          const endMoment = moment(`2000-01-02T${timeSpan.end}:00`);
          this.addTime('lunchTimes', weekday.key, startMoment, endMoment);
        });
      }
    });
  }

  formatReadOnly(date: FormGroup): String {
    if (!date.value.start && !date.value.end) {
      return 'N/A';
    }
    const startDate: moment.Moment = date.value.start;
    const endDate: moment.Moment = date.value.end;
    return `${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`;
  }
  
}

export function ValidateTimeRange(control: AbstractControl) {
  return null;

  // DISABLE FOR NOW: RC1
  // const startDate = moment(control.get('start').value);
  // const endDate = moment(control.get('end').value);

  // if (startDate instanceof moment && endDate instanceof moment) {
  //   const isBefore = startDate.isBefore(endDate);
  //   return isBefore
  //     ? null : { 'mismatch': true };
  // }

  // return { 'mismatch': true };

}
