import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserService} from './user.service';
import {HelperService} from './helper.service';
import {Restaurant, Role, User} from '../models/model';
import {Utils} from '../app.utils';
import {APP_ROUTE_PATHS, PARTNER_ROUTE_PATHS} from '../app.constants';
import {AccessArea, AccessRole} from '../models/enums';
import {AngularFireAuth} from '@angular/fire/auth';

export const AVAILABLE_ROUTES = {
  [AccessArea.admin]: [
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.attribute_manager}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.admin_reports}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.admin_print_templates}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.lunchnow_cms}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.language_manager}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.tags_manager}`
  ],
  [AccessArea.dashboard]: [
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.dashboard}`
  ],
  [AccessArea.restaurant]: [
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_general}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_details}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_openinghours}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_media}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_menu_files}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_address}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_facebook_connect}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_print_function}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurants_widget}`,
  ],
  [AccessArea.meals]: [
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.meals}`
  ],
  [AccessArea.menus]: [
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.menus}`
  ],
  [AccessArea.help]: [
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.help}`
  ],
  [AccessArea.company]: [
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.company_info}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.pool_meals}`
  ],
  [AccessArea.sales]: [
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.partner_list}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.restaurant_list}`,
    `/${APP_ROUTE_PATHS.partner}/${PARTNER_ROUTE_PATHS.company_list}`
  ]
};

/* the firestore roles are defined, that the system role 'user' doesn't have access to admin and salesman area.
 * if you define a restaurant role as follow.
 * {
 *  role: 'user',
 *  restaurants: {
 *    id1234: {
 *      name: 'name',
 *      roles: ['salesman']
 *    }
 *  }
 * }
 * it will be not working. You will have access to the 'Vetrieb' area for restaurant with id id1234,
 * but firestore will return  'permision denied' repsonse.
 */
@Injectable({providedIn: 'root'})
export class AccessService extends HelperService {

  public static ACCESS_EDIT = 'edit';
  public static ACCESS_VIEW = 'view';

  availableRoutes: { [name: string]: string[] };
  availableRoles: { [name: string]: Role };
  availableRolesPromise;
  availableRolesCallbacks = [];

  constructor(
    _aFs: AngularFirestore,
    private _us: UserService,
    private _afAuth: AngularFireAuth) {
    super(_aFs);
    this.availableRoutes = AVAILABLE_ROUTES;
    this.availableRoles = {};
    this.init();
  }

  public get user(): User {
    return this._us.user;
  }

  public waitForRoles(): Promise<void> {
    if (!this.availableRolesPromise) {
      return this.loadRoles();
    }
    if (this.availableRolesPromise.isPending()) {
      return new Promise((resolve, _reject) => {
        this.availableRolesCallbacks.push(() => {
          resolve();
        });
      });
    }
    return Promise.resolve();
  }

  public canLoadRoute(url: string, restaurant: Restaurant): Promise<boolean> {
    const route = url.split('?')[0];
    if (this.user.isAdmin) {
      return Promise.resolve(true);
    }
    if (!this.availableRolesPromise) {
      return Promise.resolve(false);
    }
    if (this.availableRolesPromise.isPending) {
      return this.waitForRoles().then(() => Promise.resolve(this.grantedRoutes(restaurant).includes(route)))
        .catch(error => {
          console.error(error);
          return Promise.resolve(false);
        });
    }
    return Promise.resolve(this.grantedRoutes(restaurant).includes(route));
  }

  /**
   * check if for given area the user has edit role
   * system role (admin, salesman, or whatever defined in user.role except 'user') has priority against the restaurant roles.
   * @param area
   * @param restaurant
   *
   * TODO area can be extended. E.g. restaurant.general, restaurant.lunchtimes...
   */
  public canEdit(area: string, restaurant?: Restaurant): boolean {
    if (!this.availableRoles) {
      return false;
    }
    if (this.user.isAdmin) {
      return true;
    }
    return this.role(restaurant).canEdit(area);
  }

  /**
   * check if for given area the access is granted
   * system role (admin, salesman, or whatever defined in user.role except 'user') has priority against the restaurant roles.
   * @param area
   * @param restaurant
   *
   * TODO area can be extended. E.g. restaurant.general, restaurant.lunchtimes...
   */
  public hasAccess(area: string, restaurant?: Restaurant): boolean {
    if (!this.availableRoles) {
      return false;
    }
    if (this.user.isAdmin) {
      return true;
    }
    return this.role(restaurant).hasAccess(area);
  }

  private init() {
    this._afAuth.auth.onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        this.loadRoles().catch(error => {
          console.error(error);
        });
      }
    });

  }

  private loadRoles(): Promise<void> {
    this.availableRolesPromise = Utils.statePromise<Role[]>(this.getCollection<Role>('roles', Role));
    return this.availableRolesPromise.then(roles => {
      this.setRoles(roles);
      if (this.availableRolesCallbacks) {
        for (const callback of this.availableRolesCallbacks) {
          callback();
        }
        this.availableRolesCallbacks = [];
      }
      return Promise.resolve();
    }).catch(error => {
      // if user not logged in - FirebaseError: Missing or insufficient permissions. error will be thrown
      // console.error(error);
    });
  }

  private setRoles(roles: Role[]) {
    this.availableRoles = {};
    for (const role of roles) {
      this.availableRoles[role.id] = role;
    }
  }

  /*
   * return list of allowed routed depending of user role
   * user role has priority against the restaurant roles.
   * user roles are admin or whatever defined in user.role, except 'user'.
   * if user.role == 'user', then the roles will be checked in user.restaurants.id.roles
   *
   * e.g. grantedRoutes
   * ...
   * "/partner/restaurants/address"
   * "/partner/restaurants/widget"
   * "/partner/meals"
   * "/partner/menus"
   * ...
   */
  private grantedRoutes(restaurant: Restaurant): string[] {
    const routes = ['/', `/${APP_ROUTE_PATHS.partner}`];
    if (!this.user || !this.availableRoles) {
      return routes;
    }
    if (this.user.isAdmin) {
      for (const value of Object.values(this.availableRoutes)) {
        routes.push(...value);
      }
      return routes;
    }
    const roleData = this.role(restaurant).data();
    for (const area of Object.keys(roleData)) {
      if (this.availableRoutes[area]) {
        routes.push(...this.availableRoutes[area]);
      }
    }
    return routes;
  }

  /*
   * return the user role. If the use has more roles, the access rights will be merged.
   * the rules are checked as follow:
   * 1) check if user.role exists and is not a 'user' role
   * 2) if user.role doesn't exist or user.role is a 'user' check the roles in user.restaurants.id.roles
   * the role name is ignored, important are here the access rights.
   * for more roles access rights will be merged:
   * [view, edit, view, view] and [edit, view, view, edit] gives [edit, edit, view, edit]
   */
  private role(restaurant?: Restaurant): Role {
    if (!Utils.isEmpty(this.user.role) && !this.user.hasRole(AccessRole.user)) {
      if (this.user.isAdmin) {
        const adminRole = {};
        Object.values(AccessArea).forEach(v => {
          adminRole[v] = AccessService.ACCESS_EDIT;
        });
        return Role.parse(adminRole);
      }
      const role = this.availableRoles[this.user.role];
      return role ? role : new Role();
    }
    const mergedRole: any = {};
    if (restaurant && this.user.restaurants) {
      const _restaurant = this.user.restaurants[restaurant.id] || {name: '', restaurant: null, roles: []};
      for (const roleName of _restaurant.roles) {
        const role = this.availableRoles[roleName];
        if (role) {
          const roleData = role.data();
          for (const area of Object.keys(roleData)) {
            mergedRole[area] = mergedRole[area] !== AccessService.ACCESS_EDIT ? roleData[area] : AccessService.ACCESS_EDIT;
          }
        }
      }
    }
    return Role.parse(mergedRole);
  }
}
