import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';

@Component({
  selector: 'app-cell-renderer-edit',
  templateUrl: './cell-renderer-edit.component.html',
  styleUrls: ['./cell-renderer-edit.component.less']
})
export class CellRendererEditComponent implements AgRendererComponent {

  constructor() { }

  agInit() {

  }

  refresh() {
    return true;
  }

}
