import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference
} from '@angular/fire/firestore';
import { LunchNowDocument, LunchNowObject } from '../models/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { firestore } from 'firebase';
import { Injectable } from '@angular/core';

export interface CollectionOrder {
  fieldPath: string;
  direction: 'asc' | 'desc';
}

@Injectable()
export class WindowRef {
  constructor() { }

  getNativeWindow() {
    return window;
  }
}

export class HelperService {

  public static MAX_BATCH_SIZE = 500;

  constructor(protected _aFs: AngularFirestore) { }

  /**
   * mapping function for the angular firestore collection snapshotChanges, returns live updates
   * @param collection
   * @param clazz
   */
  protected mapSnapshotChanges$<T extends LunchNowDocument<T>>(collection: AngularFirestoreCollection<T>, clazz?: new (data: any) => T): Observable<T[]> {
    return collection.snapshotChanges().pipe(map(actions => actions.map(event => {
      return new clazz(event.payload.doc);
    })));
  }
  /**
   * mapping function for the angular firestore document snapshotChanges, returns live updates
   * @param document
   * @param clazz
   */
  protected mapDocSnapshotChanges$<T extends LunchNowDocument<T>>(document: AngularFirestoreDocument<T>, clazz?: new (data: any) => T): Observable<T> {
    return document.snapshotChanges().pipe(map(action => {
      return action.payload.exists ? new clazz(action.payload) : null;
    }));
  }
  /**
   * mapping function for the firestore collection querySnapshot, returns a promise
   * @param querySnapshot
   * @param clazz
   */
  protected mapQuerySnapshot<T extends LunchNowDocument<T>>(querySnapshot, clazz?: new (data: any) => T): Promise<T[]> {
    const list: T[] = [];
    querySnapshot.forEach((documentSnapshot) => {
      list.push(new clazz(documentSnapshot));
    });
    return Promise.resolve(list);
  }

  /**
   * this method doesn't delete subcollections inside the collection and returns number of deleted documents
   *
   * https://firebase.google.com/docs/firestore/manage-data/delete-data
   *
   * To delete an entire collection or subcollection in Cloud Firestore, retrieve all the
   * documents within the collection or subcollection and delete them. If you have larger
   * collections, you may want to delete the documents in smaller batches to avoid out-of-memory errors.
   * Repeat the process until you've deleted the entire collection or subcollection.
   *
   * !!! Deleting collections from a Web client is not recommended. !!!
   *
   * see delete data with the Firebase CLI
   *
   * @param collection
   */
  protected deleteCollection(collection: CollectionReference): Promise<number> {
    return collection.get().then((querySnapshot: firebase.firestore.QuerySnapshot) => {
      const documents = [];
      querySnapshot.forEach((documentSnapshot: firebase.firestore.DocumentSnapshot) => {
        documents.push(documentSnapshot.ref);
      });
      return this.deleteDocuments(documents);
    });
  }

  /**
   * remove documents from firestore database and returns number of deleted documents
   * @param refs - documents to remove
   */
  protected deleteDocuments(refs: firebase.firestore.DocumentReference[]): Promise<number> {
    // prepare batches for delete, max batch size is 500, so divide to 500 length blocks
    const batches = [];
    const length = refs.length;
    for (let i = 0; i < length; i++) {
      if (i % HelperService.MAX_BATCH_SIZE === 0) {
        batches.push(firebase.firestore().batch());
      }
      batches[batches.length - 1].delete(refs[i]);
    }
    return Promise.all(batches.map(batch => batch.commit())).then((array) => {
      return length;
    });
  }
  /**
   * update more documents with update data
   * @param elements
   */
  protected updateDocuments(elements: { ref: firebase.firestore.DocumentReference, update: any }[]): Promise<void> {
    // prepare batches for delete, max batch size is 500, so divide to 500 length blocks
    const batches = [];
    const length = elements.length;
    for (let i = 0; i < length; i++) {
      if (i % HelperService.MAX_BATCH_SIZE === 0) {
        batches.push(firebase.firestore().batch());
      }
      // update documents or create if they not exist.
      batches[batches.length - 1].set(elements[i].ref, elements[i].update, {merge: true});
    }
    return Promise.all(batches.map(batch => batch.commit())).then(() => Promise.resolve());
  }

  /**
   * get whole collection as promise, without live changes
   * @param name
   * @param clazz
   */
  protected getCollection<T extends LunchNowDocument<T>>(name: string, clazz?: new (data: any) => T, order?: CollectionOrder): Promise<T[]> {
    if (order) {
      return this._aFs.firestore.collection(name).orderBy(order.fieldPath, order.direction).get().then(querySnapshot => this.mapQuerySnapshot<T>(querySnapshot, clazz));
    } else {
      return this._aFs.firestore.collection(name).get().then(querySnapshot => this.mapQuerySnapshot<T>(querySnapshot, clazz));
    }
  }

  /**
   * Remove items from array of property - field in LunchNowDocument
   * @param document
   * @param field
   * @param items
   */
  protected arrayRemove(document: LunchNowDocument<any>, field: string, items: LunchNowObject<any>[]): Promise<void> {
    if (!items || items.length === 0) {
      return Promise.resolve();
    }
    const toRemove = items.map(m => m.data());
    return document.ref.update({
      [field]: firestore.FieldValue.arrayRemove(...toRemove),
    });
  }

  /**
   * Add items of LunchNowObject type to the array of property - field in LunchNowDocument
   * @param document
   * @param field
   * @param items
   */
  protected arrayUnion(document: LunchNowDocument<any>, field: string, items: LunchNowObject<any>[]): Promise<void> {
    if (!items || items.length === 0) {
      return Promise.resolve();
    }
    const update = items.map(m => m.data());
    return document.ref.update({
      [field]: firestore.FieldValue.arrayUnion(...update),
    });
  }
}
