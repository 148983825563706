import { environment } from '../../environments/environment';
import { Injectable, OnDestroy } from '@angular/core';
import { HelperService } from './helper.service';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';

import {
  Goodie,
  IMenu,
  LunchNowReference,
  Pool,
  Restaurant,
  RestaurantName,
  User,
  RestaurantPrivateData,
  Company,
  RestTimeType,
} from '../models/model';
import { UserService } from './user.service';
import { Utils } from '../app.utils';
import { HttpClient } from '@angular/common/http';
import { map, pairwise, switchMap, filter, tap, startWith, take } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import * as firebase from 'firebase/app';
import { AccessService } from './access.service';
import { AccessArea, RestTimeType_Category } from '../models/enums';
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PROFILE_PERCENTAGE_THRESHOLD } from '../app.constants';
import { AlertService } from './alert.service';

@Injectable()
export class RestaurantService extends HelperService implements OnDestroy {
  subscriptionUser: Subscription;
  private subscriptionPools: Subscription;
  private subscriptionMealsOnline: Subscription;
  private subscriptionRestaurant: Subscription;
  private subscriptionRestaurantNames: Subscription;
  private subscriptionRestaurantPrivateData: Subscription;
  private selectedRestaurant$ = new BehaviorSubject<Restaurant>(null);
  private mealsOnline$ = new BehaviorSubject<any>(null);
  private selectedPool$ = new BehaviorSubject<Pool>(null);
  private restaurantNames$ = new BehaviorSubject<RestaurantName[]>([]);
  private pools$ = new BehaviorSubject<Pool[]>([]);
  private restaurantPrivateData$ = new BehaviorSubject<RestaurantPrivateData>(null);

  constructor(
    _aFs: AngularFirestore,
    private _us: UserService,
    private _ac: AccessService,
    private http: HttpClient,
    private _ts: TranslateService,
    private _as: AlertService,
  ) {
    super(_aFs);
    this.subscriptionUser = this._us.getUser$().subscribe((user: User) => {
      if (user && !Utils.isEmpty(user.id)) {
        this.init(user).catch(error => console.error(error));
      } else {
        this.reset();
      }
    }, error => console.error(error));

  }

  public static hasSettingOnlyPoolMeals(pools: Pool[], restaurant: Restaurant): boolean {
    if (!pools || pools.length === 0) {
      return false;
    }
    for (const pool of pools) {
      if (pool.settings && pool.settings.onlyPoolMeals) {
        const restaurants = pool.restaurants || [];
        if (restaurants === true || restaurants.some(r => r.id === restaurant.id)) {
          return true;
        }
      }
    }
    return false;
  }

  public getSelectedRestaurant$() {
    return this.selectedRestaurant$;
  }
  public areMealsOnline$() {
    return this.mealsOnline$;
  }
  public getRestaurantPrivateData$() {
    return this.restaurantPrivateData$;
  }

  public reset(): void {
    this.pools$.next([]);
    this.selectedRestaurant$.next(null);
    this.restaurantNames$.next([]);
    if (this.subscriptionRestaurant) {
      this.subscriptionRestaurant.unsubscribe();
    }
    if (this.subscriptionRestaurantNames) {
      this.subscriptionRestaurantNames.unsubscribe();
    }
    if (this.subscriptionPools) {
      this.subscriptionPools.unsubscribe();
    }
    if (this.subscriptionRestaurantPrivateData) {
      this.subscriptionRestaurantPrivateData.unsubscribe();
    }
    if (this.subscriptionMealsOnline) {
      this.subscriptionMealsOnline.unsubscribe();
    }
  }

  public addPool(pool: Pool): Promise<any> {
    return this._aFs.collection('pools').add(pool.data());
  }
  public deletePool(pool: Pool): Promise<void> {
    return pool.delete().then(() => Promise.resolve());
  }
  public getPools$() {
    return this.pools$;
  }
  public getPools(): Pool[] {
    return this.pools$.getValue();
  }
  public getSelectedPool$() {
    return this.selectedPool$;
  }
  public getSelectedRestaurant(): Restaurant {
    return this.getSelectedRestaurant$().getValue();
  }

  public getUsers(restaurant: Restaurant): Promise<User[]> {
    const query = this._aFs.firestore.collection('users').where(`restaurants.${restaurant.id}.restaurant`, '==', restaurant.ref);
    return query.get().then(querySnapshot => this.mapQuerySnapshot<User>(querySnapshot, User));
  }
  
  public setSelectedRestaurant(res: Restaurant | string | LunchNowReference<Restaurant>) {
    if (res instanceof Restaurant || res instanceof LunchNowReference) {
      this.subscribeRestaurant(this._aFs.doc(res.ref));
    } else {
      if (!res) {
        if (this.subscriptionRestaurant) {
          this.subscriptionRestaurant.unsubscribe();
        }
        if (this.subscriptionPools) {
          this.subscriptionPools.unsubscribe();
        }
      } else {
        this.subscribeRestaurant(this._aFs.doc(`restaurants/${res}`));
      }
    }
  }

  getRestaurantNames(): RestaurantName[] {
    return this.restaurantNames$.getValue();
  }

  getRestaurantNames$(): BehaviorSubject<RestaurantName[]> {
    return this.restaurantNames$;
  }

  public getRestaurant(id): Promise<Restaurant> {
    if (Utils.isEmpty(id)) {
      return Promise.resolve(null);
    }
    return this._aFs.firestore.collection('restaurants').doc(id).get().then(
      (snap) => {
        return new Restaurant(snap);
      }
    );
  }

  public getRestaurants(ref: Company): Promise<Restaurant[]> {
    return this._aFs.firestore.collection('restaurants').where('company', '==', ref.ref).get()
      .then((querySnapshot) => {
        return super.mapQuerySnapshot<Restaurant>(querySnapshot, Restaurant);
      });
  }

  public getRestaurants$(): Observable<Restaurant[]> {
    if (this._ac.hasAccess(AccessArea.sales)) {
      return super.mapSnapshotChanges$<Restaurant>(this._aFs.collection('restaurants'), Restaurant)
        .pipe(tap((restaurants: Restaurant[]) => restaurants.sort((a: any, b: any) => {
          const comp = b.customerId - a.customerId;
          return isNaN(comp) ? (a.customerId ? -1 : 1) : comp;
        })));
    }
    return throwError({ code: 'permission-denied', message: 'Missing or insufficient permissions.' });
  }

  public getGoodies(restaurant: Restaurant): Promise<Goodie[]> {
    // .orderBy('start', 'asc')
    return restaurant.goodies.get().then(querySnapshot => this.mapQuerySnapshot<Goodie>(querySnapshot, Goodie));
  }

  public setGoodie(restaurant: Restaurant, goodie: Goodie): Promise<void> {
    if (goodie.ref) {
      return goodie.update(goodie);
    } else {
      return restaurant.goodies.add(goodie.data()).then(ref => {
        return Promise.resolve();
      });
    }
  }

  public getGoodie(restaurant: Restaurant): Promise<Goodie> {
    if (!restaurant) {
      return Promise.resolve(null);
    }
    // orderBy('start', 'asc')
    return restaurant.goodies.get().then(querySnapshot =>
      this.mapQuerySnapshot<Goodie>(querySnapshot, Goodie)).then((goodies: Goodie[]) => {
        if (goodies && goodies.length > 0) {
          if (goodies.length > 1) {
            // delete other goodies, it must be only one goodie for the restaurant for now
            super.deleteDocuments(goodies.filter((v, i) => i > 0).map(g => g.ref)).catch(error => {
              console.error(error);
            });
          }
          return goodies[0];
        }
        return null;
      });
  }

  public addGoodie(restaurant: Restaurant, goodie: Goodie): Promise<void> {
    return restaurant.goodies.add(goodie.data()).then(ref => {
      return Promise.resolve();
    });
  }

  public addRestaurant(restaurant: Restaurant): Promise<void> {
    return this._aFs.firestore.collection('restaurants').add(restaurant.data()).then(docRef => {
      restaurant.ref = docRef;
      return Promise.resolve();
    });
  }

  public getRestaurantMenu(restaurant: Restaurant, params: { from: moment.Moment, to: moment.Moment }): Promise<IMenu[]> {
    if (!restaurant || Utils.isEmpty(restaurant.id)) {
      return Promise.resolve([]);
    }
    return this._us.user.firebaseUser.getIdToken().then(token => {
      const query = `type=calendar&from=${params.from.format('YYYY-MM-DD')}&to=${params.to.format('YYYY-MM-DD')}&auth=${token}`;
      const url = `${environment.menusApiURL}/menu/${restaurant.id}?${query}`;
      return this.http.get(url).toPromise().then((data: any) => {
        if (!data || !Array.isArray(data)) {
          return [];
        }
        return data;
      });
    });
  }

  public async getRestTimeTypes(category: RestTimeType_Category): Promise<RestTimeType[]> {
    return this._aFs.firestore.collection('rest_time_types').where('category', '==', category).get()
      .then((querySnapshot) => {
        return super.mapQuerySnapshot<RestTimeType>(querySnapshot, RestTimeType);
      });
  }

  public getRestaurantPrivateData(restaurant: Restaurant): Promise<RestaurantPrivateData> {
    return new Promise((resolve, reject) => {
      super.mapDocSnapshotChanges$<RestaurantPrivateData>(this._aFs.collection('restaurant_private_data').doc(restaurant.id), RestaurantPrivateData).pipe(take(1)).toPromise().then((docData) => {
        resolve(docData);
      });
    });
  }

  public updateRestauratPrivateData(restaurant: Restaurant, updateData) {
    this._aFs.collection('restaurant_private_data').doc(restaurant.id).set(updateData, { merge: true });
  }

  public async getSelfServiceUrl(restaurant: Restaurant) {
    return this._us.user.firebaseUser.getIdToken().then(token => {
      const url = `${environment.restaurantsApiURL}/getSelfServiceUrl/${restaurant.id}?auth=${token}`;

      return this.http.get(url).toPromise()
        .then((data: { selfServiceUrl: string }) => {
          return data.selfServiceUrl;
        })
        .catch((error) => {
          if (error.status === 422) {
            this._as.open(this._ts.instant('error_contract_id_not_found'), { severity: 'danger' });
          } else {
            this._as.open(this._ts.instant('error'), { severity: 'danger' });
          }

          return Promise.reject();
        });
    });
  }

  ngOnDestroy(): void {
    this.reset();
  }

  private async init(user: User) {
    await this._ac.waitForRoles();
    if (this._ac.hasAccess(AccessArea.sales) || this._us.user.isAdmin) {
      // @ts-ignore
      this.subscriptionRestaurantNames = super.mapSnapshotChanges$(this._aFs.collection('restaurant_names', ref => {
        return ref.orderBy('name', 'asc');
      }), RestaurantName).pipe(tap((names: RestaurantName[]) => names.sort((a, b) => Utils.compare(a.name, b.name))))
        .subscribe((names: RestaurantName[]) => {
          this.restaurantNames$.next(names);
        }, error => {
          this.restaurantNames$.next([]);
          console.warn(error);
        });
    } else {
      const restaurants = user.restaurants || {};
      const restaurantNames = [];
      for (const resName of Object.values(restaurants)) {
        const restaurantName = new RestaurantName();
        restaurantName.ref = this._aFs.firestore.collection('restaurant_names').doc(resName.restaurant.id);
        restaurantName.name = resName.name;
        restaurantName.formattedAddress = resName.formattedAddress;
        restaurantName.restaurant = resName.restaurant;
        restaurantNames.push(restaurantName);
      }
      restaurantNames.sort((a, b) => Utils.compare(a.name, b.name));
      this.restaurantNames$.next(restaurantNames);
    }
  }

  private subscribeRestaurant(restaurantDoc: AngularFirestoreDocument<Restaurant>) {
    if (this.subscriptionRestaurant) {
      this.subscriptionRestaurant.unsubscribe();
    }
    // TODO test the rxjs operators for changing restaurant and the pools
    this.subscriptionRestaurant = super.mapDocSnapshotChanges$<Restaurant>(restaurantDoc, Restaurant).pipe(
      // Emit given value first.  This is only for pairwise pipe to work properly.
      startWith(this.getSelectedRestaurant()),
      // Emit the previous and current values as an array.
      pairwise(),
      // Transparently perform actions or side-effects, in this case if only restaurant properties changed, emit next restaurant
      tap((restaurants: Restaurant[]) => {
        if (restaurants[0] && restaurants[1] && restaurants[0].id === restaurants[1].id) {
          // cache the offersAvailable value between restaurant changes.
          restaurants[1].offersAvailable = restaurants[0].offersAvailable;
          this.selectedRestaurant$.next(restaurants[1]);
        }
        return restaurants;
      }),
      tap((restaurants: Restaurant[]) => {
        // TODO sure that we want to have this here?, on every restaurant update?
        this.subscribeRestaurantPrivateData(restaurants[1]);
        return restaurants;
      }),
      // Emit values that pass the provided condition.
      filter((restaurants: Restaurant[]) => {
        const id1 = restaurants[0] ? restaurants[0].id : '';
        const id2 = restaurants[1] ? restaurants[1].id : '';
        return id1 !== id2;
      }),
      // Map to observable, complete previous inner observable, emit values
      switchMap((restaurants: Restaurant[]) => {
        return this.getPoolsObservable(restaurants[1]).pipe(map(pools => {
          return { pools: pools, restaurants: restaurants };
        }));
      })
    ).subscribe((results: { pools: Pool[], restaurants: Restaurant[] }) => {
      // this must be come first
      this.pools$.next(results.pools);
      const selectedRes = this.getSelectedRestaurant();
      const newRes = results.restaurants[1];
      // if only pools changed, don't emit the value in results.restaurants[1] again
      if (!selectedRes || !newRes || selectedRes.id !== newRes.id) {
        this.selectedRestaurant$.next(newRes);
        this.subscribeMealsOnline(newRes);
      }
    }, error => {
      // this.pools$.error(error);
      this.pools$.next([]);
      this.selectedRestaurant$.next(null);
      this.mealsOnline$.next(null);
      console.warn(error.toString());
    });
  }
  private getPoolsObservable(restaurant: Restaurant): Observable<Pool[]> {
    if (restaurant.company && restaurant.company.ref) {
      return super.mapDocSnapshotChanges$<Company>(this._aFs.doc(restaurant.company.ref.path), Company).pipe(
        switchMap((company: Company) => {
          if (company && company.addOns && company.addOns.poolFunction) {
            return super.mapSnapshotChanges$<Pool>(this._aFs.collection('pools', ref => {
              return ref.where('company', '==', restaurant.company.ref);
            }), Pool);
          }
          return of([]);
        })
      );
      /*
      return super.mapSnapshotChanges$<Pool>(this._aFs.collection('pools', ref => {
        return ref.where('company', '==', restaurant.company.ref);
      }), Pool); */
    } else {
      console.warn(`Restaurant "${restaurant.name}" has no company assigned!`);
      return of([]);
    }
  }

  private subscribeRestaurantPrivateData(restaurant: Restaurant) {
    if (this.subscriptionRestaurantPrivateData) {
      this.subscriptionRestaurantPrivateData.unsubscribe();
    }

    this.subscriptionRestaurantPrivateData = super.mapDocSnapshotChanges$<RestaurantPrivateData>(this._aFs.collection('restaurant_private_data')
      .doc<RestaurantPrivateData>(restaurant.id), RestaurantPrivateData)
      .subscribe((restaurantPrivateData: RestaurantPrivateData) => {
        this.restaurantPrivateData$.next(restaurantPrivateData);
      }, error => {
        this.restaurantPrivateData$.next(null);
        console.warn(error);
      });
  }

  /*
    TODO: check if this method can be deleted, since the restaurant progress is calculated in the back end
  */
  private subscribeMealsOnline(restaurant: Restaurant) {
    if (this.subscriptionMealsOnline) {
      this.subscriptionMealsOnline.unsubscribe();
    }
    const today = firebase.firestore.Timestamp.fromDate(moment().utc().startOf('day').toDate());
    this.subscriptionMealsOnline = this._aFs.collection<boolean>(restaurant.ref.collection('offers'),
      ref => ref.where('date', '>=', today).limit(1))
      .snapshotChanges().pipe(map(actions => actions.length > 0)).subscribe((mealsOnline: boolean) => {
        restaurant.offersAvailable = mealsOnline;
        this.mealsOnline$.next(mealsOnline);
      }, (error: any) => {
        this.mealsOnline$.next(null);
        console.error(error);
      });
  }
}
