import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
// add DateClicked Helper to providers if used in future. remember to add to index.ts for export
import {DateClickedDirective, DateRangeHelper, MultipleDatePickerComponent} from './index';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule
    ],
    declarations: [
        MultipleDatePickerComponent,
        DateClickedDirective
    ],
    providers: [
        DateRangeHelper
    ],
    exports: [MultipleDatePickerComponent]
})
export class MultipleDatePickerModule { }
