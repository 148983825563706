import { CellRendererActiveComponent } from './cell-renderer/cell-renderer-active/cell-renderer-active.component';
import { PrintTemplateDetailDialogComponent } from './admin/print-template-detail-dialog/print-template-detail-dialog';
import { CellRendererEditComponent } from './cell-renderer/cell-renderer-edit/cell-renderer-edit.component';
import { CellRendererAddComponent } from './cell-renderer/cell-renderer-add/cell-renderer-add.component';
import { ImageUploadModule } from 'angular2-image-upload';
import { MediaUploadDialogComponent } from './dialogs/media-upload-dialog/media-upload-dialog.component';
import { PaymentUploadDialogComponent } from './dialogs/payment-upload-dialog/payment-upload-dialog.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { AgmCoreModule } from '@agm/core';
import { PipeModule } from './partner/pipe/pipe.module';
import { MultipleDatePickerModule } from './multiple-date-picker/multiple-date-picker.module';
import { InputDialog } from './input-dialog/input-dialog.component';
import { TitleService } from './title-service/title.service';
import { ConfirmationDialog } from './confirmation-dialog/confirmation-dialog.component';
import { AuthGuard } from './auth.service';
import { routes } from './app.routes';
import { AgGridModule } from 'ag-grid-angular/main';
import { environment } from '../environments/environment';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { LoginComponent } from './login/login.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoodieDialogComponent } from './goodie-dialog/goodie-dialog.component';
import { TimeSpanDialogComponent } from './time-span-dialog/time-span-dialog.component';
import { WidgetService } from './services/widget.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorMessageService } from './services/error-message.service';
import { CellRendererDeleteComponent } from './cell-renderer/cell-renderer-delete/cell-renderer-delete.component';
import { CellRendererViewComponent } from './cell-renderer/cell-renderer-view/cell-renderer-view.component';
import { WidgetPreviewDialogComponent } from './widget-preview-dialog/widget-preview-dialog.component';
import { MediaNameEditDialogComponent } from './dialogs/media-name-edit-dialog/media-name-edit-dialog.component';
import { ValidatorService } from './services/validator.service';
import { SharedModule } from './shared.module';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CellRendererMoveUpComponent } from './cell-renderer/cell-renderer-move-up/cell-renderer-move-up.component';
import { CellRendererMoveDownComponent } from './cell-renderer/cell-renderer-move-down/cell-renderer-move-down.component';
import { LanguageManagementEditDialogComponent } from './admin/language-management-edit-dialog/language-management-edit-dialog.component';
// FIX for the message:  The timestampsInSnapshots setting now defaults to true and you no
//   longer need to explicitly set it. In a future release, the setting
//   will be removed entirely and so it is recommended that you remove it
//   from your firestore.settings() call now.
import { AngularFirestore, FirestoreSettingsToken } from '@angular/fire/firestore';
import defaultLocale from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { RestaurantService } from './services/restaurant.service';
import { MealService } from './services/meal.service';
import { MediaService } from './services/media.service';
import { LanguageService } from './services/language.service';
import { PartnerService } from './services/partner.service';
import { PrintService } from './services/print.service';
import { TagService } from './services/tag.service';
import { KpiService } from './services/kpi.service';
import { PrintTemplateService } from './services/print-template.service';
import { PropertyService } from './services/property.service';
import { UserService } from './services/user.service';
import { SearchService } from './services/search.service';
import { AlertService } from './services/alert.service';
import { WindowRef } from './services/helper.service';
import { ComponentsModule } from './components/components.module';
import { CustomLoadingGridOverlayComponent } from './loading-grid-overlay/loading.grid.overlay.component';
import { AccessService } from './services/access.service';
import {LnCmsElementsModule} from './ln-cms-elements/ln-cms-elements.module';
import { CompanyService } from './services/company.service';
import { DiscountService } from './services/discount.service';
import {TextEditDialogComponent} from './dialogs/text-edit-dialog/text-edit-dialog.component';
import {SpecialsCellRendererComponent} from './partner/cell-renderer/specials-cell-renderer/specials-cell-renderer.component';
import {RestaurantListCellRendererAddressComponent} from './partner/cell-renderer/restaurant-list-cell-renderer-address/restaurant-list-cell-renderer-address.component';
import {RestaurantListCellRendererCustomerIdComponent} from './partner/cell-renderer/restaurant-list-cell-renderer-customer-id/restaurant-list-cell-renderer-customer-id.component';
import {CellRendererImageComponent} from './partner/cell-renderer/cell-renderer-image/cell-renderer-image.component';
import {CellRendererFormatedDateComponent} from './partner/cell-renderer/cell-renderer-formated-date/cell-renderer-formated-date.component';
import {CellRendererMediaTypeComponent} from './partner/cell-renderer/cell-renderer-media-type/cell-renderer-media-type.component';
import {EmptyPagePlaceholderComponent} from './partner/empty-page-placeholder/empty-page-placeholder.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConfirmationDialog,
    InputDialog,
    TextEditDialogComponent,
    GoodieDialogComponent,
    TimeSpanDialogComponent,
    MediaUploadDialogComponent,
    PaymentUploadDialogComponent,
    CellRendererViewComponent,
    CellRendererAddComponent,
    CellRendererEditComponent,
    CellRendererDeleteComponent,
    CellRendererMoveUpComponent,
    CellRendererMoveDownComponent,
    WidgetPreviewDialogComponent,
    MediaNameEditDialogComponent,
    PrintTemplateDetailDialogComponent,
    CellRendererActiveComponent,
    LoadingDialogComponent,
    LanguageManagementEditDialogComponent,
    CustomLoadingGridOverlayComponent
  ],
  imports: [
    ImageUploadModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    ComponentsModule,
    LnCmsElementsModule,
    routes,
    PipeModule,
    MultipleDatePickerModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebase.apiKey,
      libraries: ['places']
    }),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    DpDatePickerModule
  ],
  entryComponents: [
    InputDialog,
    CustomLoadingGridOverlayComponent,
    ConfirmationDialog,
    GoodieDialogComponent,
    WidgetPreviewDialogComponent,
    TimeSpanDialogComponent,
    TextEditDialogComponent,
    MediaUploadDialogComponent,
    PaymentUploadDialogComponent,
    CellRendererViewComponent,
    CellRendererAddComponent,
    CellRendererActiveComponent,
    CellRendererEditComponent,
    CellRendererDeleteComponent,
    CellRendererMoveUpComponent,
    CellRendererMoveDownComponent,
    MediaNameEditDialogComponent,
    LoadingDialogComponent,
    PrintTemplateDetailDialogComponent,
    LanguageManagementEditDialogComponent
  ],
  providers: [
    AuthGuard,
    UserService,
    TitleService,
    RestaurantService,
    MediaService,
    MealService,
    LanguageService,
    PartnerService,
    PrintService,
    TagService,
    KpiService,
    PropertyService,
    PrintTemplateService,
    WidgetService,
    AngularFirestore,
    SearchService,
    ErrorMessageService,
    WindowRef,
    ValidatorService,
    AlertService,
    AccessService,
    CompanyService,
    DiscountService,
    // FIX for the message @firebase/firestore: Firestore (5.8.0). The timestampsInSnapshots setting now defaults ...
    { provide: FirestoreSettingsToken, useValue: {} },
    { provide: LOCALE_ID, useValue: environment.defaultLang }, // This is only for JIT compiling on dev
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor() {
    registerLocaleData(defaultLocale, environment.defaultLang);
  }

}
