import {TranslateService} from '@ngx-translate/core';
import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-cell-renderer-media-type',
  templateUrl: './cell-renderer-media-type.component.html',
  styleUrls: ['./cell-renderer-media-type.component.less']
})
export class CellRendererMediaTypeComponent implements AgRendererComponent {
  constructor(private _ts: TranslateService) {}

  type: string;
  icon: string;

  refresh(params: any): boolean {
    if (params && params.value) {
      this.parseType(params.value);
    }
    return true;
  }

  agInit(params: ICellRendererParams): void {
    if (params && params.value) {
      this.parseType(params.value);
    }
  }

  parseType(type) {
    switch (type) {
      case 'video':
        this.type = this._ts.instant('video');
        this.icon = 'videocam';
        break;
      case 'image':
        this.type = this._ts.instant('photo');
        this.icon = 'photo';
        break;
      default:
        this.type = this._ts.instant('unknown');
        this.icon = 'help_outline';
    }
  }

}
