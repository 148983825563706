import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Restaurant, User } from '../../models/model';
import { AccessRole } from '../../models/enums'
import { MatStepper } from '@angular/material';
import { PartnerChangePasswordFormComponent } from '../partner-change-password-form/partner-change-password-form.component';
import { AlertService } from '../../services/alert.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { PartnerRestaurantsDetailsFormComponent } from '../partner-restaurants-details-form/partner-restaurants-details-form.component';
import { PartnerRestaurantsOpeningHoursFormComponent } from '../partner-restaurants-opening-hours-form/partner-restaurants-opening-hours-form.component';
import { PartnerRestaurantsMediaComponent } from '../partner-restaurants-media/partner-restaurants-media.component';
import { UserService } from '../../services/user.service';
import { RestaurantService } from '../../services/restaurant.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-partner-guide-dialog',
  templateUrl: './partner-guide-dialog.component.html',
  styleUrls: ['./partner-guide-dialog.component.less']
})
export class PartnerGuideDialogComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('partnerChangePasswordForm') partnerChangePasswordForm: PartnerChangePasswordFormComponent;
  @ViewChild('partnerRestaurantsDetailsForm') partnerRestaurantsDetailsForm: PartnerRestaurantsDetailsFormComponent;
  @ViewChild('partnerRestaurantsOpeningHoursForm') partnerRestaurantsOpeningHoursForm: PartnerRestaurantsOpeningHoursFormComponent;
  @ViewChild('partnerRestaurantsMedia') partnerRestaurantsMedia: PartnerRestaurantsMediaComponent;
  
  isLoading = false;
  loadingMessage = '';
  isLinear = false;
  restaurant: Restaurant;
  user: User;
  showFireworks: boolean = false;
  notShowGuideForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PartnerGuideDialogComponent>,
    private _as: AlertService,
    private _us: UserService,
    private router: Router,
    private _rs: RestaurantService
  ) {
    this.user = this._us.user;    
    this._rs.getSelectedRestaurant$().subscribe(restaurant => {
      this.restaurant = restaurant;
      this.notShowGuideForm = new FormGroup({
        'notShowGuide': new FormControl(!!this.restaurant.notShowGuide)
      })
    });
  }

  ngOnInit() { }

  onClose(): void {
    if(this.notShowGuideForm.controls.notShowGuide.dirty) {
      this.setNotShowGuide(this.notShowGuideForm.get('notShowGuide').value);
    }
    this.dialogRef.close();
  }

  onStepChange(ev: StepperSelectionEvent) {
    /* Scroll to the top of the selected step on change */
    const stepId = this.stepper._getStepLabelId(ev.selectedIndex);
    const stepElement = document.getElementById(stepId);
    if (stepElement) {
      setTimeout(() => {
        stepElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }, 250);
    }

    if(ev.selectedIndex === this.stepper.steps.length - 1) {
      /* dont auto open the guide again after the user has been to the last step */
      if(!this.restaurant.notShowGuide
        && !this.notShowGuideForm.controls.notShowGuide.dirty
        && !this._us.user.hasRole(AccessRole.admin) 
        && !this._us.user.hasRole(AccessRole.salesman)
      ) {
        this.setNotShowGuide(true);
      }
      /* Fireworks gimmick */
      this.startFireworks();
    }
  }

  previousStep() {
    this.stepper.previous();
  }

  nextStep() {
    this.stepper.next();
  }

  async submitPassword() {
    try {
      await this.partnerChangePasswordForm.submit();
      this._as.open(AlertService.getMessageKey('ln/password-changed-succesfully'), {duration: 'slow', severity: 'success'});
      this.stepper.next();
    } catch (e) {
      console.error(e);
      this.partnerChangePasswordForm.showErrorMessage(e.code || e);
      this._as.open(AlertService.getErrorKey(e.code || e), {severity: 'danger', duration: 'slow'});
    } finally {
      this.isLoading = false;
    }
  }

  async submitDetails() {
    try{
      await this.partnerRestaurantsDetailsForm.submit();
      this._as.open('changes_saved');
      this.nextStep();
    } catch(e) {
      console.error(e);
      this._as.error(e);
    }
  }

  async submitOpeningHours() {
    try {
      await this.partnerRestaurantsOpeningHoursForm.submit();
      this._as.open('changes_saved');
      this.nextStep();
    } catch(e) {
      console.error(e);
      this._as.error(e);
    }
  }

  openMealDialog() {
    this.dialogRef.close();
    this.router.navigate(['/partner/meals'], {queryParams: {openDialog: true}}).catch(error => {
      console.error(error);
    });
  }

  setNotShowGuide(notShowGuide: boolean) {
    return this.restaurant.update({ notShowGuide: notShowGuide });
  }

  /* Fireworks gimmick */
  startFireworks() {
    this.showFireworks = true;
    setTimeout(() => {
      this.showFireworks = false;
    }, 3950);
  }

}
