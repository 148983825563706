import {AgRendererComponent} from 'ag-grid-angular';
import {Component} from '@angular/core';

@Component({
  selector: 'app-cell-renderer-add',
  templateUrl: './cell-renderer-add.component.html',
  styleUrls: ['./cell-renderer-add.component.less']
})

export class CellRendererAddComponent implements AgRendererComponent {

  constructor() { }

  agInit() {

  }

  refresh() {
    return true;
  }

}
