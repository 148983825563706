import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { Restaurant, WidgetSetting, WidgetSettingStyleCss, WidgetSettingStyle } from '../models/model';
import { WidgetType } from './../models/enums';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { firestore } from 'firebase';

@Injectable()
export class WidgetService extends HelperService {
  constructor(_aFs: AngularFirestore) {
    super(_aFs);
  }

  public getWidgetForRestaurant$(restaurant: Restaurant, type: WidgetType): Observable<WidgetSetting[]> {
    const collection = this._aFs.collection<WidgetSetting>(restaurant.widgetSettings);
    return collection.snapshotChanges().pipe(map(actions => {
      let widgets = actions.map(event => {
        const widget = new WidgetSetting(event.payload.doc);
        // in database are some empty widget. This causes exceptions in client. In such cases set defaults values

        if (!widget.style) {
          this.setWidgetDefaults(widget);
        }

        return widget;
      });

      if (type) {
        widgets = widgets.filter((widget) => widget.type === type);
      }

      if (!widgets.length) {
        const newWidget = new WidgetSetting();
        newWidget.type = type;
        this.setWidgetDefaults(newWidget);

        return [newWidget];
      }

      return widgets;
    }));
  }
  public updateDateModified(restaurant: Restaurant): Promise<void> {
    if (restaurant.details && restaurant.details.widgetEnabled) {
      return restaurant.widgetSettings.get().then(querySnapshot => {
        const updates = [];
        const now = firestore.Timestamp.now();
        querySnapshot.forEach(docSnap => {
          updates.push({ ref: docSnap.ref, update: { dateModified: now } });
        });
        return super.updateDocuments(updates);
      });
    }
    return Promise.resolve();
  }
  public setWidgetCss(widget: WidgetSetting): void {
    const widgetCss = new WidgetSettingStyleCss();
    widgetCss.base = `.ln-widget{}.row{}`;
    widgetCss.heading = `.ln-widget-heading{}.restaurant-logo{}.ln-header-text{}.datespan{}`;
    widgetCss.menu = `.ln-menu-header-text{}.ln-menu-wrapper{}.ln-weekday{}.ln-menu-table{}.ln-meal-name{}.ln-meal-description{}.ln-meal-price{}.ln-meal-price-currency{}`;
    widgetCss.footer = `.ln-footer{}.ln-footer-text{}`;
    widgetCss.custom = ``;
    widget.style.css = widgetCss;
  }

  public setWidgetDefaults(widget: WidgetSetting): void {
    widget.style = new WidgetSettingStyle();
    widget.style.layout = 'classic';
    widget.style.fontSizeItem = 24;
    widget.style.fontSizeItemDescription = 18;
    widget.style.fontSizeItemPrice = 20;
    widget.style.fontSizeDate = 20;
    widget.style.fontSizeTextHeader = 56;
    widget.style.fontSizeTextMenuHeader = 32;
    widget.style.fontSizeTextFooter = 32;
    widget.style.useCustomFont = true;
    widget.style.fontFamily = 'Roboto';
    widget.style.customFontUrl = 'https://fonts.googleapis.com/css?family=Roboto';
    widget.style.customFontFamily = 'Roboto';
    widget.style.restaurantLogo = '200';
    widget.textHeader = 'MITTAGSTISCH';
    widget.style.showPrice = true;
    widget.style.showCurrency = true;
    widget.style.showWeekday = true;
    widget.style.showPublicHoliday = false;
    widget.style.bgColor = '#FFFFFF';
    widget.style.textColor = '#000000';
    widget.period = 'week';
    widget.dateSpanFormat = 'D. MMMM YYYY';
    widget.dateFormat = 'dddd, D. MMMM YYYY';
    this.setWidgetCss(widget);
  }
}
