import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { LnException } from '../models/model';
const ANIMATION_DURATION_SLOW = 6000;
const ANIMATION_DURATION_NORMAL = 3000;
const ANIMATION_DURATION_FAST = 1500;
@Injectable()
export class AlertService {
  constructor(
    private _ts: TranslateService,
    private snackBar: MatSnackBar) {
  }
  public static getDurationInMillis(d: string) {
    switch (d) {
      case 'fast':
        return ANIMATION_DURATION_FAST;
      case 'slow':
        return ANIMATION_DURATION_SLOW;
    }
    return ANIMATION_DURATION_NORMAL;
  }
  public static getErrorKey(code: string): string {
    return AlertService.getMessageKey(code, 'error_occurred_try_later');
  }
  public static getMessageKey(code: string, defaultKey?: string): string {
    switch (code) {
      case 'ln/password-changed-succesfully':
        return 'password_changed_succesfully';
      case 'ln/email-changed-succesfully':
        return 'email_changed_succesfully';
      case 'auth/email-already-exists':
        return 'email_already_exists';
      case 'auth/user-disabled':
        return 'auth_user_disabled';
      case 'permission-denied':
        return 'permission_denied';
      case 'auth/weak-password':
        return 'password_weak';
      case 'auth/invalid-email':
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return 'invalid_email_or_password';
      case 'auth/too-many-requests':
        return 'too_many_requests_try_later';
      default:
        // print not recognized keys, add code to this method if necessary. remove this warning if not needed.
        console.warn('Not recognized message key. Code = ' + code);
        return defaultKey;
    }
  }
  public open(msg: string | Array<string>, params?: {
    i18nParams?: Object,
    action?: string,
    panelClass?: string,
    duration?: 'slow' | 'fast' | 'normal',
    severity?: string
  }) {
    const config: any = {
      duration: AlertService.getDurationInMillis(params ? params.duration : 'normal')
    };
    if (params && params.severity === 'danger') {
      config.panelClass = 'danger';
    } else if (params && params.severity === 'success') {
      config.panelClass = 'success';
    } else if (params && params.panelClass) {
      config.panelClass = params.panelClass;
    }
    const action = params && params.action ? params.action : null;
    this.snackBar.open(this._ts.instant(msg, params ? params.i18nParams : undefined), action, config);
  }
  public error(err: any) {
    this.open(AlertService.getErrorKey(LnException.code(err)), { severity: 'danger' });
  }
}
