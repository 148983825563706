import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';

@Component({
  selector: 'app-cell-renderer-move-down',
  templateUrl: './cell-renderer-move-down.component.html',
  styleUrls: ['./cell-renderer-move-down.component.less']
})

export class CellRendererMoveDownComponent implements AgRendererComponent {

  constructor() { }

  agInit() {

  }

  refresh() {
    return true;
  }

}
