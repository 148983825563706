import {AgRendererComponent} from 'ag-grid-angular';
import {Component} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';

@Component({
  selector: 'app-cell-renderer-active',
  templateUrl: './cell-renderer-active.component.html',
  styleUrls: ['./cell-renderer-active.component.less']
})
export class CellRendererActiveComponent implements AgRendererComponent {

  isActive = false;

  constructor() { }

  agInit(params: ICellRendererParams) {
    this.isActive = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.isActive = params.value;
    return true;
  }

}
