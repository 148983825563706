import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from '@angular/fire/firestore';
import { firestore } from 'firebase';
import {
  AccessRole,
  LnCmsAreas,
  LnCmsElementTypes,
  Medium_Type,
  TasteTagType,
  CoopertionType,
  WidgetType,
  RestTimeType_Category, DiscountType, VoucherType
} from './enums';
import { Utils } from '../app.utils';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * this class represents a firestore reference property
 */
export class LunchNowReference<T extends LunchNowDocument<T>> {
  public ref: DocumentReference;

  /**
   * @param ref firestore DocumentReference
   * @param clazz a class T
   */
  constructor(ref: DocumentReference, private clazz?: new (data: any) => T) {
    this.ref = ref;
  }

  /**
   * get a LunchNowDocument from a DocumentReference
   * @returns Promise<T>
   */
  get(): Promise<T> {
    if (this.ref) {
      return this.ref
        .get()
        .then((snap) => (snap.exists ? this.getNew(snap) : null));
    }
    return Promise.resolve(null);
  }

  /**
   * create new object from snapshot or return the row data if clazz is undefined
   * @param snap document snapshot
   */
  private getNew(snap): T {
    if (this.clazz) {
      return new this.clazz(snap);
    }
    return snap ? snap.data() : null;
  }

  /**
   * @returns id of document
   */
  public get id() {
    return this.ref ? this.ref.id : null;
  }

  /**
   * returns object, that can be save in the database
   */
  public data(nullValues?: boolean): any {
    return this.ref;
  }
}

/**
 * this is a base class for all objects saved in firesotre database,
 * e.g. document references, array, maps and documents
 */
export class LunchNowObject<T> {
  public static RESERVED_PROPERTIES = ['configuration', 'ref', 'id'];
  /**
   *
   * @param configuration for this object
   * @param data dirty data
   */
  constructor(protected configuration: LunchNowModelConfiguration, data: any) {
    this.parse(data);
  }
  /**
   * currently the parse method merge the new data with the old one, flat merging
   * @param data  - dirty data to parse
   */

  public parse(data: any): void {
    /* TODO the method doesnt work with default data initialized in constructor, e.g. Meal -> tags = [];
       /Answer from Maciej Apelgrim/
       The method parse only the data. The issue is with default data initialized in constructor.
       An update (without defined tags in the data) will override the tags with []. E.g. ref.update(Meals.parse(data).updateData()).
       One solution can be to adjust every static parse method, like that:
         public static parse(data): Meal {
            const newObj = new Meal();
            newObj.parse(data);
            delete newObj.tags
            return newObj;
         }
     */
    if (!data) {
      return;
    }

    if (typeof data !== 'object') {
      throw new LnException(
        `Cannot parse the data. Object is required but got ${typeof data}`,
        LnException.ERROR_CODE_OBJECT_REQUIRED
      );
    }

    this.parseProperties(this, data);
    this.parseArray(this, data);
    this.parseObjects(this, data);
  }

  protected parseProperties(context, data: any): void {
    if (this.configuration.properties) {
      for (const prop of this.configuration.properties) {
        if (
          data[prop] !== undefined &&
          !LunchNowObject.RESERVED_PROPERTIES.includes(prop)
        ) {
          context[prop] = data[prop];
        }
      }
    }
  }

  protected parseArray(context, data: any): void {
    if (this.configuration.arrays) {
      for (const object of this.configuration.arrays) {
        if (data[object.name]) {
          context[object.name] = [];
          for (const obj of data[object.name]) {
            context[object.name].push(
              this.createObject(object.name, obj, object.clazz, object.type)
            );
          }
        } else if (data[object.name] === null) {
          context[object.name] = [];
        }
      }
    }
  }

  protected setConfiguration(configuration: LunchNowModelConfiguration): void {
    this.configuration = configuration;
  }

  protected parseObjects(context, data: any): void {
    if (this.configuration.objects) {
      for (const object of this.configuration.objects) {
        if (data[object.name]) {
          context[object.name] = this.createObject(
            object.name,
            data[object.name],
            object.clazz,
            object.type
          );
        } else if (data[object.name] === null) {
          context[object.name] = null;
        }
      }
    }
  }

  protected createObject(prop: string, data, clazz?, type?) {
    if (!clazz) {
      return data;
    }
    if (clazz === LunchNowReference) {
      if (!type) {
        throw new LnException(
          'Cannot create LunchNowReference object without a type class.',
          LnException.ERROR_CODE_OBJECT_NOT_ALLOWED
        );
      }
      return new LunchNowReference(data, type);
    }
    return new clazz(data);
  }
  /**
   * data() method return valid object to be persist in the database
   * e.g.
   * const goodie = Goodie({text: 'text', start: null, end: null, dummy: 'dummy'});
   * goodie.data() returns {text: 'text'}, good for adding the data. e.g. collection.add(goodie.data())
   * goodie.data(true) returns {text: 'text', start: null, end: null}, see  updateData()
   */
  public data(nullValues?: boolean): any {
    const data: any = {};
    if (this.configuration.properties) {
      for (const prop of this.configuration.properties) {
        if (this[prop] !== undefined && (this[prop] !== null || nullValues)) {
          data[prop] = this[prop];
        }
      }
    }
    if (this.configuration.arrays) {
      for (const array of this.configuration.arrays) {
        if (this[array.name]) {
          data[array.name] = [];
          for (const item of this[array.name]) {
            // if a object hast data method use this method, e.g. in the array can be only primitive datatypes
            data[array.name].push(item.data ? item.data(nullValues) : item);
          }
        }
      }
    }
    if (this.configuration.objects) {
      for (const object of this.configuration.objects) {
        if (this[object.name] !== undefined) {
          if (this[object.name] === null && nullValues) {
            data[object.name] = null;
          } else if (this[object.name] !== null) {
            data[object.name] = this[object.name].data
              ? this[object.name].data(nullValues)
              : this[object.name];
          }
        }
      }
    }
    return data;
  }

  /**
   * use this method to get data for update. The method replace null values with firestore.FieldValue.delete()
   * e.g
   * const widgetSetting: WidgetSetting = {
   *      style:{
   *          bgColor: null,
   *          css: {
   *              base: null,
   *              goodie: "goodie"
   *          }
   *      },
   *      textHeader: null
   * }
   * returns
   * {
   *      style:{
   *          css: {
   *              goodie: "goodie"
   *          }
   *      },
   *      textHeader: DeleteFieldValueImpl {_methodName: "FieldValue.delete"}
   * }
   *
   *
   */
  public updateData(): any {
    return this._updateData(this.data(true), this instanceof LunchNowDocument);
  }

  // don't allow to call _updateData() from outside with dirty data
  private _updateData(data: any, isDoc: boolean): any {
    for (const prop of Object.keys(data)) {
      if (data[prop] === null) {
        if (isDoc) {
          data[prop] = firestore.FieldValue.delete();
        } else {
          // fix for object inside document to avaid exception
          // firestore.FieldValue.delete() must be set on the top of document
          delete data[prop];
        }
      } else if (Array.isArray(data[prop])) {
        data[prop] = data[prop].filter((el) => {
          return el !== null && el !== undefined;
        });
        for (const element of data[prop]) {
          if (
            typeof data[prop] === 'object' &&
            !LunchNowDocument.isDocumentReference(element)
          ) {
            this._updateData(element, false);
          }
        }
      } else if (
        typeof data[prop] === 'object' &&
        !LunchNowDocument.isDocumentReference(data[prop])
      ) {
        this._updateData(data[prop], false);
      }
    }
    return data;
  }
}
/**
 * This class represents a firestore document in the database
 */
export class LunchNowDocument<T> extends LunchNowObject<T> {
  public ref?: DocumentReference;
  constructor(
    protected configuration: LunchNowModelConfiguration,
    snap?:
      | DocumentSnapshot<T>
      | QueryDocumentSnapshot<T>
      | firestore.DocumentSnapshot
  ) {
    super(configuration, snap ? snap.data() : null);
    if (snap) {
      this.ref = snap.ref;
      this.init();
    }
  }
  public static isDocumentReference(el: any): boolean {
    if (!el) {
      return false;
    }
    if (el.constructor && el.constructor.name === 'DocumentReference') {
      return true;
    }
    // FIX for ng serve --aot --optimization
    return el.firestore && el.id && el.parent !== undefined && el.path;
  }
  private init(): void {
    // only firesotre documents can have collections
    if (this.configuration.collections && this.ref) {
      for (const collection of this.configuration.collections) {
        this[collection.name] = this.ref.collection(collection.name);
      }
    }
  }
  /**
   * update method, e.g.
   *
   * const widgetSetting = new WidgetSetting(snapshot);
   * widgetSetting.style.bgColor = '#ff0000';
   * widgetSetting.update();
   *
   * or
   *
   * const widgetSetting = new WidgetSetting(snapshot);
   * widgetSetting.parse({
   *     style: {
   *         bgColor: '#ff0000'
   *     }
   * });
   * widgetSetting.update();
   *
   * or
   *
   * const widgetSetting = new WidgetSetting(snapshot);
   * widgetSetting.update(WidgetSetting.parse({style: {bgColor: '#ff0000'}}));
   * widgetSetting.update({style: {bgColor: '#ff0000'}}); you can use dirty data directly in the method as well
   * here if update fails, widgetSetting object is not affected ! It keeps his old data.
   * Recommended way to update the object.
   */
  public update(data?: any): Promise<void> {
    if (this.ref) {
      let updateData = {};
      if (!data) {
        updateData = super.updateData();
      } else {
        const newDoc: LunchNowDocument<T> = new LunchNowDocument<T>(
          this.configuration
        );

        newDoc.parse(data);
        updateData = newDoc.updateData();
      }
      return this.ref.update(updateData);
    }
    return Promise.reject('Cannot update! this.ref object is NULL');
  }

  public delete(): Promise<boolean> {
    if (this.ref) {
      return this.ref.delete().then(() => true);
    }
    return Promise.resolve(false);
  }

  /**
   * refresh the object with the current data from database. e.g. if the object have been updated with
   * this.update(editedDoc) and the object isn't Observable in your component. Refresh method use parse function, so
   * the new data will be merged into current object. This method makes https call.
   */
  public refresh(): Promise<void> {
    if (!this.id) {
      return Promise.reject(
        'Cannot refresh the document! ID is NULL or undefined'
      );
    }
    return this.ref.get().then((documentSnapshot) => {
      super.parse(documentSnapshot.data());
      return Promise.resolve();
    });
  }

  public get id(): string {
    return this.ref ? this.ref.id : null;
  }
}

export class Cuisine extends LunchNowDocument<Cuisine> {
  name: string;

  constructor(data?: any) {
    super(LnConfiguration.cuisine, data);
  }

  public static parse(data): Cuisine {
    const newObj = new Cuisine();

    newObj.parse(data);
    return newObj;
  }
}

export class Address extends LunchNowObject<Address> {
  country: string;
  locality: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  formattedAddress?: string;
  placeId?: string;
  plusCode?: string;
  state?: string;
  stateCode?: string;

  constructor(data?: any) {
    super(LnConfiguration.address, data);
  }

  public static parse(data): Address {
    return new Address(data);
  }
  /*
        This is a helper method, which will try to return always a pretty
        formatted address string. Use this instead of the variable, to avoid empty
        address string fields.
    */
  public getFormatedAddress(): string {
    if (this.formattedAddress) {
      return this.formattedAddress;
    }

    let string = `${this.street || ''} ${this.streetNumber || ''}, ${this.postalCode || ''
      } ${this.locality || ''}, ${this.country || ''}`;

    string = string.trim();
    if (string.startsWith(',')) {
      string = string.substr(1, string.length - 1);
    }
    if (string.endsWith(',')) {
      string = string.substr(0, string.length - 1);
    }
    return string.trim();
  }
}

export class Contact extends LunchNowObject<Contact> {
  email?: string;
  phoneNumber?: string;
  web?: string;
  faxNumber?: string;
  constructor(data: any) {
    super(LnConfiguration.contact, data);
  }
  public static parse(data): Contact {
    return new Contact(data);
  }
}

export class Detail extends LunchNowObject<Detail> {
  handicap?: boolean;
  parkingExternal?: boolean;
  parkingRestaurant?: boolean;
  paymentAE?: boolean;
  paymentCA?: boolean;
  paymentDC?: boolean;
  paymentEC?: boolean;
  paymentMC?: boolean;
  paymentVS?: boolean;
  seatsInside?: number;
  seatsOutside?: number;
  smokingOutside?: boolean;
  sodexo?: boolean;
  ticketRestaurant?: boolean;
  wifi?: boolean;
  smokingInside?: boolean;
  deliveryEnabled?: boolean;
  takeawayEnabled?: boolean;
  reservationEnabled?: boolean;
  widgetEnabled?: boolean;
  bookatableId?: string;
  paypal: boolean;

  constructor(data: any) {
    super(LnConfiguration.detail, data);
  }
  public static parse(data): Detail {
    return new Detail(data);
  }
}



export class MangoPayData extends LunchNowObject<MangoPayData> {
  creditedWalletId?: string;
  userId?: string;

  constructor(data: any) {
    super(LnConfiguration.mangoPayData, data);
  }
  public static parse(data): MangoPayData {
    return new MangoPayData(data);
  }
}

export class AddOns extends LunchNowObject<AddOns> {
  widgetEnabled?: boolean;
  facebookConnectEnabled?: boolean;
  printSettings?: boolean;
  poolFunction?: boolean;
  constructor(data: any) {
    super(LnConfiguration.addOns, data);
  }
  public static parse(data): AddOns {
    return new AddOns(data);
  }
}

export class TimesTimeSpan extends LunchNowObject<TimesTimeSpan> {
  end?: string;
  start?: string;
  constructor(data?: any) {
    super(LnConfiguration.timesTimeSpan, data);
  }
  public static parse(data): TimesTimeSpan {
    return new TimesTimeSpan(data);
  }
}

export class Times extends LunchNowObject<Times> {
  1: TimesTimeSpan[];
  2: TimesTimeSpan[];
  3: TimesTimeSpan[];
  4: TimesTimeSpan[];
  5: TimesTimeSpan[];
  6: TimesTimeSpan[];
  7: TimesTimeSpan[];

  constructor(data?: any) {
    super(LnConfiguration.times, data);
  }
  public static parse(data): Times {
    return new Times(data);
  }
}

export class Location extends LunchNowObject<Location> {
  geohash?: string;
  geopoint?: firestore.GeoPoint;
  constructor(data: any) {
    super(LnConfiguration.location, data);
  }
  public static parse(data): Location {
    return new Location(data);
  }
}

export class Medium extends LunchNowObject<Medium> {
  dateCreated?: firestore.Timestamp;
  type?: Medium_Type;
  url?: string;
  storageUrl?: string;
  storageRef?: string;
  youtubeId?: string;
  name?: string;
  constructor(data?: any) {
    super(LnConfiguration.medium, data);
  }
  public static parse(data): Medium {
    return new Medium(data);
  }
}

export class WidgetSettingStyleCss extends LunchNowObject<
  WidgetSettingStyleCss
  > {
  base?: string;
  footer?: string;
  heading?: string;
  menu?: string;
  custom?: string;

  // system?: string;
  constructor(data?: any) {
    super(LnConfiguration.widgetSettingStyleCss, data);
  }
  public static parse(data): WidgetSettingStyleCss {
    return new WidgetSettingStyleCss(data);
  }
}

export class Goodie extends LunchNowDocument<Goodie> {
  text: string;
  code: string;
  start: firestore.Timestamp;
  end?: firestore.Timestamp;
  value: number;
  from: firestore.Timestamp;
  to: firestore.Timestamp;
  weekdays: number[];
  usedCount: number;
  constructor(data?: any) {
    super(LnConfiguration.goodie, data);
  }

  public static parse(data): Goodie {
    const newObj = new Goodie();

    newObj.parse(data);
    return newObj;
  }
  public get startDate(): Date {
    if (this.start) {
      return this.start.toDate();
    }
    return null;
  }
  public get endDate(): Date {
    if (this.end) {
      return this.end.toDate();
    }
    return null;
  }
  public get fromDate(): Date {
    if (this.from) {
      return this.from.toDate();
    }
    return null;
  }
  public get toDate(): Date {
    if (this.to) {
      return this.to.toDate();
    }
    return null;
  }
  public get isActive(): boolean {
    const now = new Date();

    return (
      this.startDate &&
      this.startDate <= now &&
      (!this.endDate || this.endDate >= now)
    );
  }
}

export class WidgetSettingStyle extends LunchNowObject<WidgetSettingStyle> {
  bgColor?: string;
  css?: WidgetSettingStyleCss;
  fontFamily?: string;
  fontSizeDate?: number;
  fontSizeItem?: number;
  fontSizeItemDescription?: number;
  fontSizeItemPrice?: number;
  fontSizeTextFooter?: number;
  fontSizeTextHeader?: number;
  fontSizeTextMenuHeader?: number;
  layout?: string;
  restaurantLogo?: string;
  showCurrency?: boolean;
  showDateSpan?: boolean;
  showPrice?: boolean;
  textColor?: string;
  showWeekday?: boolean;
  showPublicHoliday?: boolean;
  customFontFamily?: string;
  customFontUrl?: string;
  useCustomFont?: boolean;
  bgColorTransparent?: string;
  showDaysWithoutItems?: boolean;

  constructor(data?: any) {
    super(LnConfiguration.widgetSettingStyle, data);
  }

  public static parse(data): WidgetSettingStyle {
    return new WidgetSettingStyle(data);
  }
}

export class WidgetSettingPriceFormat extends LunchNowObject<
  WidgetSettingPriceFormat
  > {
  separator?: string;
  precision?: number;

  constructor(data?: any) {
    super(LnConfiguration.widgetSettingPriceFormat, data);
  }

  public static parse(data): WidgetSettingPriceFormat {
    return new WidgetSettingPriceFormat(data);
  }
}

export class WidgetSetting extends LunchNowDocument<WidgetSetting> {
  dateModified?: firestore.Timestamp;
  dateFormat?: string;
  dateSpanFormat?: string;
  period?: string;
  style?: WidgetSettingStyle;
  priceFormat?: WidgetSettingPriceFormat;
  textHeader?: string;
  textFooter?: string;
  useCustomCss?: boolean;
  textMenuHeader?: string;
  useCustomFont?: string;
  periodWeeks?: number;
  dateRendered?: firestore.Timestamp;
  type?: WidgetType;

  constructor(
    documentSnapshot?:
      | DocumentSnapshot<WidgetSetting>
      | QueryDocumentSnapshot<WidgetSetting>
  ) {
    super(LnConfiguration.widgetSetting, documentSnapshot);
  }

  public static parse(data): WidgetSetting {
    const newObj = new WidgetSetting();

    newObj.parse(data);
    return newObj;
  }
}

export interface PriceFormat {
  separator: string;
  precision: number;
  example?: string;
}

export const PriceFormats = {
  CommaSingle: { separator: ',', precision: 1, example: '9,9' } as PriceFormat,
  CommaDouble: { separator: ',', precision: 2, example: '9,90' } as PriceFormat,
  NoDecimal: { separator: '', precision: 0, example: '9' } as PriceFormat,
  DotSingle: { separator: '.', precision: 1, example: '9.9' } as PriceFormat,
  DotDouble: { separator: '.', precision: 2, example: '9.90' } as PriceFormat,
};

export class RestaurantName extends LunchNowDocument<RestaurantName> {
  name: string;
  formattedAddress?: string;
  restaurant: LunchNowReference<Restaurant>;
  constructor(
    documentSnapshot?:
      | DocumentSnapshot<RestaurantName>
      | QueryDocumentSnapshot<RestaurantName>
  ) {
    super(LnConfiguration.restaurantName, documentSnapshot);
  }
  public static parse(data): RestaurantName {
    const newObj = new RestaurantName();
    newObj.parse(data);
    return newObj;
  }
}

export class Role extends LunchNowDocument<Role> {
  [access: string]: any;
  constructor(
    documentSnapshot?: DocumentSnapshot<Role> | QueryDocumentSnapshot<Role>
  ) {
    super(LnConfiguration.role, documentSnapshot);
  }
  public static parse(data): Role {
    const newObj = new Role();
    newObj.parse(data);
    return newObj;
  }
  public hasAccess(area: string): boolean {
    return !!this[area];
  }
  public canEdit(area: string): boolean {
    return this[area] === 'edit';
  }
}

export class User extends LunchNowDocument<User> {
  email?: string;
  firstName?: string;
  lastName?: string;
  restaurants?: {
    [id: string]: {
      formattedAddress: string;
      name: string;
      restaurant: LunchNowReference<Restaurant>;
      roles: string[];
      sendKpiReport?: boolean;
      settings?: {
        dontAskIfNoMeals?: boolean;
      };
    };
  };
  telephone?: string;
  lastLogin?: firestore.Timestamp;
  readonly role?: string;
  language?: string;
  firebaseUser?: any;
  sendKpiReport?: boolean;
  constructor(documentSnapshot?: any) {
    super(LnConfiguration.user, documentSnapshot);
  }
  public static parse(data): User {
    const newObj = new User();
    newObj.parse(data);
    return newObj;
  }
  public static displayName(user: any): string {
    if (!user) {
      return '';
    }
    const firstName = user.firstName || '';
    const lastName = user.lastName || '';
    const displayName = firstName + ' ' + lastName;
    if (!Utils.isEmpty(displayName)) {
      return displayName.trim();
    }
    return user.email;
  }
  public data(nullValues?: boolean): any {
    const _data = super.data(nullValues);
    if (_data.restaurants) {
      Object.values(_data.restaurants).forEach((obj: any) => {
        if (obj.restaurant && obj.restaurant.ref) {
          obj.restaurant = obj.restaurant.ref;
        }
      });
    }
    return _data;
  }
  // Override the method form LunchNowObject, because creation of restaurants differs from standard creation
  protected createObject(prop: string, data, clazz?, type?) {
    if (prop === 'restaurants') {
      const restaurants: any = {};
      if (data) {
        for (const id of Object.keys(data)) {
          const res = data[id];
          if (res && res.name && res.restaurant && res.roles) {
            restaurants[id] = {
              name: res.name,
              formattedAddress: res.formattedAddress,
              restaurant: new LunchNowReference(res.restaurant, Restaurant),
              roles: res.roles,
            };
            if (res.sendKpiReport !== undefined) {
              restaurants[id].sendKpiReport = res.sendKpiReport;
            }
            if (res.settings !== undefined) {
              restaurants[id].settings = res.settings;
            }
          }
        }
      }
      return restaurants;
    }
    return super.createObject(prop, data, clazz, type);
  }
  public get isAdmin(): boolean {
    return this.hasRole(AccessRole.admin);
  }
  public get isSalesman(): boolean {
    return this.hasRole(AccessRole.salesman);
  }
  public get displayName(): string {
    return User.displayName(this);
  }
  public hasRole(role: string): boolean {
    return this.role === role;
  }
  public isPartner(restaurant: Restaurant | string): boolean {
    if (!this.restaurants || !restaurant) {
      return false;
    }
    let res;
    if (restaurant instanceof Restaurant) {
      res = this.restaurants[restaurant.id];
    } else {
      res = this.restaurants[restaurant];
    }
    return res && res.roles.includes(AccessRole.partner);
  }
}

export class Language extends LunchNowDocument<Language> implements ILanguage {
  alpha2: string;
  displayName: string;
  flag: string;
  constructor(documentSnapshot?: any) {
    super(LnConfiguration.language, documentSnapshot);
  }
  public static parse(data): Language {
    const newObj = new Language();
    newObj.parse(data);
    return newObj;
  }
}

export class RestTime extends LunchNowObject<RestTime> {
  reference: LunchNowReference<RestTimeType>;
  start: firestore.Timestamp;
  end: firestore.Timestamp;

  constructor(data: any) {
    super(LnConfiguration.restTime, data);
  }

  public static parse(data): RestTime {
    return new RestTime(data);
  }

  public get startDate() {
    if (this.start) {
      return this.start.toDate();
    }

    return null;
  }

  public get endDate() {
    if (this.end) {
      return this.end.toDate();
    }

    return null;
  }
}

export interface SharingURL {
  [key: string]: string;
}

export class Restaurant extends LunchNowDocument<Restaurant> {
  company: LunchNowReference<Company>;
  address: Address;
  contact: Contact;
  details: Detail;
  mangoPayData: MangoPayData;
  location?: any;
  logo?: Medium;
  media: Medium[];
  description?: string;
  name: string;
  routingName: string;
  contractId: string;
  type: CoopertionType;
  ratingNumber: number;
  cuisines?: LunchNowReference<Cuisine>[];
  openingHours?: Times;
  lunchTimes?: Times;
  menuFiles?: Medium[];
  tags?: LunchNowReference<TasteTag>[];
  restTime?: RestTime;
  holidayTime?: LunchNowReference<RestTimeType>;
  sharingURL: SharingURL;
  notShowGuide?: boolean;
  // Collections
  widgetSettings?: CollectionReference;
  meals: CollectionReference;
  printSettings?: CollectionReference;
  goodies?: CollectionReference;
  offers?: CollectionReference;
  offersAvailable: boolean;
  premiumStart?: firestore.Timestamp;
  premiumEnd?: firestore.Timestamp;
  profileProgress?: number;
  active?: boolean;
  kycDocument: boolean;
  orderForerunMinutes:number;
  maximumVoucherPrice: number;
  voucherPercentage:number;
  onlyTakeAway?: boolean;

  constructor(documentSnapshot?: any) {
    super(LnConfiguration.restaurant, documentSnapshot);
    if (!this.media) {
      this.media = [];
    }
    if (!this.menuFiles) {
      this.menuFiles = [];
    }
    if (!this.tags) {
      this.tags = [];
    }
    if (!this.contact) {
      this.contact = new Contact({});
    }
    if (!this.type) {
      this.type = CoopertionType.Restaurant;
    }
  }
  public static parse(data): Restaurant {
    const newObj = new Restaurant();
    newObj.parse(data);
    return newObj;
  }
  public getLogoUrl(): string {
    if (!this.logo) {
      return null;
    }
    return this.logo.url;
  }
  public get premiumStartDate(): Date {
    if (this.premiumStart) {
      return this.premiumStart.toDate();
    }
    return null;
  }
  public get premiumEndDate(): Date {
    if (this.premiumEnd) {
      return this.premiumEnd.toDate();
    }
    return null;
  }
}

export class Company extends LunchNowDocument<Company> {
  name: string;
  customerId: number;
  commissionRate: number;
  admin?: any;
  restaurants: LunchNowReference<Restaurant>[];
  addOns: AddOns;
  parentCompany: LunchNowReference<Company>;
  subCompanies: LunchNowReference<Company>[];
  constructor(
    documentSnapshot?: DocumentSnapshot<Company> | firestore.DocumentSnapshot
  ) {
    super(LnConfiguration.company, documentSnapshot);
    if (this.customerId === undefined) {
      this.customerId = 0;
    }
    if (!this.addOns) {
      this.addOns = AddOns.parse({
        facebookConnectEnabled: false,
        widgetEnabled: false,
        printSettings: false,
        poolFunction: false,
      });
    }
  }
  public static parse(data): Company {
    const newObj = new Company();
    newObj.parse(data);
    return newObj;
  }
}

export class Pool extends LunchNowDocument<Pool> {
  meals?: CollectionReference;
  offers?: CollectionReference;
  name: string;
  blocked: boolean;
  canEdit: string[];
  company: LunchNowReference<Company>;
  restaurants: DocumentReference[] | boolean;
  settings: {
    onlyPoolMeals: boolean;
    onlyAllowPeriod: boolean;
    startDate: firestore.Timestamp;
    endDate: firestore.Timestamp;
  };
  parentPool: LunchNowReference<Pool>;
  subPoolCompanies: LunchNowReference<Company>[];

  constructor(documentSnapshot?: DocumentSnapshot<Pool>) {
    super(LnConfiguration.pool, documentSnapshot);
  }
  public static parse(data): Pool {
    const newObj = new Pool();
    newObj.parse(data);
    return newObj;
  }
}

export class Meal extends LunchNowDocument<Meal> {
  availability: CollectionReference;
  currency?: string;
  description?: string;
  name?: string;
  type?: LunchNowReference<MealType>;
  order: number;
  allergens: LunchNowReference<Allergen>[];
  additives: LunchNowReference<Additive>[];
  tags: LunchNowReference<TasteTag>[];
  price?: number;
  orderable?: boolean;
  discountable?: boolean;
  poolMeal?: LunchNowReference<PoolMeal>;
  constructor(
    documentSnapshot?: DocumentSnapshot<any> | QueryDocumentSnapshot<any>
  ) {
    super(LnConfiguration.mealSetting, documentSnapshot);
    if (!this.tags) {
      this.tags = [];
    }
  }
  public static parse(data): Meal {
    const newObj = new Meal();
    newObj.parse(data);
    return newObj;
  }
  public isMergedMeal(): boolean {
    return !!this.poolMeal;
  }
}

export class Discount extends LunchNowDocument<Discount> {
  type?: VoucherType;
  mealRef?: LunchNowReference<Meal>;
  restaurantRef?: LunchNowReference<Restaurant>;
  companyRef?: any;
  start?: firestore.Timestamp;
  end?: firestore.Timestamp;
  quantity: number;
  usedBy?: [];
  owners?: [];
  discount?: number;
  discountType?: DiscountType;
  paidByUs?: number;
  imageUrl?: string;
  platform?: boolean;
  allRestaurnat?: boolean;
  allMeals?: boolean;
  maximumVoucherPrice?:number;
  name?: {
    de: string;
    en: string;
  };

  constructor(documentSnapshot?: any
  ) {
    super(LnConfiguration.discounts, documentSnapshot);
  }
  public static parse(data): Discount {
    const newObj = new Discount();
    newObj.parse(data);
    return newObj;
  }

  public get startDate() {
    if (this.start) {
      return this.start.toDate();
    }

    return null;
  }

  public get endDate() {
    if (this.end) {
      return this.end.toDate();
    }

    return null;
  }
}

export class PoolMeal extends Meal {
  public pool: Pool;
  constructor(pool: Pool, snapshot?: any) {
    super(snapshot);
    this.pool = pool;
  }
}

export class MergedMeal extends PoolMeal {
  // only for the case, if there is no corresponding pool meal inside restaurants.meals collection
  existsInDatabase: boolean;
  // default value is set to true, and in MealService.getAvailability() changed. Important for editing the meal.
  // It tells if any data in collection meal.availabilities exists
  availabilityExists = true;

  constructor(poolMeal: PoolMeal, resMeal: Meal, existsInDatabase?: boolean) {
    super(poolMeal.pool);
    this.ref = resMeal.ref;
    this.availability = this.ref.collection('availability');
    this.poolMeal = resMeal.poolMeal;
    this.existsInDatabase =
      existsInDatabase === undefined ? true : existsInDatabase;
    // delete default value for tags for working correct with the parse method
    delete this.tags;
    super.parse(poolMeal.data());
    if (!this.tags) {
      this.tags = [];
    }
    if (!poolMeal.ref.isEqual(this.poolMeal.ref)) {
      throw new LnException(
        'The restaurant meal doesnt match the pool meal',
        LnException.ERROR_CODE_OBJECT_NOT_ALLOWED
      );
    }
    const editFields = this.pool.canEdit ? this.pool.canEdit : [];
    for (const field of editFields) {
      if (resMeal[field] !== undefined) {
        // save origin value in _field, e.g. origin value: _price=5.4, overridden value price=3.4
        this[`_${field}`] = this[field];
        this[field] = resMeal[field];
      }
    }
  }

  public canEdit(field: string): boolean {
    if (!this.pool || !this.pool.canEdit) {
      return false;
    }
    // console.log(`canEdit(${field}) ` + this.pool.canEdit.includes(field), this.pool);
    return this.pool.canEdit.includes(field);
  }

  public update(data?: any): Promise<void> {
    const fields: string[] = this.pool.canEdit || [];
    const filtered = Object.keys(data)
      .filter((key) => fields.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
    return super.update(filtered);
  }
}

export class PartnerMail extends LunchNowDocument<PartnerMail> {
  dateCreated: firestore.Timestamp;
  restaurantName?: string;
  subject?: string;
  userAgent?: string;
  contactName?: string;
  partnerId?: number;
  recipient?: string;
  contact?: Contact;
  constructor(documentSnapshot?: DocumentSnapshot<PartnerMail>) {
    super(LnConfiguration.partnerEmail, documentSnapshot);
    this.dateCreated = firestore.Timestamp.now();
  }
  public static parse(data): PartnerMail {
    const newObj = new PartnerMail();
    newObj.parse(data);
    return newObj;
  }
}

export class KpiMail extends LunchNowDocument<KpiMail> {
  dateCreated: firestore.Timestamp;
  dateSent: firestore.Timestamp;
  user: LunchNowReference<User>;
  restaurants: LunchNowReference<Restaurant>[];
  constructor(documentSnapshot?: DocumentSnapshot<KpiMail>) {
    super(LnConfiguration.kpiMail, documentSnapshot);
    this.dateCreated = firestore.Timestamp.now();
  }
  public static parse(data): KpiMail {
    const newObj = new KpiMail();
    newObj.parse(data);
    return newObj;
  }
}

export class Attribute extends LunchNowDocument<Attribute> {
  name: string;
  constructor(documentSnapshot?: DocumentSnapshot<Attribute>) {
    super(LnConfiguration.attribute, documentSnapshot);
  }
  public static parse(data): Attribute {
    const newObj = new Attribute();
    newObj.parse(data);
    return newObj;
  }
}

export class Additive extends LunchNowDocument<Additive> {
  name: string;
  acronym: string;
  constructor(documentSnapshot?: DocumentSnapshot<Additive>) {
    super(LnConfiguration.additive, documentSnapshot);
  }
  public static parse(data): Additive {
    const newObj = new Additive();
    newObj.parse(data);
    return newObj;
  }
}

export class Allergen extends LunchNowDocument<Allergen> {
  name: string;
  acronym: string;
  constructor(documentSnapshot?: DocumentSnapshot<Allergen>) {
    super(LnConfiguration.allergen, documentSnapshot);
  }
  public static parse(data): Allergen {
    const newObj = new Allergen();
    newObj.parse(data);
    return newObj;
  }
}

export class TasteTag extends LunchNowDocument<TasteTag> {
  name: string;
  color: string;
  popularity?: number;
  type: TasteTagType;
  translations?: any;
  priority?: number;
  constructor(documentSnapshot?: DocumentSnapshot<TasteTag>) {
    super(LnConfiguration.tasteTag, documentSnapshot);
  }
  public static parse(data): TasteTag {
    const newObj = new TasteTag();
    newObj.parse(data);
    return newObj;
  }
}

export class MealType extends LunchNowDocument<MealType> {
  name: string;
  translations?: any;
  constructor(documentSnapshot?: DocumentSnapshot<MealType>) {
    super(LnConfiguration.mealType, documentSnapshot);
  }
  public static parse(data): MealType {
    const newObj = new MealType();
    newObj.parse(data);
    return newObj;
  }
}

export class PublicHoliday extends LunchNowDocument<PublicHoliday> {
  counties: string;
  countryCode: string;
  date: string;
  localName: string;
  name: string;

  constructor(documentSnapshot?: DocumentSnapshot<PublicHoliday>) {
    super(LnConfiguration.publicHoliday, documentSnapshot);
  }
  public static parse(data): PublicHoliday {
    const newObj = new PublicHoliday();
    newObj.parse(data);
    return newObj;
  }
}

export class PrintSettings extends LunchNowDocument<PrintSettings> {
  multiPage?: boolean;
  previewUrl?: string;
  hideSupplement?: boolean;
  showPublicHoliday?: boolean;
  customText?: string;
  templateId?: string;
  fontSize?: string;
  customHolidayTexts?: { [date: string]: string } = {};
  constructor(documentSnapshot?: any) {
    super(LnConfiguration.printSettings, documentSnapshot);
  }
  public static parse(data): PrintSettings {
    const newObj = new PrintSettings();
    newObj.parse(data);
    return newObj;
  }
}

export class PrintTemplate extends LunchNowDocument<PrintTemplate> {
  previewUrl?: string;
  templateName?: string;
  name?: string;
  enabled?: boolean;
  users?: LunchNowReference<User>[];
  order?: number;
  constructor(documentSnapshot?: any) {
    super(LnConfiguration.printTemplate, documentSnapshot);
  }
  public static parse(data): PrintTemplate {
    const newObj = new PrintTemplate();
    newObj.parse(data);
    return newObj;
  }
  public hasUser(user: User): boolean {
    if (!this.hasUsers) {
      return false;
    }
    return this.users.some((userRef: LunchNowReference<User>) =>
      userRef.ref.isEqual(user.ref)
    );
  }
  public get hasUsers(): boolean {
    if (!this.users) {
      return false;
    }
    return this.users.length > 0;
  }
}

export class Tag extends LunchNowDocument<Tag> {
  color: string;
  name: string;
  popularity?: number;
  translations?: any;
  type: string;
  priority?: number;
  constructor(documentSnapshot?: any) {
    super(LnConfiguration.tag, documentSnapshot);
  }
  public static parse(data): Tag {
    const newObj = new Tag();
    newObj.parse(data);
    return newObj;
  }
}
export class KpiEvent extends LunchNowDocument<KpiEvent> {
  eventName: string;
  interval: number;
  restaurant: Restaurant;
  timestamp: number;
  value: number;
  delta: number;
  constructor(documentSnapshot?: any) {
    super(LnConfiguration.kpiEvent, documentSnapshot);
  }
  public static parse(data): KpiEvent {
    const newObj = new KpiEvent();
    newObj.parse(data);
    return newObj;
  }
}

export class LnCmsElement extends LunchNowDocument<LnCmsElement> {
  active: boolean;
  flagName: string;
  type: LnCmsElementTypes;
  value: any;
  isKeyValue: boolean;
  areas?: LnCmsAreas[];
  headline?: string;
  key?: string;
  image?: string;
  size?: string;
  url?: string;
  order?: number;
  urlDescription?: string;
  constructor(documentSnapshot?: DocumentSnapshot<LnCmsElement>) {
    super(
      LnConfiguration.lnCmsElement.type[LnCmsElement.getType(documentSnapshot)],
      documentSnapshot
    );
  }
  public static parse(data): LnCmsElement {
    const newObj = new LnCmsElement();
    newObj.parse(data);
    return newObj;
  }
  private static getType(
    documentSnapshot?: DocumentSnapshot<LnCmsElement>
  ): LnCmsElementTypes {
    if (!documentSnapshot || !documentSnapshot.exists) {
      return LnCmsElementTypes.CARD_SMALL;
    }
    return documentSnapshot.data().type || LnCmsElementTypes.CARD_SMALL;
  }
  public parse(data: any): void {
    if (data) {
      super.setConfiguration(
        LnConfiguration.lnCmsElement.type[
        data.type || LnCmsElementTypes.CARD_SMALL
        ]
      );
      super.parse(data);
    }
  }
  /* TODO when updating the LnCmsElement type (e.g. large card to pdf) some properties are redundant, overide this method and delete such properies
  public updateData(): any {
    for (const type of Object.keys(LnConfiguration.lnCmsElement.type)) {
    }
    const data = super.data();
    for (const prop of this.configuration.properties) {
      if (data[prop] === null ||  data[prop] === undefined) {
        data[prop] = firestore.FieldValue.delete();
      }
    }
    return data;
  }
   */
}

export class RestaurantPrivateData extends LunchNowDocument<
  RestaurantPrivateData
  > {
  profileProgressMail?: {
    percentage: number;
    name: string;
    user: firestore.DocumentReference;
    timestamp: firestore.Timestamp;
  };
  constructor(documentSnapshot?: any) {
    super(LnConfiguration.restaurantPrivateData, documentSnapshot);
  }
  public static parse(data): RestaurantPrivateData {
    const newObj = new RestaurantPrivateData();
    newObj.parse(data);
    return newObj;
  }
}

export class RestTimeType extends LunchNowDocument<RestTimeType> {
  category: RestTimeType_Category;
  translations: object;
  constructor(documentSnapshot?: any) {
    super(LnConfiguration.restTimeType, documentSnapshot);
  }
  public static parse(data): RestTimeType {
    const newObj = new RestTimeType();
    newObj.parse(data);
    return newObj;
  }
}

export class LnException {
  public static ERROR_CODE_CREATION_FAILED = 'auth/creation_failed';
  public static ERROR_CODE_OBJECT_REQUIRED = 'model/object_required';
  public static ERROR_CODE_OBJECT_NOT_ALLOWED = 'model/object_not_allowed';
  message: string;
  code: string;
  data: any;
  constructor(_message: string, _code?: string, _data?: any) {
    this.message = _message;
    this.code = _code || '';
    this.data = _data;
  }
  public static code(error: any): string {
    if (error) {
      if (error instanceof HttpErrorResponse) {
        const httpError = error.error;
        if (httpError) {
          return httpError.code;
        }
      }
      return error.code;
    }
    return '';
  }
}

export interface ICountry {
  displayName: string;
  alpha2: string;
}

export interface IEmptyPageButton {
  name: string;
  onClick?: () => any;
}

export interface ILanguage {
  displayName: string;
  alpha2: string;
}

export interface LunchNowModelConfiguration {
  mandatoryFields?: string[];
  properties?: string[];
  collections?: { name: string }[];
  objects?: {
    name: string;
    type?: any;
    clazz?: any;
    createObject?: (documentSnapshot?) => any;
  }[];
  arrays?: {
    name: string;
    type?: any;
    clazz?: any;
    createObject?: (documentSnapshot?) => any;
  }[];
}

export interface IMenu {
  end: Date;
  start: Date;
  currency: string;
  name?: string;
  description: string;
  price: string;
  timestamp?: number;
}

export interface IPdf {
  name: string;
  path: string;
  size?: string;
}
export interface IVideo {
  name: string;
  url: string;
  time?: string;
}

// To avoid the circular dependency problem is the configuration here

const locationConfiguration: LunchNowModelConfiguration = {
  properties: ['geohash'],
  objects: [{ name: 'geopoint' }],
};

const cuisineConfiguration: LunchNowModelConfiguration = {
  properties: ['name'],
};

const mealTypeConfiguration: LunchNowModelConfiguration = {
  properties: ['name', 'translations'],
};

const publicHolidayConfiguration: LunchNowModelConfiguration = {
  properties: ['counties', 'countryCode', 'date', 'localName', 'name'],
};

const attributeConfiguration: LunchNowModelConfiguration = {
  properties: ['name'],
};

const additiveConfiguration: LunchNowModelConfiguration = {
  properties: ['name', 'acronym'],
};

const allergenConfiguration: LunchNowModelConfiguration = {
  properties: ['name', 'acronym'],
};

const tasteTagConfiguration: LunchNowModelConfiguration = {
  properties: [
    'name',
    'color',
    'popularity',
    'type',
    'translations',
    'priority',
  ],
};

const roleConfiguration: LunchNowModelConfiguration = {
  properties: [
    'admin',
    'dashboard',
    'company',
    'help',
    'meals',
    'menus',
    'restaurant',
    'sales',
  ],
};

const addressConfiguration: LunchNowModelConfiguration = {
  properties: [
    'country',
    'locality',
    'postalCode',
    'street',
    'streetNumber',
    'formattedAddress',
    'placeId',
    'plusCode',
    'state',
    'stateCode',
  ],
};

const addOnsConfiguration: LunchNowModelConfiguration = {
  properties: [
    'facebookConnectEnabled',
    'widgetEnabled',
    'printSettings',
    'poolFunction',
  ],
};

const detailConfiguration: LunchNowModelConfiguration = {
  properties: [
    'handicap',
    'parkingExternal',
    'parkingRestaurant',
    'paymentAE',
    'paymentCA',
    'paymentDC',
    'paymentEC',
    'paymentMC',
    'paymentVS',
    'seatsInside',
    'seatsOutside',
    'smokingOutside',
    'sodexo',
    'ticketRestaurant',
    'wifi',
    'smokingInside',
    'deliveryEnabled',
    'takeawayEnabled',
    'reservationEnabled',
    'widgetEnabled',
    'bookatableId',
    'paypal',
  ],
};

const mangoPayDataConfiguration: LunchNowModelConfiguration = {
  properties: ['creditedWalletId', 'userId'],
};

const contactConfiguration: LunchNowModelConfiguration = {
  properties: ['email', 'phoneNumber', 'web'],
};

const timesConfiguration: LunchNowModelConfiguration = {
  arrays: [
    { name: '1', clazz: TimesTimeSpan },
    { name: '2', clazz: TimesTimeSpan },
    { name: '3', clazz: TimesTimeSpan },
    { name: '4', clazz: TimesTimeSpan },
    { name: '5', clazz: TimesTimeSpan },
    { name: '6', clazz: TimesTimeSpan },
    { name: '7', clazz: TimesTimeSpan },
  ],
};
const restaurantNameConfiguration: LunchNowModelConfiguration = {
  properties: ['name', 'formattedAddress'],
  objects: [{ name: 'restaurant', clazz: LunchNowReference, type: Restaurant }],
};

const timesTimeSpanConfiguration: LunchNowModelConfiguration = {
  properties: ['start', 'end'],
};

const mediumConfiguration: LunchNowModelConfiguration = {
  properties: [
    'dateCreated',
    'type',
    'url',
    'storageUrl',
    'storageRef',
    'youtubeId',
    'name',
  ],
};

const printTemplateConfiguration: LunchNowModelConfiguration = {
  properties: ['templateName', 'previewUrl', 'name', 'enabled', 'order'],
  arrays: [{ name: 'users', clazz: LunchNowReference, type: User }],
};

const printSettingsConfiguration: LunchNowModelConfiguration = {
  properties: [
    'multiPage',
    'hideSupplement',
    'customText',
    'templateId',
    'fontSize',
    'previewUrl',
    'showPublicHoliday',
    'customHolidayTexts',
  ],
};

const widgetSettingStyleCssConfiguration: LunchNowModelConfiguration = {
  properties: ['base', 'footer', 'heading', 'menu', 'custom'],
};

const languageConfiguration: LunchNowModelConfiguration = {
  properties: ['alpha2', 'displayName', 'flag'],
};

const goodieConfiguration: LunchNowModelConfiguration = {
  properties: [
    'text',
    'code',
    'start',
    'end',
    'value',
    'from',
    'to',
    'weekdays',
  ],
};

const mealConfiguration: LunchNowModelConfiguration = {
  properties: [
    'currency',
    'description',
    'name',
    'order',
    'price',
    'orderable',
    'currency',
    'discountable'
  ],
  arrays: [
    { name: 'allergens', clazz: LunchNowReference, type: Allergen },
    { name: 'additives', clazz: LunchNowReference, type: Additive },
    { name: 'tags', clazz: LunchNowReference, type: TasteTag },
  ],
  objects: [
    { name: 'type', clazz: LunchNowReference, type: MealType },
    { name: 'poolMeal', clazz: LunchNowReference, type: PoolMeal },
  ],
  collections: [{ name: 'availability' }],
};

const widgetSettingConfiguration: LunchNowModelConfiguration = {
  properties: [
    'dateModified',
    'dateFormat',
    'dateSpanFormat',
    'period',
    'textHeader',
    'textFooter',
    'useCustomCss',
    'textMenuHeader',
    'useCustomFont',
    'periodWeeks',
    'dateRendered',
    'type',
  ],
  objects: [
    { name: 'style', clazz: WidgetSettingStyle },
    { name: 'priceFormat', clazz: WidgetSettingPriceFormat },
  ],
};

const widgetSettingPriceFormatConfiguration: LunchNowModelConfiguration = {
  properties: ['precision', 'separator'],
};

const widgetSettingStyleConfiguration: LunchNowModelConfiguration = {
  properties: [
    'bgColor',
    'fontFamily',
    'fontSizeDate',
    'fontSizeItem',
    'fontSizeItemDescription',
    'fontSizeItemPrice',
    'fontSizeTextFooter',
    'fontSizeTextHeader',
    'fontSizeTextMenuHeader',
    'layout',
    'restaurantLogo',
    'showCurrency',
    'showDateSpan',
    'showPrice',
    'textColor',
    'showWeekday',
    'showPublicHoliday',
    'customFontFamily',
    'customFontUrl',
    'useCustomFont',
    'bgColorTransparent',
    'showDaysWithoutItems',
  ],
  objects: [{ name: 'css', clazz: WidgetSettingStyleCss }],
};

const userConfiguration: LunchNowModelConfiguration = {
  properties: [
    'customerId',
    'email',
    'firstName',
    'lastName',
    'telephone',
    'lastLogin',
    'role',
    'language',
    'sendKpiReport',
  ],
  objects: [{ name: 'restaurants' }],
};

const tagConfiguration: LunchNowModelConfiguration = {
  properties: [
    'color',
    'name',
    'popularity',
    'translations',
    'type',
    'priority',
  ],
};

const kpiEventConfiguration: LunchNowModelConfiguration = {
  properties: [
    'eventName',
    'interval',
    'restaurant',
    'timestamp',
    'value',
    'delta',
  ],
};

const companyConfiguration: LunchNowModelConfiguration = {
  properties: ['customerId', 'name', 'admin', 'commissionRate'],
  arrays: [
    { name: 'restaurants', clazz: LunchNowReference, type: Restaurant },
    { name: 'subCompanies', clazz: LunchNowReference, type: Company },
  ],
  objects: [
    { name: 'addOns', clazz: AddOns },
    { name: 'parentCompany', clazz: LunchNowReference, type: Company },
  ],
};

const poolConfiguration: LunchNowModelConfiguration = {
  properties: ['name', 'canEdit', 'blocked', 'restaurants', 'settings'],
  objects: [
    { name: 'company', clazz: LunchNowReference, type: Company },
    { name: 'parentPool', clazz: LunchNowReference, type: Pool },
  ],
  collections: [{ name: 'meals' }, { name: 'offers' }],
  arrays: [
    { name: 'subPoolCompanies', clazz: LunchNowReference, type: Company },
  ],
};

const partnerMailConfiguration: LunchNowModelConfiguration = {
  properties: [
    'dateCreated',
    'restaurantName',
    'subject',
    'userAgent',
    'contactName',
    'partnerId',
    'recipient',
  ],
  objects: [{ name: 'contact', clazz: Contact }],
};

const kpiMailConfiguration: LunchNowModelConfiguration = {
  properties: ['dateCreated', 'dateSent', 'user'],
  arrays: [{ name: 'restaurants', clazz: LunchNowReference, type: Restaurant }],
};

const lnCmsElementConfiguration: any = {
  mandatoryFields: ['isKeyValue', 'active', 'flagName', 'type', 'value'],
};

lnCmsElementConfiguration.type = {
  [LnCmsElementTypes.PDF]: {
    properties: [
      ...lnCmsElementConfiguration.mandatoryFields,
      'order',
      'areas',
      'size',
      'url',
    ],
  },
  [LnCmsElementTypes.VIDEO]: {
    properties: [
      ...lnCmsElementConfiguration.mandatoryFields,
      'order',
      'areas',
      'size',
      'url',
    ],
  },
  [LnCmsElementTypes.CARD_LARGE]: {
    properties: [
      ...lnCmsElementConfiguration.mandatoryFields,
      'order',
      'areas',
      'image',
      'headline',
      'url',
      'urlDescription',
    ],
  },
  [LnCmsElementTypes.CARD_SMALL]: {
    properties: [
      ...lnCmsElementConfiguration.mandatoryFields,
      'order',
      'areas',
      'image',
    ],
  },
  [LnCmsElementTypes.TEXT]: {
    properties: [...lnCmsElementConfiguration.mandatoryFields, 'key'],
  },
  [LnCmsElementTypes.NUMBER]: {
    properties: [...lnCmsElementConfiguration.mandatoryFields, 'key'],
  },
  [LnCmsElementTypes.BOOLEAN]: {
    properties: [...lnCmsElementConfiguration.mandatoryFields, 'key'],
  },
};

const restaurantConfiguration: LunchNowModelConfiguration = {
  properties: [
    'description',
    'name',
    'type',
    'ratingNumber',
    'routingName',
    'contractId',
    'sharingURL',
    'premiumStart',
    'premiumEnd',
    'profileProgress',
    'notShowGuide',
    'active',
    'kycDocument',
    'orderForerunMinutes',
    'maximumVoucherPrice',
    'voucherPercentage',
    'onlyTakeAway'
  ],
  collections: [
    { name: 'meals' },
    { name: 'printSettings' },
    { name: 'goodies' },
    { name: 'offers' },
    { name: 'widgetSettings' },
    { name: 'roles' },
  ],
  objects: [
    { name: 'address', clazz: Address },
    { name: 'contact', clazz: Contact },
    { name: 'details', clazz: Detail },
    { name: 'mangoPayData', clazz: MangoPayData },
    { name: 'location' },
    { name: 'logo', clazz: Medium },
    { name: 'company', clazz: LunchNowReference, type: Company },
    { name: 'openingHours', clazz: Times },
    { name: 'lunchTimes', clazz: Times },
    { name: 'restTime', clazz: RestTime },
    { name: 'holidayTime', clazz: LunchNowReference, type: RestTimeType },
  ],
  arrays: [
    { name: 'cuisines', clazz: LunchNowReference, type: Cuisine },
    { name: 'media', clazz: Medium },
    { name: 'menuFiles', clazz: Medium },
    { name: 'tags', clazz: LunchNowReference, type: TasteTag },
  ],
};

const restaurantPrivateDataConfiguration: LunchNowModelConfiguration = {
  properties: ['profileProgressMail'],
};

const restTimeConfiguration = {
  properties: ['start', 'end'],
  objects: [
    { name: 'reference', clazz: LunchNowReference, type: RestTimeType },
  ],
};

const restTimeTypeConfiguration = {
  objects: [{ name: 'translations' }],
};

const discountsConfiguration = {
  properties: [
    'type',
    'start',
    'end',
    'quantity',
    'usedBy',
    'owners',
    'discount',
    'discountType',
    'paidByUs',
    'name',
    'imageUrl',
    'platform',
    'allRestaurnat',
    'allMeals',
    'maximumVoucherPrice'
],
  objects: [
    { name: 'companyRef', clazz: LunchNowReference, type: Company },
    { name: 'mealRef', clazz: LunchNowReference, type: Meal },
    { name: 'restaurantRef', clazz: LunchNowReference, type: Restaurant },

  ],
};

export class LnConfiguration {
  public static cuisine = cuisineConfiguration;
  public static address = addressConfiguration;
  public static contact = contactConfiguration;
  public static medium = mediumConfiguration;
  public static restaurant = restaurantConfiguration;
  public static detail = detailConfiguration;
  public static mangoPayData = mangoPayDataConfiguration;
  public static addOns = addOnsConfiguration;
  public static location = locationConfiguration;
  public static times = timesConfiguration;
  public static role = roleConfiguration;
  public static timesTimeSpan = timesTimeSpanConfiguration;
  public static widgetSettingStyleCss = widgetSettingStyleCssConfiguration;
  public static widgetSettingStyle = widgetSettingStyleConfiguration;
  public static widgetSettingPriceFormat = widgetSettingPriceFormatConfiguration;
  public static widgetSetting = widgetSettingConfiguration;
  public static mealSetting = mealConfiguration;
  public static restaurantName = restaurantNameConfiguration;
  public static attribute = attributeConfiguration;
  public static additive = additiveConfiguration;
  public static allergen = allergenConfiguration;
  public static tasteTag = tasteTagConfiguration;
  public static mealType = mealTypeConfiguration;
  public static publicHoliday = publicHolidayConfiguration;
  public static user = userConfiguration;
  public static lnCmsElement = lnCmsElementConfiguration;
  public static printTemplate = printTemplateConfiguration;
  public static printSettings = printSettingsConfiguration;
  public static language = languageConfiguration;
  public static goodie = goodieConfiguration;
  public static partnerEmail = partnerMailConfiguration;
  public static kpiMail = kpiMailConfiguration;
  public static tag = tagConfiguration;
  public static kpiEvent = kpiEventConfiguration;
  public static company = companyConfiguration;
  public static pool = poolConfiguration;
  public static restTime = restTimeConfiguration;
  //  public static poolMeal = poolMealConfiguration;
  public static restaurantPrivateData = restaurantPrivateDataConfiguration;
  public static restTimeType = restTimeTypeConfiguration;
  public static discounts = discountsConfiguration;
}
