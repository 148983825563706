
import { KpiEvent, Restaurant } from '../models/model';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HelperService } from './helper.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import * as FileSaver from 'file-saver';
import * as firebase from 'firebase/app';
import { RestaurantService } from './restaurant.service';
import { MangoPayRevenue } from './../partner/restaurant-kpi-dashboard/restaurant-kpi-dashboard.component';

const KPI_EVENTS_COLLECTION = 'kpi_events';


@Injectable()
export class KpiService extends HelperService {
  locale: string;
  constructor(
    private _us: UserService, private _http: HttpClient, private _rs: RestaurantService,
    _aFs: AngularFirestore,
    @Inject(LOCALE_ID) _locale: string) {
    super(_aFs);
    this.locale = _locale;
  }

  public getKpiEvents(restaurant: Restaurant): Promise<KpiEvent[]> {
    if (!restaurant) {
      return Promise.resolve(null);
    }

    const lastMonth = firebase.firestore.Timestamp.fromDate(moment().utc().add(-1, 'month').startOf('month').toDate());
    return this._aFs.firestore.collection(KPI_EVENTS_COLLECTION)
      .where('restaurant', '==', restaurant.ref)
      .where('timestamp', '>=', lastMonth)
      .get()
      .then((querySnapshot) => {
        return super.mapQuerySnapshot<KpiEvent>(querySnapshot, KpiEvent);
      });
  }

  public getKpiEvents$(): Observable<KpiEvent[]> {
    const collection: AngularFirestoreCollection<KpiEvent> = this._aFs.collection<KpiEvent>(KPI_EVENTS_COLLECTION, ref => {
        return ref.orderBy('order', 'asc');
    });
    return super.mapSnapshotChanges$(collection, KpiEvent);
  }
  public async generateKpiEvent() {
    const selectedRestaurant = this._rs.getSelectedRestaurant();
  //  const date: moment.Moment = moment().utc().startOf('month');
    return new Promise((resolve, reject) => {
      this._us.user.firebaseUser.getIdToken().then(token => {
        const url = `${environment.kpiApiURL}/printKpi/?resKey=${selectedRestaurant.id}&auth=${token}`;
        this._http.get(url, { observe: 'response', responseType: 'blob' }).subscribe(response => {
          const blob = new Blob([response.body], { type: response.body.type });
          // TODO: How can we improve that, so we don't need to add the file extension?
          FileSaver.saveAs(blob, `KpiEvents-${moment().toISOString()}.pdf`);
          resolve();
        });
      }).catch(e => {
        console.error(e);
        reject();
      });
    });
  }

  public async getRevenueFromMangoPay(restaurant: Restaurant, numberOfMonths: number) {
    const restaurantKey = restaurant.id;
    return new Promise<MangoPayRevenue[]>((resolve, reject) => {
      this._us.user.firebaseUser.getIdToken().then(token => {
        const url = `${environment.kpiApiURL}/getMangoRevenue/?resKey=${restaurantKey}&auth=${token}&numberOfMonths=${numberOfMonths}`;
        this._http.get(url, { observe: 'response', responseType: 'json' })
        .subscribe(response => {
          resolve(response.body as MangoPayRevenue[]);
        });
      })
      .catch(e => {
        console.error(e);
        reject();
      });
    });
  }

}
