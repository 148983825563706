import * as packageJSON from '../../package.json';

const cloudFunctionsBaseURL = 'https://europe-west1-lunchnow-partner-development.cloudfunctions.net/api';
// const cloudFunctionsBaseURL = 'http://localhost:5000/lunchnow-partner-development/us-central1/api';

// urls that can be cached by the CDN or that should be visible to the user should use our domain
const externalCloudFunctionsBaseURL = 'https://dev.partner.lunchnow.com';

// require definition in typings.d.ts makes conflict with ng test
// declare var require: any;
export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyBr-YARBcyZH1k1zxVtq0iikmjT5hpD9fw',
        authDomain: 'lunchnow-partner-development.firebaseapp.com',
        databaseURL: 'https://lunchnow-partner-development.firebaseio.com',
        projectId: 'lunchnow-partner-development',
        storageBucket: 'lunchnow-partner-development.appspot.com',
        messagingSenderId: '73038592107',
        appId: '1:73038592107:web:26db09d743eef9d5',
        measurementId: 'G-B6WKRNHTH9'
    },
    customerWebsiteUrl: 'https://dev.customer.lunchnow.com',
    usersApiURL: cloudFunctionsBaseURL + '/api/users',
    mediaApiURL: externalCloudFunctionsBaseURL + '/api/media',
    paymentApiURL: cloudFunctionsBaseURL + '/api/payment',
    menusApiURL: cloudFunctionsBaseURL + '/api/menus',
    widgetApiURL: externalCloudFunctionsBaseURL + '/api/widget',
    adminApiURL: cloudFunctionsBaseURL + '/api/admin',
    poolsApiURL: cloudFunctionsBaseURL + '/api/pools',
    kpiApiURL: cloudFunctionsBaseURL + '/api/kpi',
    restaurantsApiURL: cloudFunctionsBaseURL + '/api/restaurants',
    discountApiURL: cloudFunctionsBaseURL + '/api/discount',

    VERSION: packageJSON.version + '-develop',
    supportMailRecipient: 'developers@lunchnow.com',
    defaultLang: 'de',
};
