import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-inline-editor',
  templateUrl: './inline-editor.component.html',
  styleUrls: ['./inline-editor.component.less']
})
export class InlineEditorComponent implements OnInit, OnChanges {
  @Output() done = new EventEmitter<string>();
  @Input() editable: boolean;
  @Input() errors: string[];
  @Input() href: string | null;
  @Input() target: string | null;
  @Input() icon: string;
  @Input() on: boolean;
  @Output() onChange = new EventEmitter<boolean>();
  @Input() placeholder: string;
  @Input() value: string;
  _value: string;
  constructor() { }
  ngOnInit() {
    this._value = this.value;
    if (!this.href) {
      this.href = null;
    }
    if (!this.target) {
      this.target = null;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this._value = changes.value.currentValue;
    }
  }
  show(visibility: boolean) {
    this.onChange.emit(visibility);
    this.on = visibility;
    if (!visibility) {
      this.errors = [];
      this._value = this.value;
    }
  }
  onDone() {
    if (this.done) {
      if (this._value !== this.value) {
        this.done.emit(this._value);
      } else {
        this.show(false);
      }
    }
  }
  get hasError(): boolean {
    return this.errors && this.errors.length > 0;
  }
}
