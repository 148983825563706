import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LanguageSelectorDialogComponent} from '../language-selector-dialog/language-selector-dialog.component';
import {UserService} from '../../services/user.service';
import {LanguageService} from '../../services/language.service';
import {ILanguage} from '../../models/model';

@Component({
  selector: 'app-language-selector-button',
  templateUrl: './language-selector-button.component.html',
  styleUrls: ['./language-selector-button.component.less']
})
export class LanguageSelectorButtonComponent implements OnInit {
  @Input() isMenuItem: boolean; // determines if its a mat-raised-button or a mat-menu-item
  language: ILanguage;

  constructor(private _us: UserService, private _ls: LanguageService, private _dialog: MatDialog) { }

  ngOnInit() {
    this.language = this._ls.currentLanguage;
  }

  openLanguageSelectorDialog() {
    const dialogRef = this._dialog.open(LanguageSelectorDialogComponent, {
      autoFocus: false,
      height: '480px',
      width: '480px',
      data: { language: this.language },
    });

    dialogRef.afterClosed().subscribe(async selectedLanguage => {
      if (selectedLanguage && !this._ls.isCurrentLanguage(selectedLanguage.alpha2)) {
        this.language = selectedLanguage;
        if (this._us.isLoggedIn()) {
          this._us.user.language = selectedLanguage.alpha2;
          await this._us.updateUserLanguage(this._us.user, selectedLanguage.alpha2);
        }
        this._ls.redirect(selectedLanguage.alpha2);
      }
    });
  }
}
