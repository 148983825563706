import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-partner-change-password-form',
  templateUrl: './partner-change-password-form.component.html',
  styleUrls: ['./partner-change-password-form.component.less']
})
export class PartnerChangePasswordFormComponent implements OnInit {

  formGroup: FormGroup;

  passwordVisibility = {
    icon: 'visibility_off',
    fieldType: 'password'
  };

  passwordRules = {
    upperCase: false,
    lowerCase: false,
    passwordLength: false,
    confirm: false,
  };

  allRulesPassed: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private _us: UserService,
    private _afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      email: [this._afAuth.auth.currentUser.email, []],
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPasswordConfirm: ['', [Validators.required, Validators.minLength(8)]],
    }, {
        validator: this.confirmPasswordValidator
    });
  }

  async submit() {
    await this._us.authenticateUserPassword(this.formGroup.get('currentPassword').value);
    return this._us.setUserPassword(this._us.user.id, this.formGroup.get('newPassword').value, false);
  }

  /* Determine snackbar message by error code */
  showErrorMessage(code: string) {     
    switch (code) {
      case 'auth/weak-password':
        this.formGroup.get('newPassword').setErrors({ weak: true });
        break;
      case 'auth/wrong-password':
        this.formGroup.get('currentPassword').setErrors({ invalid: true });
        break;
      case 'auth/too-many-requests':
        this.formGroup.get('currentPassword').setErrors({ invalid: true });
        break;
    }
  }

  /* show/hide password in 'newPassword' form-field */
  togglePasswordVisibility() {
    this.passwordVisibility.icon = this.passwordVisibility.icon === 'visibility' ? 'visibility_off' : 'visibility';
    this.passwordVisibility.fieldType = this.passwordVisibility.fieldType === 'password' ? 'text' : 'password';
  }

  /* Check password rules */
  onPasswordKeyUp(event: any) {
    if (this.formGroup.value.newPassword) {
      this.passwordRules.upperCase = !!this.formGroup.value.newPassword.match('.*[A-Z].*');
      this.passwordRules.lowerCase = !!this.formGroup.value.newPassword.match('.*[a-z].*');
      this.passwordRules.passwordLength = this.formGroup.value.newPassword.length >= 8;
    }
    if (this.formGroup.value.newPassword && this.formGroup.value.newPasswordConfirm) {
      this.passwordRules.confirm = this.formGroup.value.newPassword === this.formGroup.value.newPasswordConfirm;
    }
    this.allRulesPassed = this.isValidPassword();
  }

  isValidPassword(): boolean {
    return Object.values(this.passwordRules).every(passwordRule => passwordRule);
  }

  /* custom validator for formgroup */
  confirmPasswordValidator(ac: AbstractControl) {
    const newPassword = ac.get('newPassword').value;
    const newPasswordConfirm = ac.get('newPasswordConfirm').value;
    if (newPassword !== newPasswordConfirm) {
      ac.get('newPasswordConfirm').setErrors({ matchPassword: true });
    } else {
      ac.get('newPasswordConfirm').setErrors(null);
    }
  }
}
