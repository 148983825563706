import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { PrintTemplate } from '../models/model';
import { firestore } from 'firebase';

const PRINT_TEMPLATE_COLLECTION = 'print_templates';

@Injectable()
export class PrintTemplateService extends HelperService {
  public DEFAULT_PRINT_TEMPLATE_NAME = '1-classic-prod';

  constructor(
    _aFs: AngularFirestore,
    private _http: HttpClient,
    private _us: UserService) {
    super(_aFs);
  }
  public getTemplates$(): Observable<PrintTemplate[]> {
    const collection = this._aFs.collection<PrintTemplate>(PRINT_TEMPLATE_COLLECTION, ref => {
      return ref.orderBy('order', 'asc');
    });
    return super.mapSnapshotChanges$(collection, PrintTemplate);
  }
  public setTemplate(template: PrintTemplate): Promise<void> {
    //  firestore, add or set ? in old implementation the template was added to a collection,
    //   setTemplate(template: PrintTemplate) {
    //     return this._lnds.save(template, 'restaurant_print_templates', true);
    //   }
    return this._aFs.collection(PRINT_TEMPLATE_COLLECTION).add(template.data()).then(() => Promise.resolve());
  }
  async syncTemplates(): Promise<any> {
    const token = await this._us.user.firebaseUser.getIdToken();
    const url = `${environment.menusApiURL}/syncTemplates?auth=${token}`;
    return this._http.get(url).pipe(take(1)).toPromise();
  }

  async getDefaultPrintTemplate() {
    let defaultTemplate = await this.getTemplateByName(this.DEFAULT_PRINT_TEMPLATE_NAME);
    if (!defaultTemplate || !defaultTemplate.enabled) {
      defaultTemplate = null;
    }
    return defaultTemplate;
  }

  async getTemplateByName(name: string) {
    const templates: PrintTemplate[] = await this.getAvailableTemplates();
    const filteredTemplate = templates.filter(template => {
      return template.templateName === name;
    });
    return filteredTemplate[0] || null;
  }

  
  public getAvailableTemplates$(): Observable<PrintTemplate[]> {
    return super.mapSnapshotChanges$(this._aFs.collection<PrintTemplate>(PRINT_TEMPLATE_COLLECTION, ref => {
      return ref.orderBy('order', 'asc');
    }), PrintTemplate).pipe(map(templates => this.availableTemplates(templates)));
  }
  public getAvailableTemplates(): Promise<PrintTemplate[]> {
    const query = this._aFs.firestore.collection(PRINT_TEMPLATE_COLLECTION).orderBy('order', 'asc');
    return query.get().then(querySnapshot => {
      return super.mapQuerySnapshot<PrintTemplate>(querySnapshot, PrintTemplate).then((templates: PrintTemplate[]) => {
        return this.availableTemplates(templates);
      });
    });
  }
  public getTemplateById(templateId: string): Promise<PrintTemplate> {
    const doc = this._aFs.firestore.collection(PRINT_TEMPLATE_COLLECTION).doc(templateId);
    return doc.get().then((snap: firestore.DocumentSnapshot) => {
      if (!snap.exists) {
        return null;
      }
      const filteredTemplates = this.availableTemplates([new PrintTemplate(snap)]);
      return filteredTemplates.length > 0 ? filteredTemplates[0] : null;
    });
  }
  private availableTemplates(templates: PrintTemplate[]): PrintTemplate[] {
    if (this._us.user.isAdmin) {
      return templates;
    }
    const availableTemplates: PrintTemplate[] = [];
    for (const template of templates) {
      if ((template.hasUser(this._us.user) || !template.hasUsers) && template.enabled) {
        availableTemplates.push(template);
      }
    }
    return availableTemplates;
  }
}
