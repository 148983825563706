import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {RestaurantService} from './restaurant.service';
import {Subscription} from 'rxjs';
import {Restaurant, RestaurantName} from '../models/model';

export interface ISearchResult {
  title: string;
  description: string;
  action: () => Promise<boolean>;
  terms?: string[];
  iconURL?: string;
}
@Injectable()
export class SearchService implements OnDestroy {
  resNameSubscriber: Subscription;
  //  firestore SidenavPartnerSectionComponent use also all restaurantNames array, this is redundant
  restaurantNames: RestaurantName[] ;
  constructor(private _ts: TranslateService, private _rs: RestaurantService, private router: Router) {
    this.resNameSubscriber = this._rs.getRestaurantNames$().subscribe((names: RestaurantName[]) => {
      this.restaurantNames = names;
    }, error => console.warn(error));

  }
  public async searchFor(text: string): Promise<ISearchResult[]> {
    if (!text || text.length < 2) {
      return [];
    }
    const searchText = text.toLowerCase();
    const restaurantResults = await this.searchRestaurants(searchText);
    const componentsResults = this.searchComponents(searchText);
    //  Add more search functions!

    return restaurantResults.concat(componentsResults);
  }


  private async searchRestaurants(text: string):  Promise<ISearchResult[]> {
    if (text.length < 1) {
      return [];
    }
    const filteredNames = this.restaurantNames.filter((rn: RestaurantName) => {
      return rn.name.toLowerCase().includes(text);
    }).map((rn: RestaurantName) => {
        const action = async () => {
          const restaurant: Restaurant = await rn.restaurant.get();
          this._rs.setSelectedRestaurant(restaurant);
          return true;
        };
        return { title: rn.name, description: '', action: action };
      });
    return filteredNames;
  }

  private searchComponents(text: string) {
    if (text.length < 1) {
      return [];
    }
    const components: ISearchResult[] = [
      {
        title: this._ts.instant('overview'),
        description: this._ts.instant('overview_of_restaurant'),
        action: () => this.router.navigate(['/partner/dashboard']),
        terms: [this._ts.instant('overview'), this._ts.instant('dashboard'), this._ts.instant('current_topics'), this._ts.instant('extras'), this._ts.instant('booking')]
      },
      {
        title: this._ts.instant('general'),
        description: this._ts.instant('general_restaurant_informations'),
        action: () => this.router.navigate(['/partner/restaurants/general']),
        terms: [this._ts.instant('general'), this._ts.instant('name'), this._ts.instant('description'), this._ts.instant('email'), this._ts.instant('web'), this._ts.instant('url'), this._ts.instant('website'), this._ts.instant('telephone_number'), this._ts.instant('mobile')]
      },
      {
        title: this._ts.instant('details'),
        description: this._ts.instant('details_information_pay_wlan_smoke'),
        action: () => this.router.navigate(['/partner/restaurants/details']),
        terms: [this._ts.instant('details'), this._ts.instant('services'), this._ts.instant('attendance'), this._ts.instant('electronic_purse'), this._ts.instant('seats'), this._ts.instant('wifi')]
      },
      {
        title: this._ts.instant('opening_hours'),
        description: this._ts.instant('opening_hours_lunch'),
        action: () => this.router.navigate(['/partner/restaurants/openinghours']),
        terms: [this._ts.instant('opening_hours'), this._ts.instant('open'), this._ts.instant('lunchtimes'), this._ts.instant('opened')]
      },
      {
        title: this._ts.instant('my_media'),
        description: this._ts.instant('manage_your_media'),
        action: () => this.router.navigate(['/partner/restaurants/media']),
        terms: [this._ts.instant('my_media'), this._ts.instant('photo'), this._ts.instant('video'), this._ts.instant('image'), this._ts.instant('media')]
      },
      {
        title: this._ts.instant('adress'),
        description: this._ts.instant('change_adress_of_restaurant'),
        action: () => this.router.navigate(['/partner/restaurants/address']),
        terms: [this._ts.instant('adress'), this._ts.instant('street'), this._ts.instant('house_number'), this._ts.instant('zip_code'), this._ts.instant('location'), this._ts.instant('map')]
      },
      {
        title: this._ts.instant('my_menus'),
        description: this._ts.instant('manage_your_menus'),
        action: () => this.router.navigate(['/partner/restaurants/menu-files']),
        terms: [this._ts.instant('my_menus'), this._ts.instant('photo'), this._ts.instant('video'), this._ts.instant('image'), this._ts.instant('media')]
      },
      {
        title: this._ts.instant('widget_integration'),
        description: this._ts.instant('integrate_your_menu_in_website'),
        action: () => this.router.navigate(['/partner/restaurants/widget']),
        terms: [this._ts.instant('widget_integration'), this._ts.instant('website'), this._ts.instant('html'), this._ts.instant('css'), this._ts.instant('widget'), this._ts.instant('embedding'), this._ts.instant('homepage')]
      },
      {
        title: this._ts.instant('menus'),
        description: this._ts.instant('menu_overview'),
        action: () => this.router.navigate(['/partner/menus']),
        terms: [this._ts.instant('menus'), this._ts.instant('map'), this._ts.instant('menu'), this._ts.instant('set_meal'), this._ts.instant('weekly_menu'), this._ts.instant('current'), this._ts.instant('week'), this._ts.instant('booking'), this._ts.instant('print')]
      },
      {
        title: this._ts.instant('meals'),
        description: this._ts.instant('manage_your_meals'),
        action: () => this.router.navigate(['/partner/meals']),
        terms: [this._ts.instant('food'), this._ts.instant('meals'), this._ts.instant('dishes'), this._ts.instant('create'), this._ts.instant('menu')]
      },
      {
        title: this._ts.instant('help'),
        description: this._ts.instant('tutorials'),
        action: () => this.router.navigate(['/partner/help']),
        terms: [this._ts.instant('help'), this._ts.instant('support'), this._ts.instant('tutorial'), this._ts.instant('info'), this._ts.instant('start'), this._ts.instant('video'), this._ts.instant('pdf'), this._ts.instant('instruction'), this._ts.instant('description')]
      }
    ];

    return components.filter(component => {
      const possibleTerms = component.terms.filter(term => {
        return term.toLowerCase().includes(text);
      });
      return possibleTerms.length > 0;
    });
  }

  ngOnDestroy(): void {
    this.resNameSubscriber.unsubscribe();
  }
}
