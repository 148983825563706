import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Url} from 'url';


@Component({
  selector: 'app-cell-renderer-image',
  templateUrl: './cell-renderer-image.component.html',
  styleUrls: ['./cell-renderer-image.component.less']
})
export class CellRendererImageComponent implements AgRendererComponent {
  img: Url;
  loadingError: boolean;
  refresh(params: any): boolean {
    this.setImg(params);
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.setImg(params);
  }
  setImg(params: any) {
    try {
        if (params && params.value) {
          this.img = new URL(params.value);
        } else {
          this.loadingError = true;
        }
    } catch {
      this.loadingError = true;
      throw new Error(`Unable to parse ${params.value}`);
    }
  }
}
