import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {filter, map, mergeMap} from 'rxjs/operators';
import {Utils} from '../app.utils';


@Injectable()
export class TitleService {

  public headerTitle: Observable<string>;

  constructor(private _ts: TranslateService, private _router: Router, private _activatedRoute: ActivatedRoute) {
    this.headerTitle = new Observable(observer => {
      this._router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this._activatedRoute),
          map((route) => {
            while (route.firstChild) { route = route.firstChild; }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data))
        .subscribe((event) => {
          if (!Utils.isEmpty(event.title)) {
            observer.next(
              this._ts.instant(event.title)
            );
          }
        });
    });
  }

}
