import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-widget-preview-dialog',
  templateUrl: './widget-preview-dialog.component.html',
  styleUrls: ['./widget-preview-dialog.component.css']
})
export class WidgetPreviewDialogComponent implements OnInit {
  srcUrl;

  constructor(@Inject(MAT_DIALOG_DATA) private _data: any, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this._data.url);
  }
}
