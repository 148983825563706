import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ErrorMessageService} from '../../services/error-message.service';
import {Restaurant} from '../../models/model';
import {MediaService} from '../../services/media.service';
import {RestaurantService} from '../../services/restaurant.service';

export interface MediaUploadDialogConfig {
  mediaType?: 'image' | 'logo' | 'menu';
  allowedFileExtensions: string[]; // ['jpeg', 'png', 'pdf', 'doc']
  maxFileSize?: number; // Maximum File size of uploading file in Bytes
  buttonCaption?: string; // e.g. 'Hinzufügen'
  dialogTitle?: string; // e.g. 'Speisekarte hochladen'
  fileNamePlaceholder?: string; // e.g. 'Name'
  fileTooLargeMessage?: string; // Error-message: file size exceeded
  fileExtensionMessage?: string; // Error-message: invalid file extension
}

@Component({
  selector: 'app-media-upload-dialog',
  templateUrl: './media-upload-dialog.component.html',
  styleUrls: ['./media-upload-dialog.component.less']
})
export class MediaUploadDialogComponent implements OnInit, OnDestroy {
  @ViewChild('file') file: ElementRef;
  restaurant: Restaurant;
  formGroup: FormGroup;
  uploading = false;
  dragging = false;
  restaurantSubscription: Subscription;

  uploadDialogConfig: MediaUploadDialogConfig = {
    mediaType: undefined,
    allowedFileExtensions: [],
    maxFileSize: 0,
    buttonCaption: '',
    dialogTitle: '',
    fileNamePlaceholder: '',
    fileTooLargeMessage: '',
    fileExtensionMessage: '',
  };

  uploadBoxStyles = {
    activeColor: 'green',
    baseColor: '#ccc',
  };

  error = {
    fileTooLarge: false,
    fileExtension: false
  };

  constructor(@Inject(MAT_DIALOG_DATA) private _data: MediaUploadDialogConfig,
    private _dialogRef: MatDialogRef<MediaUploadDialogComponent>,
    private _rs: RestaurantService,
    private _ms: MediaService,
    private _formBuilder: FormBuilder,
    private _ts: TranslateService,
    private _emh: ErrorMessageService) {
    this.parseUploadConfig(this._data);
  }

  ngOnInit() {
    this.restaurantSubscription = this._rs.getSelectedRestaurant$().subscribe(restaurant => {
      this.restaurant = restaurant;
    }, error => console.error(error));

    this.formGroup = this._formBuilder.group({
      file: [null, [Validators.required]],
      name: ''
    });
  }

  ngOnDestroy() {
    if (this.restaurantSubscription) {
      this.restaurantSubscription.unsubscribe();
    }
  }

  // Parse dialogConfig from parent and set default values
  parseUploadConfig(config: MediaUploadDialogConfig) {
    if (!config || !config.hasOwnProperty('mediaType') || !config.hasOwnProperty('allowedFileExtensions')) {
      console.error('No dialog data provided. Forward a valid object which implements MediaUploadDialogConfig.');
      this._dialogRef.close();
      return;
    }
    this.uploadDialogConfig.mediaType = config.mediaType;
    this.uploadDialogConfig.allowedFileExtensions = config.allowedFileExtensions;

    if (config.hasOwnProperty('maxFileSize')) {
      this.uploadDialogConfig.maxFileSize = config.maxFileSize;
    } else {
      this.uploadDialogConfig.maxFileSize = 10000000;
    }

    if (config.hasOwnProperty('buttonCaption')) {
      this.uploadDialogConfig.buttonCaption = config.buttonCaption;
    } else {
      this.uploadDialogConfig.buttonCaption = this._ts.instant('upload');
    }

    if (config.hasOwnProperty('fileTooLargeMessage')) {
      this.uploadDialogConfig.fileTooLargeMessage = config.fileTooLargeMessage;
    } else {
      this.uploadDialogConfig.fileTooLargeMessage = this.getFileTooLargeMessage(this.uploadDialogConfig.maxFileSize);
    }

    if (config.hasOwnProperty('fileExtensionMessage')) {
      this.uploadDialogConfig.fileExtensionMessage = config.fileExtensionMessage;
    } else {
      this.uploadDialogConfig.fileExtensionMessage = this.getInvalidFileExtensionMessage();
    }

    if (config.hasOwnProperty('dialogTitle')) {
      this.uploadDialogConfig.dialogTitle = config.dialogTitle;
    } else {
      this.uploadDialogConfig.dialogTitle = this._ts.instant('upload_file');
    }

    if (config.hasOwnProperty('fileNamePlaceholder')) {
      this.uploadDialogConfig.fileNamePlaceholder = config.fileNamePlaceholder;
    } else {
      this.uploadDialogConfig.fileNamePlaceholder = this._ts.instant('name');
    }
  }

  getFileTooLargeMessage(maxSize: number) {
    return this._emh.invalidMaxFileSize(maxSize);
  }

  getInvalidFileExtensionMessage() {
    return this._emh.getInvalidFileExtensionMessage(this.uploadDialogConfig.allowedFileExtensions);
  }

  onMediaRemoved(event) {
    const index = this.formGroup.value.media.indexOf(event.file);
    if (index > -1) {
      this.formGroup.value.media.splice(index, 1);
    }
  }
/*
  save() {
    const workers = [];
    if (this.formGroup.value.media) {
      this.uploading = true;
      for (const medium of this.formGroup.value.media) {
        const promise = this._ms.addMediaFile(medium, this.uploadDialogConfig.mediaType, this.restaurant);
        workers.push(promise);
      }
    }
    Promise.all(workers).then(_ => {
      this._dialogRef.close();
      this.uploading = false;
    });
  }
*/
  onAbort() {
    this.formGroup.get('media').reset();
  }

  async onUpload() {
    const name = this.formGroup.get('name').value || null;
    const file = this.formGroup.get('file').value;
    this._dialogRef.disableClose = true;
    this.uploading = true;

    if (!(file instanceof File)) {
      console.error('File is not valid. Aborting...');
      this.uploading = false;
      return;
    }
    await this._ms.addMenuFile(file, this.restaurant, name).catch(error => {
      console.error(error);
      this.uploading = false;
    });
    this.uploading = false;
    this._dialogRef.close(true);
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleInputChange(e) {
    this.resetErrorStates();
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (this.hasValidFileSize(file) && this.hasValidFileExtension(file)) {
      this.formGroup.get('file').setValue(file);
    }
  }

  resetErrorStates() {
    this.error.fileTooLarge = false;
    this.error.fileExtension = false;
  }

  hasValidFileSize(file: any): boolean {
    this.error.fileTooLarge = file.size > this.uploadDialogConfig.maxFileSize;
    return !this.error.fileTooLarge;
  }

  hasValidFileExtension(file: any) {
    const extension = file.name.split('.').pop();
    const allowedExtensions = this.uploadDialogConfig.allowedFileExtensions;
    if (!extension || !extension.length || !allowedExtensions.some(x => x === extension)) {
      this.error.fileExtension = true;
    }
    return !this.error.fileExtension;
  }

  removeFile(event: Event) {
    event.stopPropagation();
    this.file.nativeElement.value = null;
    this.formGroup.get('file').setValue(null);
  }
}
