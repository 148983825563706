import { environment } from '../../environments/environment';
import { ErrorMessageService } from '../services/error-message.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InputDialog } from '../input-dialog/input-dialog.component';
import { Unsubscribe } from '@firebase/util';
import { ValidatorService } from '../services/validator.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services/user.service';
import { RestaurantService } from '../services/restaurant.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit, OnDestroy {
  public version: string = environment.VERSION;
  public loginForm: FormGroup;
  isLoggingIn = false;
  visibilityIcon = 'visibility_off';
  passwordType = 'password';
  authUnsubscriber: Unsubscribe;

  constructor(
    private _ts: TranslateService,
    private _rs: RestaurantService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _router: Router,
    private _userService: UserService,
    private _afAuth: AngularFireAuth,
    private _formBuilder: FormBuilder,
    public emh: ErrorMessageService,
    private _vh: ValidatorService,
    private _as: AlertService) {
    if (this._router.url === '/logout') {
      this._userService.logout().then(() => {
        this._rs.reset();
      }).catch(error => console.error('error: ', error));
    }
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      email: ['', this._vh.emailValidator()],
      password: ['', [Validators.required, this._vh.whiteSpaceValidator()]]
    });

    this.authUnsubscriber = this._afAuth.auth.onAuthStateChanged(user => {
      this.isLoggingIn = !!(user);
    });
  }

  onSubmit() {
    this.isLoggingIn = true;
    const email = this.loginForm.controls.email.value;
    const password = this.loginForm.controls.password.value;
    this._snackBar.open(this._ts.instant('check_login_credentials'), null);
    this._userService.loginWith(email, password).then(() => {
      this._snackBar.open(this._ts.instant('welcome_after_login'), null, { duration: 2000 });
      this.isLoggingIn = false;
    }).catch(error => {
      this._as.open(AlertService.getMessageKey(error.code, 'error_occurred_try_later'), { duration: 'normal', severity: 'danger' });
      this.isLoggingIn = false;
    });
  }

  onResetPassword() {
    const inputDialogRef = this._dialog.open(InputDialog, { data: { title: this._ts.instant('reset_password'), message: this._ts.instant('please_enter_email'), placeholder: 'mail@example.com', input: this.loginForm.controls.email.value, validators: [Validators.required, this._vh.emailValidator(), this._vh.whiteSpaceValidator()] } });
    inputDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._afAuth.auth.sendPasswordResetEmail(result).then(() => {
          this._snackBar.open(this._ts.instant('sent_email_further_infos'), this._ts.instant('ok'), { duration: 3000 });
        }).catch(error => {
          this._snackBar.open(error + ' - ' + this._ts.instant('please_try_again'), this._ts.instant('ok'), { duration: 9000 });
        });
      }
    });
  }

  passwordVisibility() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
      this.visibilityIcon = 'visibility';
    } else {
      this.passwordType = 'password';
      this.visibilityIcon = 'visibility_off';
    }
  }
  ngOnDestroy() {
    if (this.authUnsubscriber) {
      this.authUnsubscriber();
    }
  }

}
