import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Restaurant} from '../../models/model';
import { AccessRole } from '../../models/enums';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PartnerGuideDialogComponent } from '../../partner/partner-guide-dialog/partner-guide-dialog.component';

@Component({
  selector: 'app-profile-progress',
  templateUrl: './profile-progress.component.html',
  styleUrls: ['./profile-progress.component.less']
})
export class ProfileProgressComponent implements OnInit, OnChanges {
  @Input() restaurant: Restaurant;
  @Input() displayHints: boolean = true;

  weights: ProfileProgressWeights = ProfileProgressStatistic.WEIGHTS;
  progressCircleSize =  100;
  radius = 46;
  // between 0.0 and 1.0
  progress = 0.0;
  strokeDasharray = Math.PI * (this.radius * 2);
  mostImportantActions: any[];
  constructor(
    private _ts: TranslateService,
    private router: Router,
    private _us: UserService,
    private _dialog: MatDialog) { }

  ngOnInit() {
    if (!this._us.profileProgressWeights) {
      this._us.loadProfileProgress().then(weights => {
        this._us.profileProgressWeights = weights;
        this.weights = weights;
        this.build();
        // auto open guide
        if(
          this.progress < 1
          && !this.restaurant.notShowGuide
          && !this._us.user.hasRole(AccessRole.admin) 
          && !this._us.user.hasRole(AccessRole.salesman)
        ){
          this.onStartGuide();
        }
      }).catch(error => {
        this.weights = ProfileProgressStatistic.WEIGHTS;
        this.build();
        console.warn('Could not load the data for progress weights. Default data will be used. ');
      });
    } else {
      this.weights = ProfileProgressStatistic.WEIGHTS;
    }
    // Open Guide on first Login
    if(this._us.user && !this._us.user.lastLogin) {
      this.onStartGuide();
    }
  }

  build() {
    if (this.restaurant && this.weights) {
      const statistic = new ProfileProgressStatistic(this.restaurant, this.weights);
      this.progress = this.restaurant.profileProgress;
      this.buildMostImportantActions(statistic);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.restaurant) {
      if (changes.restaurant.currentValue) {
        this.build();
      }
    }
  }

  buildMostImportantActions(statistic: ProfileProgressStatistic) {
    this.mostImportantActions = [];
    for (const item of statistic.missingItems.slice(0, 3)) {
      this.mostImportantActions.push({id: item, i18n: this._ts.instant(`profile_complete_${item}`)});
    }
  }

  onItemClick(item: any) {
    switch (item.id) {
      case 'name':
        this.router.navigate(['/partner/restaurants/general']);
        break;
      case 'logo':
        this.router.navigate(['/partner/restaurants/general']);
        break;
      case 'mail':
        this.router.navigate(['/partner/restaurants/general']);
        break;
      case 'phoneNumber':
        this.router.navigate(['/partner/restaurants/general']);
        break;
      case 'tags':
        this.router.navigate(['/partner/restaurants/general']);
        break;
      case 'description':
        this.router.navigate(['/partner/restaurants/general']);
        break;
      case 'details':
        this.router.navigate(['/partner/restaurants/details']);
        break;
      case 'openingHours':
        this.router.navigate(['/partner/restaurants/openinghours']);
        break;
      case 'lunchtimes':
        this.router.navigate(['/partner/restaurants/openinghours']);
        break;
      case 'menu':
        this.router.navigate(['/partner/restaurants/menu-files']);
        break;
      case 'imagesOne':
        this.router.navigate(['/partner/restaurants/media']);
        break;
      case 'imagesMore':
        this.router.navigate(['/partner/restaurants/media']);
        break;
      case 'availableMeals':
        this.router.navigate(['/partner/meals']);
        break;
    }
  }

  get strokeDasharrayBar(): string {
    return `${this.strokeDasharray * this.progress} ${this.strokeDasharray}`;
  }

  get percentage(): number {
    return Math.round(this.progress * 100);
  }

  get getCXY(): number {
    return Math.round(this.progressCircleSize / 2);
  }

  onStartGuide() {
    this._dialog.open(PartnerGuideDialogComponent, {
      data: {}
    });
  }

}

/*
  TODO: Duplicate class ProfileProgressStatistic. The also class exists in back end path web-lunchnowpartner/functions/src/model.ts
  --> check how to share classes between front and back end
*/
class ProfileProgressStatistic {
  weights: ProfileProgressWeights;
  points: number;
  maxPointsSum: number;
  missingItems: string[];
  restaurant: Restaurant;
  constructor(restaurant: Restaurant, weights: ProfileProgressWeights) {
    this.points = 0;
    this.weights = weights;
    this.missingItems = [];
    this.restaurant = restaurant;
    this.maxPointsSum = Object.values(this.weights).reduce((max: number, obj: any) => {
      return max + obj.points;
    }, 0);
    this.build();
  }
  private build() {
    for (const item of Object.keys(this.weights)) {
      const points = this[`${item}Points`];
      if (points !== undefined) {
        this.points += points;
        if (points === 0) {
          this.addMissingItem(item);
        }
      } else {
        console.warn('ProfileProgressStatistic.build() No method to compute the points for item ' + item);
      }
    }
    this.missingItems.sort((item1: string, item2: string) => this.weights[item2].priority - this.weights[item1].priority);
  }
  private addMissingItem(item: string) {
    // condition to avoid to have two missing items: imagesMore and imagesOne, if no images are uploaded
    if (!((!Array.isArray(this.restaurant.media) || this.restaurant.media.length === 0) && item === 'imagesMore')
        && !((Array.isArray(this.restaurant.media) && this.restaurant.media.length === 1) && item === 'imagesOne')) {
      this.missingItems.push(item);
    }
  }
  private getPoints(obj: any, prop: string, weight: number): number {
    if (!obj) {
      return 0;
    }
    return obj[prop] !== undefined && obj[prop] !== null ? weight : 0;
  }
  private get namePoints(): number {
    return this.getPoints(this.restaurant, 'name', this.weights.name.points);
  }
  private get logoPoints(): number {
    return this.getPoints(this.restaurant, 'logo', this.weights.logo.points);
  }
  private get mailPoints(): number {
    return this.getPoints(this.restaurant.contact, 'email', this.weights.mail.points);
  }
  private get phoneNumberPoints(): number {
    return this.getPoints(this.restaurant.contact, 'phoneNumber', this.weights.phoneNumber.points);
  }
  private get descriptionPoints(): number {
    return this.getPoints(this.restaurant, 'description', this.weights.description.points);
  }
  private get detailsPoints(): number {
    if (!this.restaurant.details) {
      return 0;
    }
    return Object.keys(this.restaurant.details).length > 0 ? this.weights.details.points : 0;
  }
  private get tagsPoints(): number {
    if (!Array.isArray(this.restaurant.tags)) {
      return 0;
    }
    return this.restaurant.tags.length > 0 ? this.weights.tags.points : 0;
  }
  private get openingHoursPoints(): number {
    if (!this.restaurant.openingHours) {
      return 0;
    }
    return Object.keys(this.restaurant.openingHours).length > 0 ? this.weights.openingHours.points : 0;
  }
  private get lunchtimesPoints(): number {
    if (!this.restaurant.lunchTimes) {
      return 0;
    }
    return Object.keys(this.restaurant.lunchTimes).length > 0 ? this.weights.lunchtimes.points : 0;
  }
  private get menuPoints(): number {
    if (!Array.isArray(this.restaurant.menuFiles)) {
      return 0;
    }
    return this.restaurant.menuFiles.length > 0 ? this.weights.menu.points : 0;
  }
  private get imagesOnePoints(): number {
    if (!Array.isArray(this.restaurant.media)) {
      return 0;
    }
    return this.restaurant.media.length > 0 ? this.weights.imagesOne.points : 0;
  }
  private get imagesMorePoints(): number {
    if (!Array.isArray(this.restaurant.media)) {
      return 0;
    }
    return this.restaurant.media.length > 1 ? this.weights.imagesMore.points : 0;
  }
  private get availableMealsPoints(): number {
    return this.restaurant.offersAvailable ? this.weights.availableMeals.points : 0;
  }
  public get progress(): number {
    return this.points / this.maxPointsSum;
  }

  static readonly WEIGHTS = {
    name: {priority: 100, points: 10},
    logo : {priority: 92, points: 10},
    mail: {priority: 90, points: 10},
    phoneNumber: {priority: 91, points: 10},
    tags: {priority: 70, points: 10},
    description: {priority: 40, points: 10},
    details: {priority: 71, points: 10},
    openingHours: {priority: 90, points: 10},
    lunchtimes: {priority: 68, points: 10},
    menu: {priority: 49, points: 10},
    imagesOne: {priority: 89, points: 10},
    imagesMore: {priority: 69, points: 10},
    availableMeals: {priority: 72, points: 10}
  };
}

/*
  TODO: Duplicate interface ProfileProgressWeights. The also class exists in back end path web-lunchnowpartner/functions/src/model.ts
  --> check how to share classes between front and back end
*/
export interface ProfileProgressWeights {
  [id: string]: {
    priority: number;
    points: number;
  };
}
