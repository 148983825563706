import {Component} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import * as moment from 'moment-timezone';
import {ICellRendererParams} from 'ag-grid-community';
import {firestore} from 'firebase';

@Component({
  selector: 'app-cell-renderer-formated-date',
  templateUrl: './cell-renderer-formated-date.component.html',
  styleUrls: ['./cell-renderer-formated-date.component.less']
})
export class CellRendererFormatedDateComponent implements AgRendererComponent {

  formattedDate: string;

  refresh(params: any): boolean {
    if (params && params.value) {
      this.parseDate(params.value);
    }
    return true;
  }

  agInit(params: ICellRendererParams): void {
    if (params && params.value) {
      this.parseDate(params.value);
    }
  }

  parseDate(date: firestore.Timestamp) {
    try {
      this.formattedDate = moment(date.toDate()).format('lll');
    } catch {
      throw new Error(`Unable to parse ${date}`);
    }
  }
}
