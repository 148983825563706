import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Language} from '../../models/model';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-language-selector-dialog',
  templateUrl: './language-selector-dialog.component.html',
  styleUrls: ['./language-selector-dialog.component.less']
})
export class LanguageSelectorDialogComponent implements OnInit {
  language: Language;
  systemLanguages: Array<Language>;
  loading = true;

  constructor(public dialogRef: MatDialogRef<LanguageSelectorDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any, private _lh: LanguageService) {
    if (data && data.language) {
      this.language = data.language;
    }
  }

  ngOnInit() {
    this._lh.getSystemLanguages().then(languages => {
      this.systemLanguages = languages;
      this.loading = false;
    });
  }

  onSubmit() {
    this.dialogRef.close(this.language);
  }

  onValueChange(e) {
    if (e && e.value instanceof Language) {
      this.language = e.value;
    }
  }
}
