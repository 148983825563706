import {FormBuilder, FormGroup} from '@angular/forms/';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-text-edit-dialog',
  templateUrl: './text-edit-dialog.component.html',
  styleUrls: ['./text-edit-dialog.component.less']
})
export class TextEditDialogComponent implements OnInit {
  formGroup: FormGroup;
  okButton: string;
  constructor(public dialogRef: MatDialogRef<TextEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: {
                            text: string,
                            okButton?: string,
                            validators?: any},
              private _formBuilder: FormBuilder,
              private _ts: TranslateService) {

    this.formGroup = this._formBuilder.group({
      text: data.text
    });
    this.okButton = data.okButton ? data.okButton : _ts.instant('ok');
  }

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close(this.formGroup.value.text.trim());
  }

  cancel() {
    this.dialogRef.close();
  }
}
