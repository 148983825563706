import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { LnException, PartnerMail, Restaurant, User } from '../models/model';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { AccessRole } from '../models/enums';
import { environment } from '../../environments/environment';

const USER_COLLECTION = 'users';

@Injectable()
export class PartnerService extends HelperService {

  constructor(_aFs: AngularFirestore, private _userService: UserService) {
    super(_aFs);
  }
  async sendPartnerMail(restaurant: Restaurant, subject: string) {
    try {
      const partner: User = this._userService.user.isPartner(restaurant) ? this._userService.user : null;
      if (partner) {
        const partnerMail = PartnerMail.parse({
          restaurantName: restaurant.name,
          subject: subject,
          userAgent: window.navigator.userAgent,
          contact: restaurant.contact,
          contactName: partner.displayName,
          recipient: environment.supportMailRecipient
        });
        await this._aFs.collection('partner_mails').add(partnerMail.data());
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  public getPartners(): Promise<User[]> {
    return this._aFs.firestore.collection(USER_COLLECTION).where('role', '==', AccessRole.user).get()
      .then((querySnapshot) => {
        return super.mapQuerySnapshot<User>(querySnapshot, User);
      });
  }
  public getPartners$(): Observable<User[]> {
    const collection: AngularFirestoreCollection<User> = this._aFs.collection<User>(USER_COLLECTION, ref => {
      return ref.where('role', '==', AccessRole.user);
    });
    return super.mapSnapshotChanges$(collection, User);
  }

  // public getNewCustomerId(collectionName:string): Promise<number> {
  //   return new Promise<number>((resolve, reject) => {
  //     return this._aFs.firestore.collection(collectionName).limit(1).orderBy('customerId', 'desc').get().then(querySnapshot => {
  //       super.mapQuerySnapshot<User>(querySnapshot, User).then(docData => {
  //         if (docData.length === 0 || !(typeof docData[0].customerId === 'number') || isNaN(docData[0].customerId) || docData[0].customerId <= 0) {
  //           reject('Error: Not able to generate a new customer id.');
  //           return;
  //         }
  //         resolve(docData[0].customerId + 1);
  //       });
  //     });
  //   });
  // }

  async createPartner(partner: User): Promise<User> {
    if (partner.email.length <= 0) {
      throw Error('No email provided. Can not create account. Aborting...');
    }
    const user: any = await this._userService.createUser(partner.email, partner.firstName + ' ' + partner.lastName);
    if (!user || !user.uid) {
      throw new LnException('Creation of user failed.', LnException.ERROR_CODE_CREATION_FAILED);
    }
    const ref = this._aFs.firestore.collection(USER_COLLECTION).doc(user.uid);
    await ref.set(partner.data());
    partner.ref = ref;
    return partner;
  }

  /*
  async linkRestaurants(restaurantNames: RestaurantName[], user: User) {
    const restaurants: any = {};
    restaurantNames.forEach(restaurantName => {
      user.restaurants[restaurantName.restaurant.id] = {
        name: restaurantName.name,
        restaurant: new LunchNowReference<Restaurant>(restaurantName.restaurant.ref, Restaurant),
        roles: [AccessRole.partner]
      };
    });
    await user.update({restaurants: restaurants});
  }
*/
  // TODO to discuss
  async disablePartner(user: User) {
    if (!user.ref) {
      throw Error('No database reference path provided: Property .ref is not set.');
    }
    // Deactivate linked restaurants
    if (user.restaurants) {
      const promiseAll = [];
      for (const resId of Object.keys(user.restaurants)) {
        // set active = false only for restaurants where user is parner
        if (user.isPartner(resId)) {
          const restaurant = await user.restaurants[resId].restaurant.get();
          let active: boolean = restaurant.active;
          active = false;
          promiseAll.push(restaurant.update({ active: active }));
        }
      }
      if (promiseAll.length > 0) {
        await Promise.all(promiseAll);
      }
    }
    // delete partner
    await user.delete();
    // Disable linked user account
    await this._userService.disableUser(user.id);
  }
}
