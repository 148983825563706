import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {IEmptyPageButton} from '../../models/model';

@Component({
  selector: 'app-empty-page-placeholder',
  templateUrl: './empty-page-placeholder.component.html',
  styleUrls: ['./empty-page-placeholder.component.less'],
  animations: [
    trigger('fadeInElement', [
      state('initial', style({
        opacity: 0
      })),
      state('final', style({
        opacity: 1,
      })),
      transition('initial=>final', animate('2000ms')),
    ])],
})

export class EmptyPagePlaceholderComponent implements AfterViewChecked, OnInit {

  @Input() text: string;
  @Input() type: string;
  @Input() button: IEmptyPageButton;
  onClick: () => any;

  textStyleState = 'initial';

  constructor(private _cdRef: ChangeDetectorRef) {
    if (!this.type) {
      this.type = 'default';
    }
  }

  ngOnInit(): void {
    if (this.button) {
      this.onClick = this.button.onClick;
    }
  }

  ngAfterViewChecked() {
    this.textStyleState = 'final';
    // To trigger a smooth fade-in, we are knowingly changing an expression after the
    // view has been checked. To avoid an ExpressionChangedAfterItHasBeenCheckedError,
    // we explicitly run change detection right after our expression-change.
    this._cdRef.detectChanges();
  }
}
