import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.less']
})
export class PageNotFoundComponent {
  public version: string = environment.VERSION;
  constructor() {
  }
}
