import {Component, Input, OnInit} from '@angular/core';
import {LnCmsElement} from '../../models/model';


@Component({
  selector: 'app-ln-cms-pdf',
  template: `
    <a class="pdf" [href]="element?.url" target="_blank"  fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
      <img class="small" src="/assets/images/file-pdf.svg"/>
      <div fxLayout="column" fxLayoutAlign="space-between start">
        {{element?.value}}
        <div class="muted">{{element?.size}}</div>
      </div>
    </a>`,
  styleUrls: ['../ln-cms-elements.component.less']
})
export class LnCmsPdfComponent implements OnInit {
  @Input() element: LnCmsElement;
  constructor() {}
  ngOnInit(): void {
  }
}
