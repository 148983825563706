import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Injectable()
export class ValidatorService {

  static URI_SCHEME_HTTP = 'http://';
  static URI_SCHEME_HTTPS = 'https://';
  static PHONE_FILTER_REGEX = /[^\w+.%&§"!?]/g;

  constructor() { }

  // Returns invalid if the input string is not a valid email Address.
  emailValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const reg = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i;
      const valid = reg.test(control.value);
      return valid ? null : { 'email': { value: valid } };
    };
  }

  // Returns invalid if the input string consists ONYLY of whitespaces.
  whiteSpaceValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const valid = control.value.trim ? control.value.trim() : control.value;
        return valid ? null : { 'required': { value: valid } };
      }
    };
  }

  urlValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const reg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,24}(:[0-9]{1,5})?(\/.*)?$/gm;
        const valid = reg.test(control.value.trim());
        return valid ? null : { 'url-invalid': { value: valid } };
      }
    };
  }
  noEmojiValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const regEx = /(\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gm;
        const valid = !regEx.test(control.value.trim());
        return valid ? null : { 'invalid': { value: valid } };
      }
    };
  }

  /*

    An Example for how phoneNumberValidator() works

    Input: ++040/12ef%()-3456
    filteredValue = ++04012ef3456
    valid = false -> only one '+', no chars and no '%' (not filtered because listed in PHONE_FILTER_REGEX) are allowed (refer to reg)

    Input: 040/1-2-345()67
    filteredValue = 0401234567
    valid = true -> spezial chars are filtered (refer to PHONE_FILTER_REGEX)

 */
  phoneNumberValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if (control.value) {
        const phoneUtil = PhoneNumberUtil.getInstance();
        try {
          const valid = phoneUtil.isValidNumber(phoneUtil.parse(control.value, 'de'));
          return valid ? null : { 'pattern': { value: valid } };
        } catch (e) {
          return { 'pattern': { value: false } };
        }
      }
    };
  }

  appendValidURIScheme(url: string) {
    if (url) {
      url = url.trim();
      if (!url.startsWith(ValidatorService.URI_SCHEME_HTTP) && !url.startsWith(ValidatorService.URI_SCHEME_HTTPS)) {
        url = ValidatorService.URI_SCHEME_HTTP + url;
      }
      return url;
    }
  }
}
