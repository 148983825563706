import { MultipleDatePickerComponent } from '../../multiple-date-picker';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';
import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'app-partner-meal-availability',
  templateUrl: './partner-meal-availability.component.html',
  styleUrls: ['./partner-meal-availability.component.less']
})
export class PartnerMealAvailabilityComponent implements OnInit, OnChanges {

  today: Moment;
  @Input() selectedDates: Array<Moment> = [];
  @Input() weekDaysOff: Array<number> = [];
  @Input() editable: boolean;
  @Input() disableDaysBefore?: Moment;
  @Input() disableDaysAfter?: Moment;
  @Input() highlightDays?: { date: Moment, title: string, css: 'holiday', selectable: true }[];

  @ViewChild('datePicker') datePicker: MultipleDatePickerComponent;
  constructor() {
    this.today = moment().startOf('day');
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.datePicker.generate();
  }

  public generate() {
    this.datePicker.projectScope = this.selectedDates;
    this.datePicker.generate();
  }

}
