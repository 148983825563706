import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HelperService } from './helper.service';
import { FirebaseApp } from '@angular/fire';
import { Medium, Restaurant } from '../models/model';
import { firestore } from 'firebase';
import { UUID } from 'angular2-uuid';
import { HttpClient, HttpParams } from '@angular/common/http';


import { environment } from '../../environments/environment';


@Injectable()
export class MediaService extends HelperService {

  STORAGE_REFERENCE = {
    'logo': this._firebaseApp.storage().ref('logos'),
    'image': this._firebaseApp.storage().ref('photos'),
    'menu': this._firebaseApp.storage().ref('menus')
  };

  constructor(_aFs: AngularFirestore, private _firebaseApp: FirebaseApp, private _http: HttpClient) {
    super(_aFs);
  }

  private removeFromStorage(media: Medium[]) {
    for (const medium of media) {
      if (medium.storageRef && medium.storageRef.length) {
        const storageRef = this._firebaseApp.storage().ref(medium.storageRef);
        // the files are remove silently, in case of error you can handle it accordingly
        storageRef.delete().catch(error => {
        });
      }
    }
  }
  async addToStorage(file: any, withType: 'image' | 'video' | 'logo' | 'menu' = 'image', name?: string) {
    const data = {
      type: withType === 'logo' ? 'image' : withType,
      name: name || file.name || null,
      dateCreated: firestore.Timestamp.now(),
      storageRef: null,
      storageUrl: null,
      url: file.url || null,
      youtubeId: file.youtubeId || null,
    };
    if (this.STORAGE_REFERENCE[withType]) {
      const ref: firebase.storage.Reference = this.STORAGE_REFERENCE[withType].child(UUID.UUID());
      // add caching control for every image - 1 year
      const snapshot = await ref.put(file, { cacheControl: 'public,max-age=31536000' });
      data.storageUrl = await ref.getDownloadURL();
      //  data.url = environment.production  ? `${environment.mediaApiURL}/${withType}/${snapshot.ref.name}` : data.storageUrl;
      data.url = `${environment.mediaApiURL}/${withType}/${snapshot.ref.name}`;
      data.storageRef = snapshot.ref.fullPath;
    }
    return new Medium(data);
  }


  async addDocsToMangoPay(file: any, restaurantId: string) {
    const url = `${environment.paymentApiURL}/createkyc/${restaurantId}`;
    return this._http.post(url, file).subscribe(response => {
      console.error();
    });
  }

  public addMediaFiles(media: Medium[], toRestaurant: Restaurant): Promise<void> {
    if (media && media.length > 0) {
      return super.arrayUnion(toRestaurant, 'media', media);
    }
    return Promise.resolve();
  }
  /*
  the remove order is here important, first remove from firestore, then from storage
  if we delete from storage first, and the firestore update fails, then we have
  entry with empty reference to storage. Bad for the UX.
  Now if the removeFromStorage fails, the user doesnt get it. It could be implemented a cron job
  to remove such lonely files.
 */
  public removeMediaFiles(media: Medium[], fromRestaurant: Restaurant): Promise<void> {
    if (media && media.length > 0) {
      return super.arrayRemove(fromRestaurant, 'media', media).then(() => {
        this.removeFromStorage(media);
      });
    }
    return Promise.resolve();
  }
  public addMenuFile(file: any, toRestaurant: Restaurant, name?: string): Promise<void> {
    if (file) {
      return this.addToStorage(file, 'menu', name).then((medium: Medium) => {
        return super.arrayUnion(toRestaurant, 'menuFiles', [medium]);
      });
    }
    return Promise.resolve();
  }
  public addDocsFile(file: any, toRestaurant: Restaurant, name?: string): Promise<void> {
    if (file) {
      return this.addToStorage(file, 'menu', name).then((medium: Medium) => {
        return super.arrayUnion(toRestaurant, 'menuFiles', [medium]);
      });
    }
    return Promise.resolve();
  }
  /*
  the remove order is here important, see removeMediaFiles() method
   */
  public removeMenuFiles(media: Medium[], fromRestaurant: Restaurant): Promise<void> {
    if (media && media.length > 0) {
      return super.arrayRemove(fromRestaurant, 'menuFiles', media).then(() => {
        this.removeFromStorage(media);
      });
    }
    return Promise.resolve();
  }
  /*
  the remove order is here important, see removeMediaFiles() method
   */
  public removeLogo(fromRestaurant: Restaurant): Promise<void> {
    const logoCopy: Medium = new Medium(fromRestaurant.logo.data());
    return fromRestaurant.ref.update({
      logo: firestore.FieldValue.delete()
    }).then(() => {
      fromRestaurant.logo = null;
      this.removeFromStorage([logoCopy]);
    });
  }
  // returns thumbnail pic url of given youtube-video id or url
  getYouTubeThumbnailUrl(input: string) {
    let videoId = input.trim();
    let thumbnailUrl = '';
    if (!this.isValidYouTubeId(videoId)) {
      videoId = this.extractYouTubeId(videoId);
    }
    if (this.isValidYouTubeId(videoId)) {
      thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }
    return thumbnailUrl;
  }

  extractYouTubeId(url: string) {
    url = url.trim();
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && this.isValidYouTubeId(match[7])) {
      return match[7];
    } else {
      return undefined;
    }
  }

  isValidYouTubeId(id: string): boolean {
    id = id.trim();
    if (id && id.length === 11 && id.match(/[a-zA-Z0-9_-]{11}/)) {
      return true;
    }
    return false;
  }
}
