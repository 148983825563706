import {MatDialogRef} from '@angular/material';
import {Component, OnInit} from '@angular/core';
import {IDatePickerConfig} from 'ng2-date-picker';
import * as moment from 'moment-timezone';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TimesTimeSpan} from '../models/model';

@Component({
  selector: 'app-time-span-dialog',
  templateUrl: './time-span-dialog.component.html',
  styleUrls: ['./time-span-dialog.component.less']
})
export class TimeSpanDialogComponent implements OnInit {

  timePickerConfig: IDatePickerConfig;
  formGroup: FormGroup;
  timeSpan: TimesTimeSpan;
  moment = moment;

  weekdays: String[];
  selectedWeekdays = new Set();


  constructor(private _formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<TimeSpanDialogComponent>) {

    this.weekdays = moment.weekdaysMin();
    this.weekdays.push(this.weekdays.shift());
    this.formGroup = this._formBuilder.group({
      start: moment('2000-01-01T10:00:00'),
      end: moment('2000-01-01T22:00:00'),
    });
    this.timePickerConfig = {
      format: 'HH:mm',
      drops: 'down',
      opens: 'left',
      min: moment('2000-01-01T00:00:00'),
      max: moment('2000-01-02T00:00:00'),
      hours24Format: 'HH',
      minutesInterval: 15,
      showTwentyFourHours: true
    };
  }

  ngOnInit() {
  }

  onSubmit() {
    const result = {};
    this.selectedWeekdays.forEach(weekday => {
      const weekdayNumber = moment.weekdaysMin().indexOf(weekday);
      result[weekdayNumber] = {
        start: this.formGroup.value.start.format('HH:mm'),
        end: this.formGroup.value.end.format('HH:mm')
      };
    });
    this._dialogRef.close(result);
  }

  onCancel() {
    this._dialogRef.close();
  }

  toggle(chip) {
    if (this.selectedWeekdays.has(chip.target.textContent)) {
      this.selectedWeekdays.delete(chip.target.textContent);
    } else {
      this.selectedWeekdays.add(chip.target.textContent);
    }
  }

}
