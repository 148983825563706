import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl} from '@angular/forms';
import {ErrorMessageService} from '../../services/error-message.service';
import {ICountry, ILanguage, Language} from '../../models/model';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-language-management-edit-dialog',
  templateUrl: './language-management-edit-dialog.component.html',
  styleUrls: ['./language-management-edit-dialog.component.less']
})
export class LanguageManagementEditDialogComponent implements OnInit {
  selectedLanguage: FormControl = new FormControl();
  selectedCountry: FormControl = new FormControl();
  availableLanguages: Array<ILanguage>;
  countries: Array<ICountry>;
  language: Language;
  filteredLanguages;
  filteredCountries;

  constructor(public _dialogRef: MatDialogRef<LanguageManagementEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) _language,
    private _lh: LanguageService,
    public emh: ErrorMessageService) {
    if (_language) {
      this.language = _language.data;
    } else {
      this.language = new Language();
    }
  }

  ngOnInit() {
    this.selectedLanguage.valueChanges.subscribe(text => {
      this.filteredLanguages = this.filter(text, this.availableLanguages);
    });
    this.selectedCountry.valueChanges.subscribe(text => {
      this.filteredCountries = this.filter(text, this.countries);
    });
    this.availableLanguages = this._lh.getAvailableLanguages();
    this.countries = this._lh.getCountries();
  }

  filter(val: string, list: any) {
    if (val && val.length > 0) {
      return list.filter(listElement => {
        return (listElement.alpha2.toLowerCase().includes(val.toLowerCase())
          || listElement.displayName.toLowerCase().includes(val.toLowerCase()));
      });
    } else {
      return list;
    }
  }

  autoCompleteDisplayFn(obj: any): string | undefined {
    if (obj) {
      return `${obj.displayName} (${obj.alpha2})`;
    }
  }

  onSelectLanguage() {
    this.language.parse(this.selectedLanguage.value);
  }

  onSelectCountry() {
    this.language.flag = this.selectedCountry.value.alpha2;
  }

  onSave() {
    this._lh.saveLanguage(this.language).then(_ => {
      this._dialogRef.close(true);
    });
  }
}
