import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HelperService } from './helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Additive, Allergen, Attribute, Cuisine, TasteTag, PublicHoliday, MealType } from '../models/model';
import { Observable } from 'rxjs';

@Injectable()
export class PropertyService extends HelperService {
  locale: string;
  constructor(_aFs: AngularFirestore, @Inject(LOCALE_ID) _locale: string) {
    super(_aFs);
    this.locale = _locale;
  }

  getAttributes$(): Observable<Attribute[]> {
    return super.mapSnapshotChanges$(this._aFs.collection('attributes', ref => {
      return ref.orderBy('name', 'asc');
    }), Attribute);
  }

  getAdditives$(): Observable<Additive[]> {
    return super.mapSnapshotChanges$(this._aFs.collection('additives', ref => {
      return ref.orderBy('name', 'asc');
    }), Additive);
  }


  getCuisines$(): Observable<Cuisine[]> {
    return super.mapSnapshotChanges$(this._aFs.collection('cuisines', ref => {
      return ref.orderBy('name', 'asc');
    }), Cuisine);
  }

  getAllergens$(): Observable<Allergen[]> {
    return super.mapSnapshotChanges$(this._aFs.collection('allergens', ref => {
      return ref.orderBy('name', 'asc');
    }), Allergen);
  }

  getTags$(): Observable<TasteTag[]> {
    return super.mapSnapshotChanges$(this._aFs.collection('tags', ref => {
      return ref.orderBy('name', 'asc');
    }), TasteTag);
  }


  getTagTranslation(tag: TasteTag | MealType): string {
    if (this.locale.length && tag.translations && tag.translations[this.locale].length) {
      return tag.translations[this.locale];
    } else {
      return tag.name;
    }
  }
  public getPublicHolidays(): Promise<PublicHoliday[]> {
    return super.getCollection<PublicHoliday>('public_holidays', PublicHoliday, { fieldPath: 'date', direction: 'asc' });
  }

  async save(collection: string, item: any): Promise<void> {
    await this._aFs.firestore.collection(collection).add(item);
  }

  /**
   * Checks if the items name or acronym already exists in the database. Returns corresponding
   * translation key if duplicate is found.
   * @param item - item to be created
   * @param categoryItems - list of properties (allergens, attributes, additives cuisines...)
   */
  duplicatePropertyExists(item, categoryItems) {
    let message;
    categoryItems.forEach(element => {
      if (element.name && element.name.toLowerCase().trim() === item.name.toLowerCase().trim()) {
        message = 'name_already_exists';
      }
      if (item.acronym && element.acronym.toLowerCase().trim() === item.acronym.toLowerCase().trim()) {
        message = 'acronym_already_exists';
      }
    });
    return message;
  }
}
