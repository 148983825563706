import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms/';
import { Component, Inject, OnInit } from '@angular/core';
import { PrintTemplate, User } from '../../models/model';
import { PartnerService } from '../../services/partner.service';

@Component({
  selector: 'app-print-template-detail-dialog',
  templateUrl: './print-template-detail-dialog.component.html',
  styleUrls: ['./print-template-detail-dialog.component.less']
})
export class PrintTemplateDetailDialogComponent implements OnInit {

  formGroup: FormGroup;
  template: PrintTemplate;
  isLoading = false;
  users: User[];

  filteredUsers: User[];
  selectedUser: FormControl = new FormControl();
  currentUsers = new Set<User>();

  constructor(
    private _dialogRef: MatDialogRef<PrintTemplateDetailDialogComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) template: PrintTemplate,
    private _ps: PartnerService) {

    this.template = template;
    this.formGroup = this._formBuilder.group({
      name: [this.template.name, [Validators.required]],
      previewUrl: [this.template.previewUrl],
      templateName: new FormControl({ value: this.template.templateName, disabled: true }, Validators.required),
      enabled: new FormControl({ value: this.template.enabled, disabled: !(this.template.templateName) })
    });
  }

  ngOnInit() {
    this.isLoading = true;

    this._ps.getPartners().then((result: User[]) => {
      this.users = result;
      this.filteredUsers = this.users;
      this.isLoading = false;
    });
    this.selectedUser.valueChanges.subscribe(text => {
      this.filteredUsers = this.filter(text);
    });

    if (this.template.users) {
      this.template.users.forEach(async userRef => {
        this.currentUsers.add(await userRef.get());
      });
    }
  }

  onAddUser() {
    if (this.selectedUser.value) {
      this.currentUsers.add(this.selectedUser.value);
    }
  }

  onDeleteUser(user: User) {
    this.currentUsers.delete(user);
  }

  filter(val: string): User[] {
    if (val && val.length > 0) {
      return this.users.filter(user => {
        if (user.email) {
          return user.email.toLowerCase().includes(val.toLowerCase());
        } else {
          return false;
        }
      });
    } else {
      return this.users;
    }
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.isLoading = true;
      const printTemplate: any = {
        ...this.formGroup.value,
        users: Array.from(this.currentUsers).map(user => user.ref)
      };
    
      this.template.update(printTemplate).then(() => {
        this.isLoading = false;
        this._dialogRef.close();
      }).catch(error => {
        console.error(error);
        // TODO better way to notificate the user
        if (error.error) {
          alert(JSON.stringify(error.error.message));
        } else {
          alert(JSON.stringify(error.message));
        }
        this.isLoading = false;
      });
    }
  }

  onCancel() {
    this._dialogRef.close();
  }

  displayFn(user?: User): string | undefined {
    if (user) {
      return user.email;
    }
  }
}
