import { DocumentReference } from '@firebase/firestore-types';
import { Inject, Injectable, LOCALE_ID, NgZone, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { User, RestaurantName } from '../models/model';
import { AngularFirestore } from '@angular/fire/firestore';
import { HelperService } from './helper.service';
import { LanguageService } from './language.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import * as firebase from 'firebase/app';
import { environment } from '../../environments/environment';
import { AccessRole } from '../models/enums';
import {Utils} from '../app.utils';


@Injectable()
export class UserService extends HelperService implements OnDestroy {
  user$ = new BehaviorSubject<User>(new User());
  userSubscription: Subscription;
  profileProgressWeights: any;
  initPathname: string;
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private _snackBar: MatSnackBar,
    private _afAuth: AngularFireAuth,
    _aFs: AngularFirestore,
    private _router: Router,
    private _ngZone: NgZone,
    private _ts: TranslateService,
    private _ls: LanguageService,
    private _http: HttpClient) {
    super(_aFs);
    this._afAuth.auth.languageCode = this._locale;
    this.initPathname = location.pathname;
    // console.log('UserService.constructor() ' + location.pathname);
    this.init();
  }
  init(): void {
    this._afAuth.auth.onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        this.logLastLogin(firebaseUser).catch(error => {
          console.error(error);
        });
        this.subscribeUser(firebaseUser);
      } else {
        this.resetUser();
      }
    }, error => {
      console.error(error);
      this.resetUser();
    });
  }
  public loadProfileProgress(): Promise<any> {
    return this._aFs.firestore.collection('system').doc('profile_progress').get()
        .then(snapshot => {
          if (snapshot.exists) {
            return snapshot.data();
          }
          return Promise.reject('No progress data exists.');
    });
  }
  public getUser$() {
    return this.user$;
  }

  public get user(): User {
    return this.user$.getValue();
  }

  private resetUser() {
    this.user$.next(new User());
    this.unsubscribeUser();
  }

  public isLoggedIn(): boolean {
    return this._afAuth.auth.currentUser != null;
  }

  public loginWith(email: string, password: string) {
    return this._afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  public logout(): Promise<void> {
    if (this.isLoggedIn()) {
      return this._afAuth.auth.signOut().then(() => {
        this.unsubscribeUser();
        return Promise.resolve();
      });
    }
    //  this.unsubscribeUser();
    return Promise.resolve();
  }
  private async logOutWithMessage(lbl: string, msg: string) {
    await this.logout();
    this._snackBar.open(
      msg + ' – ' + this._ts.instant('please_contact_support') + '.',
      lbl,
      {
        duration: 9000
      });
    this._ngZone.run(() => this._router.navigate(['/logout']));
  }
  private unsubscribeUser() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
  private subscribeUser(firebaseUser: any) {
    this.userSubscription = super.mapDocSnapshotChanges$(this._aFs.collection('users')
       .doc<User>(firebaseUser.uid), User).subscribe((user: User) => {

      // TODO firestore can be user disabled e.g. user.disabled ??
      if (!user) {
        this.logOutWithMessage(this._ts.instant('ok'), this._ts.instant('no_valid_credentials')).catch(error => {
          console.error(error);
        });
        return;
      }
      const currentUser = user;
      currentUser.firebaseUser = firebaseUser;
      // Emitting user-observable to partner component. the call is needed just once.
      // Therefore calling complete() right afterwards (no further unsubscr. necessary)
      this.user$.next(user);
      this._ls.redirectToUserLanguage(currentUser).catch(error => {
        console.error(error);
      });
      if (this.isLoggedIn() && !this.user.language) {
        this.user.language = this._ls.currentLanguage.alpha2;
        this.updateUserLanguage(this.user, this._ls.currentLanguage.alpha2).catch(error => {
          console.error(error);
        });
      }
      // we must hard code the role salesman here
      if (!this.user.isAdmin && !this.user.hasRole(AccessRole.salesman)) {
        if (!this.user.restaurants || Object.keys(this.user.restaurants).length === 0) {
          this.logOutWithMessage(this._ts.instant('ok'), this._ts.instant('no_restaurant_linked_to_account')).catch(error => {
            console.error(error);
          });
          return;
        }
      }
      if (this.initPathname && this._router.url !== this.initPathname) {
        this._router.navigate([this.initPathname]);
      } else if (!this._router.url.startsWith('/partner')) {
        this._router.navigate(['/partner']);
      }
      this.initPathname = null;
      // this._ngZone.run(() => this._router.navigate(['/partner']));
    }, error => {
      // ERROR FirebaseError: Missing or insufficient permissions.
      console.error(error);
    });
  }

  async authenticateUserPassword(password: string) {
    const currentUser = this._afAuth.auth.currentUser;
    const credentials = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
    return await currentUser.reauthenticateAndRetrieveDataWithCredential(credentials);
  }

  async updateUserMail(uid: string, email: string): Promise<Object> {
    if (!email || email.length <= 0) {
      throw Error('No valid email address provided');
    }
    let params = new HttpParams();
    params = params.set('email', email).set('uid', uid);
    return this.updateUser(params);
  }

  authenticateUserAndUpdateEmail(newEmail: string, password: string) {
    const currentUser = this._afAuth.auth.currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      currentUser.email, 
      password
    );
    return currentUser.reauthenticateAndRetrieveDataWithCredential(credential).then(() => {
      return currentUser.updateEmail(newEmail).then(() => {
        return this.user.update({email: newEmail});
      });
    });
  }

  async resetUserPassword(uid: string): Promise<Object> {
    let params = new HttpParams();
    params = params.set('password', '1').set('uid', uid);
    return this.updateUser(params);
  }

  async setUserPassword(uid: string, password: string, sendResetPasswordMail: boolean): Promise<Object> {
    let params = new HttpParams();
    params = params.set('uid', uid).set('password', password).set('sendResetPasswordMail', (sendResetPasswordMail ? '1' : '0'));
    const token = await this.user.firebaseUser.getIdToken();
    const url = `${environment.usersApiURL}/updateUser/?auth=${token}`;
    return this._http.post(url, null, { params: params }).toPromise();
  }

  async createUser(email: string, displayName: string): Promise<any> {
    if (!email || email.length <= 0) {
      throw Error('No valid email address provided');
    }
    const token = await this.user.firebaseUser.getIdToken();
    const url = `${environment.usersApiURL}/create/?auth=${token}`;
    let params = new HttpParams();
    params = params.set('email', email).set('displayName', displayName);
    return this._http.post(url, null, { params: params }).toPromise();
  }

  async disableUser(uid: string): Promise<Object> {
    if (!uid || uid.length <= 0) {
      throw Error('No valid uid provided');
    }
    let params = new HttpParams();
    params = params.set('disabled', 'true').set('uid', uid);
    return this.updateUser(params);
  }

  async updateUser(params: HttpParams) {
    const token = await this.user.firebaseUser.getIdToken();
    const url = `${environment.usersApiURL}/updateUser/?auth=${token}`;
    return this._http.post(url, null, { params: params }).toPromise();
  }

  private logLastLogin(firebaseUser: any) {
    return this._aFs.collection('users').doc(firebaseUser.uid)
      .update({ lastLogin: firebase.firestore.Timestamp.fromDate(new Date()) }).catch(error => {
        console.error(`Error while updating the user [CODE=${error.code}]`);
      });
  }

  public updateUserLanguage(user: User, alpha2: string): Promise<void> {
    if (!user || !user.ref) {
      return Promise.reject('user or reference is null');
    }
    return user.ref.update({ language: alpha2 }).catch(error => {
      console.error(`Error while updating the user [CODE=${error.code}]`);
    });
  }
  ngOnDestroy(): void {
  }

  async sendStartMail(user: User, addPasswordReset: boolean, restaurants: DocumentReference[]): Promise<object | void> {
    if (!restaurants.length) {
      return Promise.resolve();
    }

    const body = {
      user: user.ref.path,
      addPasswordReset,
      restaurants: restaurants.map((restaurant) => restaurant.path)
    };

    const token = await this.user.firebaseUser.getIdToken();
    const url = `${environment.usersApiURL}/sendStartMail/?auth=${token}`;

    return this._http.post(url, body).toPromise();
  }
}
