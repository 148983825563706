import { ErrorMessageService } from '../../services/error-message.service';
import { IDatePickerConfig } from 'ng2-date-picker';

import { PartnerMealAvailabilityComponent } from '../partner-meal-availability/partner-meal-availability.component';
import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatTabGroup,
  MatStepper,
} from '@angular/material';
import * as moment from 'moment-timezone';
import * as firebase from 'firebase/app';
import { Moment } from 'moment';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';
import { MediaObserver } from '@angular/flex-layout';
import { ValidatorService } from '../../services/validator.service';
import { TranslateService } from '@ngx-translate/core';
import {
  Additive,
  Allergen,
  Meal,
  MealType,
  MergedMeal,
  Pool,
  Discount,
} from '../../models/model';
import { MealService } from '../../services/meal.service';
import { DiscountService } from '../../services/discount.service';
import { DocumentReference } from '@angular/fire/firestore';
import { Utils } from '../../app.utils';
import { PropertyService } from '../../services/property.service';
import { UserService } from '../../services/user.service';
import { firestore } from 'firebase';

@Component({
  selector: 'app-partner-edit-meal-dialog',
  templateUrl: './partner-edit-meal-dialog.component.html',
  styleUrls: ['./partner-edit-meal-dialog.component.less'],
})
export class PartnerEditMealDialogComponent implements OnInit {
  formGroup: FormGroup;
  infoFormGroup: FormGroup;
  disocuntFormGroup: FormGroup;
  isLoading = false;
  loadingMessage = '';
  meal: Meal;
  discount: Discount;
  tasteTags: DocumentReference[] = [];
  allergens: Allergen[] = [];
  additives: Additive[] = [];
  mealTypes: MealType[] = [];
  weekDaysOff: Array<number> = [];
  selectedDates: Array<Moment> = [];
  oldSelectedDates: Array<Moment> = [];
  disableDaysBefore?: Moment;
  disableDaysAfter?: Moment;
  toSelect: any;
  highlightDays;
  discountData;
  isAdmin = false;
  minDate = moment().utc().startOf('day');
  maxDate = moment().utc().add(1, 'year').startOf('day');
  now = moment();
  selectedTab = 0;
  timePickerConfig: IDatePickerConfig;

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChildren(MatTabGroup) tabGroup: QueryList<MatTabGroup>;
  @ViewChild('mealAvailability')
  mealAvailability: PartnerMealAvailabilityComponent;

  constructor(
    private _ms: MealService,
    private discountService: DiscountService,

    private _ps: PropertyService,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<PartnerEditMealDialogComponent>,
    // @Output() formGroupChanged: EventEmitter<FormGroup> = new EventEmitter<FormGroup>(),
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    public emh: ErrorMessageService,
    public media: MediaObserver,
    private _vh: ValidatorService,
    private _ts: TranslateService,
    private _us: UserService
  ) {

    this.timePickerConfig = {
      format: 'HH:mm',
      drops: 'down',
      opens: 'left',
      min: moment('2000-01-01T00:00:00'),
      hours24Format: 'HH',
      minutesInterval: 15,
      showTwentyFourHours: true
    };

    this._ps.getPublicHolidays().then((holiday) => {
      this.highlightDays = holiday.map((data) => {
        return {
          date: moment(data.date),
          title: data.localName,
          selectable: true,
          css: 'holiday',
        };
      });
    });

    if (this._us.user.isAdmin) {
      this.isAdmin = true;
    }

    this.selectedDates = this._data;
    const now = moment();

    this.infoFormGroup = this._formBuilder.group({
      from: [now.clone().startOf('day')],
      to: [now.clone().add(4, 'week').endOf('week')],
      days: [undefined, Validators.required],
    });

    if (this._data.meal) {
      this.meal = this._data.meal;
    } else {
      this.meal = new Meal();
    }
    this.additives = [];
    this.allergens = [];
    this.mealTypes = [];
  }

  get canChangeAvailability(): boolean {
    return this.canEdit('availability');
  }

  get canDeleteMeal(): boolean {
    if (this.meal.isMergedMeal()) {
      const merged = <MergedMeal>this.meal;
      return !merged.pool;
    }
    return true;
  }

  get canChangeMeal(): boolean {
    if (!this.meal.isMergedMeal()) {
      return true;
    }
    const merged = <MergedMeal>this.meal;
    if (!merged.pool) {
      return true;
    }
    if (!merged.pool.canEdit || merged.pool.canEdit.length === 0) {
      return false;
    }
    const hasOrder = merged.pool.canEdit.includes('order');
    // order is always in canEdit array
    return (
      (merged.pool.canEdit.length > 1 && hasOrder) ||
      (!hasOrder && merged.pool.canEdit.length === 1)
    );
  }

  async loadData(): Promise<void> {
    this.loadingMessage = this._ts.instant('meal_loading');
    this.isLoading = true;
    let pool: Pool;

    if (!!this._data.pool) {
      // if opened in pool meal list, get pool from _data
      pool = this._data.pool;
    } else if (this.meal.isMergedMeal()) {
      // if opened in restaurant meal list, get pool from mergedMeal
      const mergedMeal = this.meal as MergedMeal;
      pool = mergedMeal.pool;
    }

    if (pool && pool.settings.onlyAllowPeriod) {
      this.disableDaysBefore = moment(pool.settings.startDate.toDate());
      this.disableDaysAfter = moment(pool.settings.endDate.toDate());
    }

    if (this.meal.ref) {
      this.discountService.getDiscountMeal(this.meal.ref).then(discounts => {
        for (const discountDoc of discounts.docs) {
          this.discountData = discountDoc.data();
        }
        return this.discountData;
      });
    }

    const allPromises: any = [
      this._ms.getMealTypes(),
      this._ms.getAllergens(),
      this._ms.getAdditives(),
      this._ms.getAvailability(
        this.meal,
        !this.canChangeAvailability && !this.isSubPool
      ),
    ];
    return Promise.all(allPromises).then((array: any[]) => {
      this.mealTypes = array[0];
      this.allergens = array[1];
      this.additives = array[2];
      this.selectedDates = array[3].map((key) => {
        return moment(key);
      });
      this.oldSelectedDates = this.selectedDates.slice();
      this.isLoading = false;
      return Promise.resolve();
    });
  }

  ngOnInit() {
    this.loadData()
      .then((_) => {
        const price = this.meal.price ? Number(this.meal.price.toFixed(2)) : 0.00;
        const discountPrice = this.discountData ? [this.discountData.discount ? Number(this.discountData.discount.toFixed(2)) : 0.00, [Validators.required, Validators.min(0.01), this.discountisGreater()]] : 0.00;
        const additives = this.meal.additives ? this.meal.additives
          .map((ref) => this.additives.find((additive) => additive.id === ref.id))
          .filter((attr) => !!attr) : [];
        const allergens = this.meal.allergens ? this.meal.allergens
          .map((ref) => this.allergens.find((allergen) => allergen.id === ref.id))
          .filter((attr) => !!attr) : [];
        const toSelect = this.mealTypes.find((c) => c.name === 'Hauptgericht');
        const startDate = [(this.discountData && this.discountData.start) ? this.discountData.start.toDate() : '', [this.dateIsSameOrBeforeEndDate()]];
        const endDate = [(this.discountData && this.discountData.end) ? this.discountData.end.toDate() : '', [this.dateIsSameOrAfterStartDate()]];
        const from = this.discountData ? this.discountData.start ? this.discountData.start.toDate() : '' : '';
        const to = this.discountData ? this.discountData ? this.discountData.end.toDate() : '' : '';

        this.formGroup = this._formBuilder.group({
          name: [{
            value: Utils.trimWhiteSpace(this.meal.name) || null,
            disabled: !this.canEdit('name'),
          }, [Validators.required, this._vh.whiteSpaceValidator()],
          ],
          description: [
            {
              value: Utils.trimWhiteSpace(this.meal.description) || null,
              disabled: !this.canEdit('description'),
            },
          ],
          price: [
            { value: price, disabled: !this.canEdit('price') },
            [Validators.required, Validators.min(0.01)],
          ],
          currency: '€',
          type: [
            {
              value: this.meal.type ? this.meal.type.id : toSelect.id,
              disabled: !this.canEdit('type'),
            },
            [Validators.required],
          ],
          additives: {
            value: additives || [],
            disabled: !this.canEdit('additives'),
          },
          allergens: {
            value: allergens || [],
            disabled: !this.canEdit('allergens'),
          },
          orderable: this.meal.orderable || false,
        });
        this.tasteTags = this.meal.tags ? this.meal.tags.map((lr) => lr.ref) : [];
      })
      .catch((error) => {
        // TODO notification to user
        console.error(error);
      });
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const confirmDialogRef = this._dialog.open(ConfirmationDialog, {
        data: this.meal.id
          ? this._ts.instant('save_meal_informations')
          : this._ts.instant('save_new_meal'),
      });
      confirmDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.updateOrCreateMeal();
        }
      });
    }
  }

  getFormattedTime(date: Date, start: boolean): string {
    const defaultTime = start ? '11:00' : '15:00';
    if (!date) {
      return defaultTime;
    }
    const momentTime = moment(date);
    if (momentTime.hours() === 0 && momentTime.minutes() === 0 && momentTime.seconds() === 0 ||
      momentTime.hours() === 23 && momentTime.minutes() === 59 && momentTime.seconds() === 59) {
      return defaultTime;
    }
    return momentTime.format('HH:mm');
  }

  getTimestamp(date: Date | Moment | null, time?: string): firestore.Timestamp | null {
    const momentDate = moment.isMoment(date) ? date : (moment.isDate(date) ? moment(date) : null);
    if (!momentDate) {
      return null;
    }
    const momentTime = moment(time || '00:00', 'HH:mm');
    momentDate.set({
      hour: momentTime.get('hour'),
      minute: momentTime.get('minute'),
      second: momentTime.get('second')
    });
    return firestore.Timestamp.fromDate(momentDate.toDate());
  }

  updateOrCreateMeal() {
    this.loadingMessage = this._ts.instant('saving_meal');
    //  this.isLoading = true
    const start = moment(this.formGroup.value.start);
    const end = moment(this.formGroup.value.end);
    const mealType = this.mealTypes
      .map((o) => o.ref)
      .find((t) => t.id === this.formGroup.value.type);
    const update = {
      name: this.formGroup.value.name,
      description: this.formGroup.value.description,
      price: this.formGroup.value.price,
      currency: this.formGroup.value.currency,
      orderable: this.formGroup.value.orderable,
      discountable: this.formGroup.value.discountable,
      additives: this.formGroup.value.additives
        ? this.formGroup.value.additives.map(
          (additive: Additive) => additive.ref
        )
        : [],
      allergens: this.formGroup.value.allergens
        ? this.formGroup.value.allergens.map(
          (allergen: Allergen) => allergen.ref
        )
        : [],
      tags: this.tasteTags,
      order: this.meal.order ? this.meal.order : 0,
      poolMeal: this.meal.poolMeal ? this.meal.poolMeal.ref : null,
      type: mealType ? mealType : null,
    };


    if (!this.meal.id) {
      this._dialogRef.close({
        action: 'create',
        data: {
          update,
        },
        availability: this.mealAvailability.selectedDates,
      });
    } else {
      this._dialogRef.close({
        action: 'update',
        meal: this.meal,
        data: {
          update,
        },
        oldAvailability: this.oldSelectedDates,
        newAvailability: this.mealAvailability.selectedDates,
      });
    }
  }

  onDateSubmit() {
    const dates = this.calculateDates();

    dates.forEach((element) => {
      let found = false;
      this.selectedDates.forEach((date) => {
        if (date.clone().startOf('day').isSame(element.clone().startOf('day'))) {
          found = true;
        }
      });
      if (!found) {
        this.selectedDates.push(element);
      }
    });
    this.mealAvailability.selectedDates = this.selectedDates;
    this.mealAvailability.generate();
  }

  canEdit(field: string) {
    if (this.meal.isMergedMeal()) {
      const mergedMeal: MergedMeal = <MergedMeal>this.meal;
      try {
        return mergedMeal.canEdit(field);
      } catch (e) {
        return false;
      }
    }

    // Check if pool is a SubPool and allow editing according to ParentPool
    if (this.isSubPool) {
      const pool = this._data.pool;
      return pool.canEdit && pool.canEdit.includes(field);
    }
    return true;
  }

  get isSubPool() {
    const pool = this._data.pool;
    return pool && pool.parentPool;
  }

  onCancel() {
    this._dialogRef.close();
  }

  onBack() {
    this.stepper.selectedIndex =
      this.stepper.selectedIndex <= 1 ? 0 : this.stepper.selectedIndex - 1;
  }

  onContinue() {
    this.stepper.selectedIndex =
      this.stepper.selectedIndex >= 2 ? 2 : this.stepper.selectedIndex + 1;
  }

  onDelete() {
    const confirmDialogRef = this._dialog.open(ConfirmationDialog, {
      data: this.meal.name + ' ' + this._ts.instant('really_want_to_delete'),
    });
    confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._dialogRef.close({ action: 'delete', meal: this.meal });
      }
    });
  }
  onUpdatedTagselection(tagRefs) {
    if (tagRefs) {
      this.tasteTags = tagRefs;
    }
  }

  onClearAvailability() {
    const today = moment().startOf('day');
    const dates = <Array<Moment>>this.mealAvailability.selectedDates.filter(
      (value) => {
        const date = <Moment>value;
        return date.isBefore(today);
      }
    );
    this.mealAvailability.selectedDates = dates;
    this.mealAvailability.generate();
  }

  calculateDates() {
    const from = this.infoFormGroup.value.from;
    const end = this.infoFormGroup.value.to;
    const current = from;
    const days = this.infoFormGroup.value.days;

    const dates = [];

    while (current.isSameOrBefore(end)) {
      if (days.indexOf('always') !== -1) {
        dates.push(current.clone());
      }

      if (days.indexOf('montofri') !== -1) {
        if (current.isoWeekday() < 6) {
          dates.push(current.clone());
        }
      }

      if (days.indexOf('mon') !== -1) {
        if (current.isoWeekday() === 1) {
          dates.push(current.clone());
        }
      }

      if (days.indexOf('tue') !== -1) {
        if (current.isoWeekday() === 2) {
          dates.push(current.clone());
        }
      }

      if (days.indexOf('wed') !== -1) {
        if (current.isoWeekday() === 3) {
          dates.push(current.clone());
        }
      }

      if (days.indexOf('thu') !== -1) {
        if (current.isoWeekday() === 4) {
          dates.push(current.clone());
        }
      }

      if (days.indexOf('fri') !== -1) {
        if (current.isoWeekday() === 5) {
          dates.push(current.clone());
        }
      }

      if (days.indexOf('sat') !== -1) {
        if (current.isoWeekday() === 6) {
          dates.push(current.clone());
        }
      }

      if (days.indexOf('sun') !== -1) {
        if (current.isoWeekday() === 7) {
          dates.push(current.clone());
        }
      }
      current.add(1, 'day');
    }
    return dates;
  }

  dateIsSameOrAfterStartDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        let start: Date;

        if (this.formGroup) {
          start = this.formGroup.value.start;
        } else if (this.discountData) {
          start = this.discountData.start;
        } else {
          return null;
        }

        let sameOrAfter: boolean;

        if (start) {
          sameOrAfter = !moment(control.value).isSameOrAfter(start);
        }

        return sameOrAfter ? { 'sameOrAfter': { value: control.value } } : null;
      }
    };
  }

  dateIsSameOrBeforeEndDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        let end: Date;

        if (this.formGroup) {
          end = this.formGroup.value.end;
        } else if (this.discountData) {
          end = this.discountData.end;
        } else {
          return null;
        }

        let sameOrBefore: boolean;

        if (end) {
          sameOrBefore = !moment(control.value).isSameOrBefore(end);
        }

        return sameOrBefore ? { 'sameOrBefore': { value: control.value } } : null;
      }
    };
  }

  discountisGreater(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        let discountPrice: number;

        if (this.formGroup) {
          discountPrice = this.formGroup.value.discountPrice;
        } else if (this.discountData) {
          discountPrice = this.discountData.discountPrice;
        } else {
          return null;
        }

        let salesPriceBigger: boolean;

        if (discountPrice) {
          salesPriceBigger = discountPrice > this.formGroup.value.price;
        }

        return salesPriceBigger ? { 'salesPriceBigger': { value: control.value } } : null;
      }
    };
  }
}
