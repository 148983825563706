import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot
} from '@angular/router';
import {Injectable} from '@angular/core';
import {AccessService} from './services/access.service';
import {Utils} from './app.utils';
import {RestaurantService} from './services/restaurant.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private _as: AccessService, private _rs: RestaurantService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      return this.canActivate(route, state);
    }

    canLoad(route: Route): Promise<boolean> {
        const url = `/${route.path}`;
        return this.checkLogin(url);
    }

    checkLogin(url: string): Promise<boolean> {
        // Everyone can logout
        if (url.split('/')[1] === 'logout') {
            return Promise.resolve(true);
        }
        // If no user is logged in or the user doesn't belong to any group, deny access
        if (!this._as.user || Utils.isEmpty(this._as.user.id)) {
          return Promise.resolve(false);
        }
        return this._as.canLoadRoute(url, this._rs.getSelectedRestaurant());
    }
}
