import {Component} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import {AgRendererComponent} from 'ag-grid-angular';
import {Restaurant} from '../../../models/model';

@Component({
  selector: 'app-specials-cell-renderer',
  templateUrl: './specials-cell-renderer.component.html',
  styleUrls: ['./specials-cell-renderer.component.less']
})
export class SpecialsCellRendererComponent implements AgRendererComponent {

  restaurant: Restaurant;

  constructor() {
  }

  agInit(params: ICellRendererParams) {
    this.restaurant = params.data;
  }

  refresh(params: ICellRendererParams): boolean {
    this.restaurant = params.data;
    return true;
  }

}
