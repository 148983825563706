import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { PrintSettings, Restaurant, RestTime, RestTimeType } from '../models/model';
import { firestore } from 'firebase';

@Injectable()
export class PrintService extends HelperService {
  constructor(_aFs: AngularFirestore) {
    super(_aFs);
  }

  /*
  printSettings is a collection which will highly likely just have one entry for the time being.
  https://lunchnow-com.atlassian.net/browse/WEBPAR-465
   */
  public getPrintSettingsForRestaurant(restaurant: Restaurant): Promise<PrintSettings> {
    if (!restaurant || !restaurant.id) {
      return Promise.resolve(null);
    }
    return restaurant.printSettings.doc('default_template').get().then(doc => {
      if (doc.exists) {
        return new PrintSettings(doc);
      }
      return restaurant.printSettings.limit(1).get().then(query => {
        if (query.size) {
          return new PrintSettings(query.docs[0]);
        }
         return new PrintSettings();
      });
    });
  }

  public updatePrintSettings(restaurant: Restaurant, printSettings: PrintSettings): Promise<void> {
   return restaurant.printSettings.doc('default_template').set(printSettings.data()).then(() => Promise.resolve());
  }

  public updateOrder(elements: { ref: firestore.DocumentReference, update: { order: number } }[]): Promise<void> {
    return super.updateDocuments(elements);
  }
  public getRestTimes(): Promise<RestTimeType[]> {
    return this._aFs.firestore.collection('rest_time_types').where('category' , '==', 'holiday').get()
      .then((querySnapshot) => {
        return super.mapQuerySnapshot<RestTimeType>(querySnapshot, RestTimeType);
      });
  }
}
