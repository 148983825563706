import {Component, Input, OnInit} from '@angular/core';
import {LnCmsElement} from '../../models/model';

/*
  currently you can embed every html into value element.
  TODO limit html elements, e.g. to only strong, i, small, etc...
 */
@Component({
  selector: 'app-ln-cms-small-card',
  template: `
    <mat-card class="small">
      <mat-card-content fxLayout="row" fxLayoutAlign="start stretch">
        <div class="image" fxLayoutAlign="start center"><img *ngIf="image" [src]="image"/></div>
        <div fxLayoutAlign="start center"><span class="value" [innerHTML]="element?.value"></span></div>
      </mat-card-content>
    </mat-card>`,
  styleUrls: ['../ln-cms-elements.component.less']
})
export class LnCmsSmallCardComponent implements OnInit {
  @Input() element: LnCmsElement;
  constructor() {}
  ngOnInit(): void {
  }
  get image(): string | null {
    return this.getValue('image');
  }
  getValue(key: string): string | null {
    if (this.element && this.element[key]) {
      return this.element[key];
    }
    return null;
  }
}
