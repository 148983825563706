import { Injectable, OnDestroy } from '@angular/core';
import { HelperService } from './helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as isoLang from 'langs';
import { environment } from '../../environments/environment';
import { ICountry, ILanguage, Language, User } from '../models/model';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../app.utils';
import { Subscription, Observable } from 'rxjs';

export interface ILanguage {
  alpha2: string;
  displayName: string;
}
// require definition in typings.d.ts makes conflict with ng test
const LANGUAGE_COLLECTION = 'languages';
declare var require: any;

@Injectable()
export class LanguageService extends HelperService implements OnDestroy {
  // Languages that are added to the app (found in the Database-path /languages)
  systemLanguages: Array<Language> = [];
  systemLanguagesPromise;
  systemLanguagesSubscriber: Subscription;
  // List of ALL countries from the i18n-iso-countries package
  countries: Array<ICountry> = [];
  // current system language
  currentLanguage: ILanguage;
  i18nCountries;

  constructor(_aFs: AngularFirestore, private _ts: TranslateService) {
    super(_aFs);
    this.setCurrentLanguage();
    this.getSystemLanguages().catch(error => console.error(error));
    this.i18nCountries = require('i18n-iso-countries');
    this.i18nCountries.registerLocale(require(`i18n-iso-countries/langs/de.json`));
    this.systemLanguagesSubscriber = this.getSystemLanguages$().subscribe(languages => {
      this.systemLanguages = languages;
    });
  }

  // The system language and its displayName has to be available instantly without any DB access.
  // Therefore the currentLanguage is stored as an interface and its props are set synchronously.
  setCurrentLanguage() {
    this.currentLanguage = {
      alpha2: this._ts.currentLang,
      displayName: isoLang.where('1', this._ts.currentLang).local
    };
  }
  // Returns all languages that are supported by the app. The reload param determines
  // if the languages should be read from DB again or to use the local variable
  public async getSystemLanguages() {
    try {
      if (!this.systemLanguages.length) {
        this.systemLanguagesPromise = Utils.statePromise(this._aFs.firestore.collection(LANGUAGE_COLLECTION).orderBy('displayName', 'asc').get().then(querySnapshot => {
          return super.mapQuerySnapshot<Language>(querySnapshot, Language);
        }));
        this.systemLanguages = await this.systemLanguagesPromise;
      }
    } catch (e) {
      console.error(e);
    }
    return this.systemLanguages;
  }

  public getSystemLanguages$(): Observable<Language[]> {
    return super.mapSnapshotChanges$(this._aFs.collection<Language>(LANGUAGE_COLLECTION, ref => {
      return ref.orderBy('displayName', 'asc');
    }), Language);
  }

  // Get all countries from i18n-iso-countries and store it as with {alpha2,displayName} props
  getCountries() {
    if (!this.countries.length) {
      const _countries: Array<ICountry> = [];
      const alli18nCountries = this.i18nCountries.getNames(environment.defaultLang);
      Object.keys(alli18nCountries).map(function (key, index) {
        _countries.push({ displayName: alli18nCountries[key], alpha2: key.toLowerCase() });
      });
      this.countries = _countries;
    }
    return this.countries;
  }

  // Gets ALL languages from 'langs' module and parses props to Language Object
  getAvailableLanguages(): Array<ILanguage> {
    let isoLanguages: Array<ILanguage> = isoLang.all().map(obj => {
      return { alpha2: obj[1], displayName: obj.local };
    });

    // Filter out already existing languages (in 'languages' table)
    this.systemLanguages.forEach(lang => {
      isoLanguages = isoLanguages.filter(isoLanguage => isoLanguage.alpha2 !== lang.alpha2);
    });
    return isoLanguages;
  }

  // Checks if the give alpha2 code is a valid language-code
  isValidAlpha2Code(isoA2Code: string) {
    return isoLang.has('1', isoA2Code);
  }

  getNativeLanguageName(isoA2Code) {
    return this.isValidAlpha2Code(isoA2Code) ? isoLang.where('1', isoA2Code).local : '';
  }

  getDefaultLanguage(): Language {
    if (!environment.defaultLang.length) {
      console.error('No default language specified in environment!');
    }
    const defaultLanguage = new Language();
    defaultLanguage.alpha2 = environment.defaultLang;
    defaultLanguage.displayName = this.getNativeLanguageName(environment.defaultLang);
    defaultLanguage.flag = environment.defaultLang;
    return defaultLanguage;
  }

  // Checks if the param-language is the same as the current language (from translate-service)
  isCurrentLanguage(alpha2: string) {
    return alpha2 === this.currentLanguage.alpha2;
  }

  // Redirects the user to his preferred language. The fallback language is used
  // if the user doesnt have an assigned language.
  redirectToUserLanguage(user: User) {
    return this.redirect(user.language);
  }

  // update existing Language / save new Language
  async saveLanguage(lang: Language) {
    await this._aFs.firestore.collection(LANGUAGE_COLLECTION).doc().set(lang.data())
      .catch(e => {
        console.error(e);
      });
  }

  // The root-partner-project is always german. Therefore the redirecting goes
  // to the root path. In every other case we use the alpha2 code as the redirect path.
  public async redirect(lang) {
    let systemLanguages = [];
    // if systemLanguages are not loaded then wait for it
    if (this.systemLanguagesPromise && this.systemLanguagesPromise.isPending()) {
      systemLanguages = await this.systemLanguagesPromise;
    } else {
      systemLanguages = this.systemLanguages;
    }
    // Check if the target-language is part of the system languages. Otherwise, take the default language.
    if (!systemLanguages.some(language => language.alpha2 === lang)) {
      lang = this.getDefaultLanguage().alpha2;
    }
    if (this.isCurrentLanguage(lang)) {
      return;
    }
    const path = (lang === environment.defaultLang) ? window.location.origin : (window.location.origin + `/${lang}`);
    window.location.assign(path);
  }

  ngOnDestroy() {
    this.systemLanguagesSubscriber.unsubscribe();
  }
}

