export enum LnCmsElementTypes {
  CARD_LARGE = 'card_large',
  CARD_SMALL = 'card_small',
  PDF = 'pdf',
  VIDEO = 'video',
  NUMBER = 'number',
  TEXT = 'text',
  BOOLEAN = 'bool',
}

export enum CoopertionType {
  Restaurant = 'restaurant',
  Fitness = 'fitness',
  Beauty = 'beauty',
  Places = 'places',
  NonPartner = 'non-partner',
  Resmio = 'resmio'

}

export enum VoucherType {
  SmartPrice = 'smart-price',
  Compensation = 'compensation',
  Promotion = 'promotion',
  Loyalty = 'loyalty',
  GiftCard = 'gift-card',
  Goodie = 'goodie'
}

export enum DiscountType {
  Absolute = 'absolute',
  Precent = 'percent',
  OverRide = 'override',
}

export enum LnCmsAreas {
  DASHBOARD = 'dashboard',
  //  GENERAL = 'general',
  //  DETAILS = 'details',
  //  OPENINGHOURS = 'openingHours',
  //  MEDIA = 'media',
  //  MENUFILES = 'menuFiles',
  //  ADDRESS = 'address',
  //  WIDGET = 'widget',
  //  MEALS = 'meals',
  //  MENUES = 'menues',
  HELP = 'help',
  LOGIN = 'login'
}

export enum Medium_Type {
  image = 'image',
  video = 'video',
  menu = 'menu'
}

export enum TasteTagType {
  attribute = 'attribute',
  cuisine = 'cuisine',
  product = 'product'
}

export enum AccessRole {
  admin = 'admin',
  company_admin = 'company_admin',
  salesman = 'salesman',
  partner = 'partner',
  user = 'user'
}

// used in AccessService
export enum AccessArea {
  admin = 'admin',
  dashboard = 'dashboard',
  company = 'company',
  restaurant = 'restaurant',
  meals = 'meals',
  menus = 'menus',
  help = 'help',
  sales = 'sales'
}

export enum WidgetType {
  website = 'website',
  facebook = 'facebook'
}

export enum RestTimeType_Category {
  individual = 'individual',
  holiday = 'holiday',
}
