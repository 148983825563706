import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  Type,
  ViewChild
} from '@angular/core';
import { LnCmsSmallCardComponent } from './components/small.card';
import { LnCmsPdfComponent } from './components/pdf';
import { LnCmsVideoComponent } from './components/video';
import { LnCmsLargeCardComponent } from './components/large.card';
import { LnCmsElementsDirective } from './ln-cms-elements.directive';
import { LnCmsService } from '../services/ln-cms.service';
import { Observable } from 'rxjs';
import { LnCmsElement } from '../models/model';
import { LnCmsAreas, LnCmsElementTypes } from '../models/enums';

const COMPONENT_TYPES = {
  [LnCmsElementTypes.PDF]: LnCmsPdfComponent,
  [LnCmsElementTypes.VIDEO]: LnCmsVideoComponent,
  [LnCmsElementTypes.CARD_LARGE]: LnCmsLargeCardComponent,
  [LnCmsElementTypes.CARD_SMALL]: LnCmsSmallCardComponent
};


@Component({
  selector: 'app-ln-cms-elements',
  template: `
              <div class="ln-cms-elements">
                <ng-container *ngIf="(cmsElements | async) as elements else loading">
                  <app-ln-cms-element *ngFor="let element of elements" [element]="element"></app-ln-cms-element>
                </ng-container>
              </div>
              <ng-template #loading>
                <mat-spinner *ngIf="showLoadingSpinner" mode="indeterminate"></mat-spinner>
              </ng-template>
            `,
  styleUrls: ['./ln-cms-elements.component.less']
})
export class LnCmsElementsComponent implements OnInit, OnChanges, OnDestroy {
  /*
   * area (page) where should be the elements visible
   */
  @Input() area: LnCmsAreas;
  /*
   * optionally show only elements from a type: e.g. video or pdf
   */
  @Input() type: LnCmsElementTypes;
   /*
    *  optionally show loading spinner, default is true
    */
  @Input() showSpinner: boolean;

  cmsElements: Observable<LnCmsElement[]>;
  constructor(private lnCmsHelperService: LnCmsService) {
    // this.cmsElements = of([]);
  }
  ngOnInit() {
    this.loadCMSElements();
  }
  ngOnDestroy(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  get showLoadingSpinner(): boolean {
    if (this.showSpinner === undefined || this.showSpinner === null) {
      return true;
    }
    return this.showSpinner === true;
  }
  loadCMSElements() {
    this.cmsElements = this.lnCmsHelperService.getActiveElements$(this.area, this.type);
  }
}


@Component({
  selector: 'app-ln-cms-element',
  template: `
    <div class="ln-cms-element">
      <ng-template appCmsHost></ng-template>
    </div>
  `,
  styleUrls: ['./ln-cms-elements.component.less']
})
export class LnCmsElementComponent implements OnInit, OnChanges {
  @Input() element: any;
  @ViewChild(LnCmsElementsDirective) appCmsHost: LnCmsElementsDirective;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }
  ngOnChanges(changes: SimpleChanges): void { }
  ngOnInit(): void {
    this.loadComponent();
  }
  loadComponent() {
    const component = this.component;
    if (!component) {
      return;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    if (this.appCmsHost) {
      const viewContainerRef = this.appCmsHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      componentRef.instance.element = this.element;
    } else {
      console.warn('LnCmsElementComponent.loadComponent() appCmsHost is undefined');
    }
  }
  get component(): Type<any> {
    const type = this.element ? this.element.type : '';
    if (COMPONENT_TYPES[type]) {
      return COMPONENT_TYPES[type];
    }
    return null;
  }
}

