import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Restaurant} from '../../models/model';
import {RestaurantService} from '../../services/restaurant.service';
import {AccessService} from '../../services/access.service';
import {AccessArea} from '../../models/enums';

@Component({
  selector: 'app-partner-restaurants-media',
  templateUrl: './partner-restaurants-media.component.html',
  styleUrls: ['./partner-restaurants-media.component.less']
})
export class PartnerRestaurantsMediaComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  listActive = false;
  restaurant: Restaurant;
  restaurantHasMedia = false;

  constructor(private _rs: RestaurantService, private _as: AccessService) {
    this.restaurant = Restaurant.parse({});
  }

  ngOnInit() {
    const selectedRestaurant$ = this._rs.getSelectedRestaurant$();
    this.subscription = selectedRestaurant$
      .subscribe(restaurant => {
        this.restaurant = restaurant || Restaurant.parse({});
        if (restaurant && restaurant.media && restaurant.media.length) {
          this.restaurantHasMedia = true;
        }
      }, error => console.error(error));
  }

  toggleListActive() {
    this.listActive = !this.listActive;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  get canEdit(): boolean {
    return this._as.canEdit(AccessArea.restaurant, this.restaurant);
  }
  onMediaChange(size: number): void {
    this.restaurantHasMedia = size > 0;
  }
}
