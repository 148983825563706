import {AgRendererComponent} from 'ag-grid-angular';
import {Component} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import {Restaurant} from '../../../models/model';

@Component({
  selector: 'app-restaurant-list-cell-renderer-customer-id',
  templateUrl: './restaurant-list-cell-renderer-customer-id.component.html',
  styleUrls: ['./restaurant-list-cell-renderer-customer-id.component.less']
})
export class RestaurantListCellRendererCustomerIdComponent implements AgRendererComponent {

  restaurant: Restaurant;
  contractId = 'N/A';

  constructor() { }

  agInit(params: ICellRendererParams) {
    this.restaurant = params.data;
    if (this.restaurant.contractId) {
      this.contractId = this.restaurant.contractId;
    }
  }

  refresh(params: ICellRendererParams) {
    return true;
  }
}
