import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './auth.service';
import {APP_ROUTE_PATHS} from './app.constants';


export const appRoutes: Routes = [
    {
        path: APP_ROUTE_PATHS.partner,
        loadChildren: 'app/partner/partner.module#PartnerModule',
        canLoad: [AuthGuard]
    },
    {
        path: APP_ROUTE_PATHS.login,
        component: LoginComponent
    },
    {
        path: APP_ROUTE_PATHS.logout,
        component: LoginComponent
    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

export const routes: ModuleWithProviders = RouterModule.forRoot(appRoutes,
    { enableTracing: false});
