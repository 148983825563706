import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {HelperService} from './helper.service';
import {LnCmsElement} from '../models/model';
import {firestore} from 'firebase';
import {LnCmsAreas, LnCmsElementTypes} from '../models/enums';

const CMS_COLLECTION = 'cms_elements';


@Injectable({
  providedIn: 'root',
})
export class LnCmsService extends HelperService {
  constructor(_aFs: AngularFirestore) {
    super(_aFs);
  }

  public updateOrder(elements: {ref: firestore.DocumentReference, update: {order: number}}[]): Promise<void> {
    return super.updateDocuments(elements);
  }

  public getAllKeyValueElements$(): Observable<LnCmsElement[]> {
    const collection: AngularFirestoreCollection<LnCmsElement> = this._aFs.collection<LnCmsElement>(CMS_COLLECTION, ref => {
      return ref.where('isKeyValue', '==', true).orderBy('key', 'asc');
    });
    return super.mapSnapshotChanges$(collection, LnCmsElement);
  }
  public getAllUiElements$(): Observable<LnCmsElement[]> {
    const collection: AngularFirestoreCollection<LnCmsElement> = this._aFs.collection<LnCmsElement>(CMS_COLLECTION, ref => {
      return ref.where('isKeyValue', '==', false).orderBy('order', 'asc');
    });
    return super.mapSnapshotChanges$(collection, LnCmsElement);
  }
  public getActiveElements$(area: LnCmsAreas, type?: LnCmsElementTypes): Observable<LnCmsElement[]> {
    const collection: AngularFirestoreCollection<LnCmsElement> = this._aFs.collection<LnCmsElement>(CMS_COLLECTION, ref => {
      let query =  ref
        .where('areas', 'array-contains', area)
        .where('active', '==', true);
      if (type) {
        query = query.where('type', '==', type);
      }
      return query.orderBy('order', 'asc');
    });
    return super.mapSnapshotChanges$(collection, LnCmsElement); // collection.valueChanges();
  }
  public updateOrCreate(element: LnCmsElement, update: LnCmsElement): Promise<void> {
    if (element.ref) {
      return element.update(update);
    }
    return this._aFs.firestore.collection(CMS_COLLECTION).doc().set(update.data());
  }

  public getValue(key: string): Promise<any> {
    return this._aFs.firestore.collection(CMS_COLLECTION)
      .where('key', '==', key)
      .where('active', '==', true)
      .get().then(querySnapshot => {
        let value = null;
        querySnapshot.forEach((documentSnapshot) => {
          if (documentSnapshot.exists) {
            value = documentSnapshot.data().value;
          }
        });
        return value;
      });
  }
}
