import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { IEmptyPageButton, Meal, MergedMeal, Pool, LunchNowReference, TasteTag, MealType, Restaurant } from '../../models/model';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog} from '@angular/material';
import { MealService } from '../../services/meal.service';
import { CustomLoadingGridOverlayComponent } from '../../loading-grid-overlay/loading.grid.overlay.component';
import { PartnerEditMealDialogComponent } from '../../partner/partner-edit-meal-dialog/partner-edit-meal-dialog.component';
import { firestore } from 'firebase';
import { Moment } from 'moment';
import { PropertyService } from '../../services/property.service';
import { take } from 'rxjs/operators';
import { RestaurantService } from '../../services/restaurant.service';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-meal-list',
    templateUrl: './meal-list.component.html',
    styleUrls: ['./meal-list.component.less']
})
export class MealListComponent implements OnInit, OnDestroy, OnChanges {

    /**
     * List of meals
     */
    @Input() meals: Meal[];
    /**
     * Allow sort or create the meals
     */
    @Input() editable: boolean;
    /**
     * Show loading indicator. E.g. during the loading the meals
     */
    @Input() loadingIndicator: boolean;
    /**
     * If true, only merged meals will be shown
     */
    @Input() onlyPoolMeals: boolean;
    /**
     * Show search input field above the meals list
     */
    @Input() searchBar: boolean;
    /**
     * Array of column to show, see mealsColumnDefs below
     */
    @Input() columnDefs: any[];
    /**
     * Show empty state depending of onlyPoolMeals and editable values. For emptyState = null the empty state placeholder will
     * be not rendered.
     */
    @Input() emptyState: { type?: string, text?: string, button?: IEmptyPageButton } | null;

    /**
     * If it is the meal list in a pool, get the pool configuration
     */
    @Input() pool: Pool;

    /**
     * Callback function in case of creating new meal. E.g. for create button on empty state placeholder or
     * if you trying to edit merged meal, that has not been saved.
     */
    @Output() addMeal = new EventEmitter<{ data: Meal, availability: Moment[] }>();

    @HostBinding('style.height') height = '100%';
    searchForm;
    gridOptions: GridOptions;
    formGroup: FormGroup;
    rowDragEnterEvent: any;
    orderable: boolean;
    _emptyStateButton: IEmptyPageButton = {
        onClick: this.openAddMealDialog.bind(this),
        name: this._ts.instant('add_meal')
    };
    constructor(
        private _ts: TranslateService,
        private _as: AlertService,
        public media: MediaObserver,
        private _dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _ms: MealService,
        private _rs: RestaurantService
    ) {
        this.columnDefs = [];
        this.createGrid();
    }
    get emptyStateText() {
        if (this.emptyState && this.emptyState.text !== undefined) {
            return this.emptyState.text;
        }
        let text = this._ts.instant('no_meals_info');
        if (!this.onlyPoolMeals && this.editable) {
            text += ' ' + this._ts.instant('no_meals_enter_first');
        }
        return text;
    }
    get emptyStateType(): string {
        if (this.emptyState && this.emptyState.type) {
            return this.emptyState.type;
        }
        return 'default';
    }
    get emptyStateButton() {
        if (this.emptyState && this.emptyState.button !== undefined) {
            return this.emptyState.button;
        }
        if (!this.onlyPoolMeals && this.editable) {
            return this._emptyStateButton;
        }
        return null;
    }
    get isPageEmpty(): boolean {
        return this.rows.length === 0 && !!this.meals;
    }
    get rows(): Meal[] {
        if (this.meals) {
            return this.meals;
        }
        return [];
    }
    private get orderInfo(): { max: number, min: number } {
        return mealsOrderInfo(this.gridOptions.rowData);
    }

    getRowNodeId = (meal: Meal) => meal.id;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.resizeHandler();
    }

    createGrid() {
        this.gridOptions = <GridOptions>{
            rowHeight: 56,
            suppressMovableColumns: true,
            defaultColDef: {
                resizable: true,
            },
            onGridReady: (event) => {
                event.api.sizeColumnsToFit();
                this.changeColumnBehavior();
            },
            components: {
                currencyCellRenderer: CurrencyCellRenderer,
                poolCellRenderer: PoolCellRenderer,
                moveDownCellRenderer: MoveDownCellRenderer,
                moveUpCellRenderer: MoveUpCellRenderer
            },
            frameworkComponents: {
                customLoadingOverlay: CustomLoadingGridOverlayComponent
            },
            loadingOverlayComponent: 'customLoadingOverlay',
            suppressNoRowsOverlay: true
        };
    }

    resizeHandler() {
        if (this.gridOptions.api) {
            // setTimeout as workaround for grid resize problem
            setTimeout(() => {
                this.gridOptions.api.sizeColumnsToFit();
            }, 20);
        }
    }

    ngOnInit() {
        this.searchForm = this._formBuilder.group({
            searchTerm: ''
        });
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.meals) {
            //  console.log('changes.meals ', changes.meals);
        }
        if (changes.loadingIndicator) {
            if (changes.loadingIndicator.currentValue === true) {
                this.showLoadingOverlay();
            } else if (changes.loadingIndicator.currentValue === false) {
                this.hideLoadingOverlay();
            }
        }
    }
    changeColumnBehavior() {
        if (this.gridOptions && this.gridOptions.api && this.gridOptions.columnApi) {
            this.gridOptions.columnApi.setColumnsVisible(['drag', 'up', 'down'], this.editable === true);
            this.gridOptions.api.sizeColumnsToFit();
        }
    }

    showLoadingOverlay() {
        if (this.gridOptions.api) {
            this.gridOptions.api.showLoadingOverlay();
        }
    }

    hideLoadingOverlay() {
        if (this.gridOptions.api) {
            this.gridOptions.api.hideOverlay();
        }
    }

    canBeOrdered() {
        const confirmDialogRef = this._dialog.open(ConfirmationDialog, { data: this._ts.instant('really_want_to_make_all_meals_orderable') });
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._ms.updateOrderable(this._rs.getSelectedRestaurant()).then(() => {
                    this._as.open('orderable_saved_success');
                }).catch(error => {
                    console.error(error);
                    this._as.error(error);
                });
            }
        });
    }
    notOrderable() {
        const confirmDialogRef = this._dialog.open(ConfirmationDialog, { data: this._ts.instant('really_want_to_make_all_meals_unorderable') });

        confirmDialogRef.afterClosed().subscribe(result => {

            if (result) {

                this._ms.updateNotOrderable(this._rs.getSelectedRestaurant()).then(() => {

                    this._as.open('not_orderable_saved_success');

                }).catch(error => {

                    console.error(error);

                    this._as.error(error);

                });

            }
        });
    }

    onCellClicked(params) {
        if (!this.editable) {
            return;
        }
        let field = null;
        try {
            field = params.column.userProvidedColDef.field;
        } catch (e) {
        }
        if (field === 'up') {
            if (params.rowIndex > 0) {
                this.moveRow(params.rowIndex, true);
            }
        } else if (field === 'down') {
            if (params.rowIndex < this.gridOptions.rowData.length - 1) {
                this.moveRow(params.rowIndex, false);
            }
        } else {
            this.openEditMealDialog(params.data);
        }
    }

    /**
     * move a row to the top or bottom
     * @param rowIndex
     * @param up if true then move a row to the top
     */
    moveRow(rowIndex: number, up: boolean) {
        const orderInfo = this.orderInfo;
        let order = up ? orderInfo.max : orderInfo.min;
        order = this.sanitizeOrder(order);
        order = up ? ++order : --order;
        const meal = this.gridOptions.rowData[rowIndex];
        const updateDocs = [];
        updateDocs.push({ ref: meal.ref, update: { order: order, ...this.getAdditionUpdateData(meal) } });
        this.persistOrder(updateDocs);
    }

    openEditMealDialog(meal: Meal) {
        const dialogRef = this._dialog.open(PartnerEditMealDialogComponent,
            {
                width: '1000px',
                data: {
                    pool: this.pool,
                    meal: meal,
                }
            });
        dialogRef.afterClosed().subscribe(result => {
            this.handleMealDialogAction(result);
        });
    }

    openAddMealDialog() {
        const newMeal = new Meal();
        newMeal.order = this.orderInfo.max + 1;
        const dialogRef = this._dialog.open(PartnerEditMealDialogComponent,
            {
                width: '1000px',
                data: {
                    pool: this.pool,
                    meal: newMeal,
                }
            });
        dialogRef.afterClosed().subscribe(result => {
            this.handleMealDialogAction(result);
        });
    }

    persistOrder(meals: { ref: firestore.DocumentReference, update: { order: number } }[]) {
        // TODO notification to user, success or failure
        this.gridOptions.api.showLoadingOverlay();
        this._ms.updateOrder(meals).then(() => {
            this.gridOptions.api.hideOverlay();
        }).catch(error => {
            this.gridOptions.api.hideOverlay();
            this._as.error(error);
            console.error('PartnerRestaurantsMealsComponent.updateOrder()', error);
        });
    }

    /*
      IMPORTANT!!! the meals should have set order correctly, e.g. [-5, 0, 4, 6, 7, 89, 111, 444, 99999],
      no mather how you move the meals, the order values stay the same.

      for the order [1, 1, 1, 1, 1, 1, 1] this method will not work properly,
      because the method does not change the order directly, but only swap and shift the position of meals
     */
    updateOrder(eventStart, eventEnd) {
        const fromIndex = eventStart.overIndex;
        const toIndex = eventEnd.overIndex;
        if (fromIndex !== toIndex) {
            const up = fromIndex > toIndex;
            const updateDocs = [];
            const indexMin = Math.min(fromIndex, toIndex);
            const indexMax = Math.max(fromIndex, toIndex);
            const model = this.gridOptions.rowData;
            updateDocs.push({
                ref: model[fromIndex].ref,
                update: { order: this.sanitizeOrder(model[toIndex].order), ...this.getAdditionUpdateData(model[fromIndex]) }
            });
            for (let i = indexMin; i <= indexMax; i++) {
                if (fromIndex !== i) {
                    const shift = up ? i + 1 : i - 1;
                    updateDocs.push({
                        ref: model[i].ref,
                        update: { order: this.sanitizeOrder(model[shift].order), ...this.getAdditionUpdateData(model[i]) }
                    });
                }
            }
            this.persistOrder(updateDocs);
        }
    }

    private getAdditionUpdateData(meal: Meal) {
        if (meal instanceof MergedMeal) {
            const merged = <MergedMeal>meal;
            if (!merged.existsInDatabase) {
                return { poolMeal: merged.poolMeal.ref };
            }
        }
        return {};
    }

    // FIX for db migration issues, in case that order are a float number
    private sanitizeOrder(order: any): number {
        const rounded = Math.round(order);
        if (isNaN(rounded)) {
            return 0;
        }
        return rounded;
    }

    private handleMealDialogAction(result: {
        action: 'create' | 'update' | 'delete', data?: any, meal?: Meal, availability?: Moment[],
        oldAvailability?: Moment[], newAvailability?: Moment[]
    }) {
        this.showLoadingOverlay();
        // only for that case if the merged meal doesnt exists in db, e.g. some trigger failed and the restaurant
        // has no init data for the pool meal. Check if the meal exists in db.
        const doesntExistInDB = result && result.meal && result.meal.isMergedMeal() && !(<MergedMeal>result.meal).existsInDatabase;
        if (result && result.action === 'update') {
            this._ms.update(result.meal, result.data, result.oldAvailability, result.newAvailability).then(() => {
                this.hideLoadingOverlay();
                this._as.open('meal_saved_success');
            }).catch(error => {
                this.hideLoadingOverlay();
                console.error(error);
                this._as.error(error);
            });
        } else if (result && result.action === 'delete') {
            result.meal.delete().then(() => {
                this.hideLoadingOverlay();
                this._as.open('meal_deleted_success');
            }).catch(error => {
                this.hideLoadingOverlay();
                console.error(error);
                this._as.error(error);
            });
        } else if (result && result.action === 'create' || doesntExistInDB) {
            this.hideLoadingOverlay();
            this.addMeal.emit({
                data: result.data, availability: doesntExistInDB ? result.newAvailability : result.availability
            });
        } else {
            this.hideLoadingOverlay();
        }
    }
}

// with this render the meals by clicking up button takes 2 seconds for cast iron grill (1055 meals)
// with old render took this 3 seconds
export class CurrencyCellRenderer {
    params;

    public getGui(): HTMLElement {
        const span = document.createElement('span');
        if (this.params && this.params.data) {
            const currency = this.params.data.currency ? this.params.data.currency : '€';
            if (this.params.data.price) {
                span.innerHTML = `${this.params.data.price.toFixed(2)} ${currency}`;
            } else {
                span.innerHTML = '0 €';
            }
        }
        return span;
    }

    public init(params) {
        this.params = params;
    }
}

export class MoveDownCellRenderer {
    public getGui(): HTMLElement {
        const div = document.createElement('div');
        div.setAttribute('style', 'height: 100%; min-height: 100%; min-width: 100%; width: 100%; place-content: center; align-items: center; flex-direction: row; box-sizing: border-box; display: flex;');
        div.innerHTML = '<mat-icon class="mat-icon material-icons mat-icon-no-color icon-pointer" role="img" aria-hidden="true">arrow_drop_down</mat-icon>';
        return div;
    }
}

export class MoveUpCellRenderer {
    public getGui(): HTMLElement {
        const div = document.createElement('div');
        div.setAttribute('style', 'height: 100%; min-height: 100%; min-width: 100%; width: 100%; place-content: center; align-items: center; flex-direction: row; box-sizing: border-box; display: flex;');
        div.innerHTML = '<mat-icon class="mat-icon material-icons mat-icon-no-color icon-pointer" role="img" aria-hidden="true">arrow_drop_up</mat-icon>';
        return div;
    }
}

export class PoolCellRenderer {
    data;

    public getGui(): HTMLElement {
        const div = document.createElement('div');
        div.setAttribute('style', 'height: 100%; min-height: 100%; min-width: 100%; width: 100%; place-content: center; align-items: center; flex-direction: row; box-sizing: border-box; display: flex;');
        div.setAttribute('class', 'pool');
        // restaurant room_service  shopping_basket view_list
        div.innerHTML = this.data && this.data.pool ?
            '<mat-icon  class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true" title="' + this.data.pool.name + '">restaurant</mat-icon>' :
            '';
        return div;
    }

    public init(params) {
        this.data = params.data;
    }
}
export const mealsOrderInfo = (meals: Meal[]): { max: number, min: number } => {
    const mealsData = meals || [];
    if (!mealsData || mealsData.length === 0) {
        return { max: 0, min: 0 };
    }
    let maxOrder = Number.MIN_SAFE_INTEGER;
    let minOrder = Number.MAX_SAFE_INTEGER;
    if (mealsData) {
        for (const meal of mealsData) {
            maxOrder = Math.max(maxOrder, meal.order);
            minOrder = Math.min(minOrder, meal.order);
        }
    }
    return { max: maxOrder, min: minOrder };
};

export const mealsColumnDefs = async (ts: TranslateService, ms: MealService, ps: PropertyService) => {
    const tags = await ps.getTags$().pipe(take(1)).toPromise();
    const mealTypes = await ms.getMealTypes();

    // Create map for all available tags
    const tagsIdToName = tags.reduce((obj, t) => {
        obj[t.id] = ps.getTagTranslation(t)
        return obj;
    }, {});

    // Create map for all available types
    const mealTypesIdToName = mealTypes.reduce((obj, t) => {
        obj[t.id] = ps.getTagTranslation(t)
        return obj;
    }, {});

    return {
        order: {
            headerName: 'Order',
            field: 'order',
            maxWidth: 50,
            width: 50,
            minWidth: 50,
        },
        pool: {
            headerName: '',
            field: 'pool',
            maxWidth: 36,
            width: 36,
            minWidth: 36,
            cellRenderer: 'poolCellRenderer'
        },
        name: {
            headerName: ts.instant('name'),
            field: 'name',
            minWidth: 120
        },
        description: {
            headerName: ts.instant('description'),
            field: 'description',
            minWidth: 120,
        },
        price: {
            headerName: ts.instant('price'),
            field: 'price',
            maxWidth: 130,
            minWidth: 100,
            cellRenderer: 'currencyCellRenderer'
        },
        moveUp: {
            headerName: '',
            hide: true,
            field: 'up',
            minWidth: 48,
            width: 48,
            maxWidth: 48,
            cellRenderer: 'moveUpCellRenderer'
        },
        moveDown: {
            headerName: '',
            hide: true,
            field: 'down',
            minWidth: 48,
            width: 48,
            maxWidth: 48,
            cellRenderer: 'moveDownCellRenderer'
        },
        drag: {
            colId: 'drag',
            headerName: '',
            maxWidth: 50,
            width: 50,
            minWidth: 50,
            field: 'name',
            rowDrag: true
        },
        tags: {
            headerName: 'tags',
            hide: true,
            field: 'tags',
            getQuickFilterText: (params) => {
                const mealTasteTags: LunchNowReference<TasteTag>[] = params.value;
                let stringsToFilter: string = '';
                if (tagsIdToName && mealTasteTags) {
                    stringsToFilter = mealTasteTags.map(r => tagsIdToName[r.id]).join(' ')
                }
                return stringsToFilter;
            }
        },
        type: {
            headerName: 'type',
            hide: true,
            field: 'type',
            getQuickFilterText: (params) => {
                const mealType: LunchNowReference<MealType> = params.value;
                if (mealTypesIdToName && mealType) {
                    return mealTypesIdToName[mealType.id];
                }
                return "";
            }
        }
    };
};
