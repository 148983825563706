import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-ln-info-box',
  templateUrl: './ln-info-box.component.html',
  styleUrls: ['./ln-info-box.component.less']
})
export class LnInfoBoxComponent {

  @Input('info') info: string;

  constructor() { }

}
