import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {PartnerVideoDialogComponent} from '../../partner/partner-video-dialog/partner-video-dialog.component';
import {LnCmsElement} from '../../models/model';
import {MediaService} from '../../services/media.service';

@Component({
  selector: 'app-ln-cms-video',
  template: `
    <div class="video" fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center" (click)="playVideo()">
      <div class="thumbnail" [class.thumbnail-placeholder] = "!thumbnailUrl">
        <mat-icon>play_circle_filled_white</mat-icon>
        <img [src]="thumbnailUrl" *ngIf="thumbnailUrl"/>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between start">
        {{element?.value}}
        <div class="muted">{{element?.size}}</div>
      </div>
    </div>`,
  styleUrls: ['../ln-cms-elements.component.less']
})
export class LnCmsVideoComponent implements OnInit {
  @Input() element: LnCmsElement;
  thumbnailUrl: string;
  constructor(private _dialog: MatDialog, private _ms: MediaService) {
  }
  ngOnInit(): void {
    try {
      this.thumbnailUrl = this._ms.getYouTubeThumbnailUrl(this.url);
    } catch (e) {
      console.warn(`Problem loading youtube thumbnail for url: ${this.url}`);
    }
  }
  get url(): string | null {
    return this.getValue('url');
  }
  getValue(key: string): string | null {
    if (this.element && this.element[key]) {
      return this.element[key];
    }
    return null;
  }
  playVideo(): void {
    this._dialog.open(PartnerVideoDialogComponent, {
      data: this.url
    });
  }
}
