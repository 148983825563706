import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-partner-video-dialog',
  templateUrl: './partner-video-dialog.component.html',
  styleUrls: ['./partner-video-dialog.component.less']
})
export class PartnerVideoDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private _data: string) {
  }

  ngOnInit() {
    document.getElementById('iframe-wrapper').innerHTML = `<iframe width="100%" height="100%" src="${this._data}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`;
  }

}
