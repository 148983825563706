import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {MaterialModule} from '../material/material.module';
import {SharedModule} from '../shared.module';
import { MealListComponent } from './meal-list/meal-list.component';
import { AgGridModule } from 'ag-grid-angular/main';
import {SpecialsCellRendererComponent} from '../partner/cell-renderer/specials-cell-renderer/specials-cell-renderer.component';
import {RestaurantListCellRendererAddressComponent} from '../partner/cell-renderer/restaurant-list-cell-renderer-address/restaurant-list-cell-renderer-address.component';
import {RestaurantListCellRendererCustomerIdComponent} from '../partner/cell-renderer/restaurant-list-cell-renderer-customer-id/restaurant-list-cell-renderer-customer-id.component';
import {CellRendererImageComponent} from '../partner/cell-renderer/cell-renderer-image/cell-renderer-image.component';
import {CellRendererFormatedDateComponent} from '../partner/cell-renderer/cell-renderer-formated-date/cell-renderer-formated-date.component';
import {CellRendererMediaTypeComponent} from '../partner/cell-renderer/cell-renderer-media-type/cell-renderer-media-type.component';
import {EmptyPagePlaceholderComponent} from '../partner/empty-page-placeholder/empty-page-placeholder.component';
import { ProfileProgressComponent } from './profile-progress/profile-progress.component';
import {TranslateModule} from '@ngx-translate/core';
import { InlineEditorComponent } from './inline-editor/inline-editor.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    MealListComponent,
    EmptyPagePlaceholderComponent,
    SpecialsCellRendererComponent,
    RestaurantListCellRendererAddressComponent,
    RestaurantListCellRendererCustomerIdComponent,
    CellRendererImageComponent,
    CellRendererFormatedDateComponent,
    CellRendererMediaTypeComponent,
    ProfileProgressComponent,
    InlineEditorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([
      SpecialsCellRendererComponent,
      RestaurantListCellRendererAddressComponent,
      RestaurantListCellRendererCustomerIdComponent,
      CellRendererImageComponent,
      CellRendererFormatedDateComponent,
      CellRendererMediaTypeComponent
    ]),
    TranslateModule
  ],
  exports: [
    PageNotFoundComponent,
    MealListComponent,
    ProfileProgressComponent,
    EmptyPagePlaceholderComponent,
    SpecialsCellRendererComponent,
    RestaurantListCellRendererAddressComponent,
    RestaurantListCellRendererCustomerIdComponent,
    CellRendererImageComponent,
    CellRendererFormatedDateComponent,
    CellRendererMediaTypeComponent,
    InlineEditorComponent
  ]
})
export class ComponentsModule {}
