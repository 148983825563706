import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ErrorMessageService {

  messages = {
    required: this._ts.instant('forgotten_field'),
    required_short: this._ts.instant('forgotten_field_short'),
    invalid_email: this._ts.instant('invalid_email'),
    invalid_phone_pattern: this._ts.instant('invalid_phone_number'),
    invalid_phone_length: this._ts.instant('minimum_seven_characters'),
    invalid_url: this._ts.instant('invalid_url'),
    invalid_character: this._ts.instant('invalid_character'),
    invalid_price_min: this._ts.instant('price_not_smaller_zero'),
    invalid_discount_price_min: this._ts.instant('sale_price_should_be_smaller_price'),
    invalid_seats_min: this._ts.instant('seats_not_smaller_zero'),
    invalid_address_street_number: this._ts.instant('invalid_address_street_number'),
    invalid_address: this._ts.instant('invalid_address'),
    invalid_code_length: this._ts.instant('minimum_six_characters'), // TODO: use same key for any number of characters and pass a number
    // More to come
  };

  constructor(private _ts: TranslateService) {
    this._ts.get('forgotten_field').subscribe(result => {
      this.messages.required = result;
    });
    this._ts.get('forgotten_field_short').subscribe(result => {
      this.messages.required_short = result;
    });
    this._ts.get('invalid_email').subscribe(result => {
      this.messages.invalid_email = result;
    });
    this._ts.get('invalid_phone_number').subscribe(result => {
      this.messages.invalid_phone_pattern = result;
    });
    this._ts.get('minimum_seven_characters').subscribe(result => {
      this.messages.invalid_phone_length = result;
    });
    this._ts.get('invalid_url').subscribe(result => {
      this.messages.invalid_url = result;
    });
    this._ts.get('invalid_character').subscribe(result => {
      this.messages.invalid_character = result;
    });
    this._ts.get('price_not_smaller_zero').subscribe(result => {
      this.messages.invalid_price_min = result;
    });
    this._ts.get('sale_price_should_be_smaller_price').subscribe(result => {
      this.messages.invalid_price_min = result;
    });
    this._ts.get('seats_not_smaller_zero').subscribe(result => {
      this.messages.invalid_seats_min = result;
    });
    this._ts.get('invalid_address_street_number').subscribe(result => {
      this.messages.invalid_address_street_number = result;
    });
    this._ts.get('invalid_address').subscribe(result => {
      this.messages.invalid_address = result;
    });
    this._ts.get('minimum_six_characters').subscribe(result => {
      this.messages.invalid_code_length = result;
    });
  }

  /*
    Code from following Issue
    https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript/15901418
  */
  invalidMaxFileSize(maxSize) {
    const formatBytes = function (bytes, decimals?) {
      if (bytes === 0) { return '0 Bytes'; }
      const k = 1000,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };
    return this._ts.instant('filesize_can_be') + ' ' + formatBytes(maxSize) + ' ' + this._ts.instant('not_bigger_than') + '.';
  }

  getInvalidFileExtensionMessage(allowedFileExtensions: Array<string>) {
    if (!allowedFileExtensions.length) {
      console.error('No file extensions passed into call');
      return;
    }
    return `${this._ts.instant('warning_use_these_file_formats')}: ${allowedFileExtensions.map(function (x) { return x.toUpperCase(); }).join(', ')}`;
  }

}
