import {Medium} from '../../models/model';
import {FormBuilder, FormGroup} from '@angular/forms/';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-media-name-edit-dialog',
  templateUrl: './media-name-edit-dialog.component.html',
  styleUrls: ['./media-name-edit-dialog.component.less']
})
export class MediaNameEditDialogComponent implements OnInit {

  mediaFile: Medium;
  formGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<MediaNameEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: Medium, private _formBuilder: FormBuilder) {

    this.formGroup = this._formBuilder.group({
      name: name
    });
  }

  ngOnInit() {
    this.mediaFile = this._data;
    this.formGroup.get('name').setValue(this.mediaFile.name);
  }

  save() {
    this.mediaFile.name = this.formGroup.get('name').value;
    this.dialogRef.close(this.mediaFile);
  }

  cancel() {
    this.dialogRef.close();
  }
}
