import {AgRendererComponent} from 'ag-grid-angular';
import {Component} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import {Address} from '../../../models/model';

@Component({
  selector: 'app-restaurant-list-cell-renderer-address',
  templateUrl: './restaurant-list-cell-renderer-address.component.html',
  styleUrls: ['./restaurant-list-cell-renderer-address.component.less']
})
export class RestaurantListCellRendererAddressComponent implements AgRendererComponent {

  address: Address;
  constructor() { }

  agInit(params: ICellRendererParams) {
    this.address = params.value;
  }

  formattedAddress() {
    if (!this.address) {
      return;
    }
    return this.address.getFormatedAddress();
  }

  refresh(params: ICellRendererParams) {
    this.address = params.value;
    return true;
  }
}
